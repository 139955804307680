import React from "react";
import { useSelector } from "react-redux";
import formatDate from "../../utils/formatDate";
import { ReactComponent as ArrowIcon } from "../../assets/icons/expand.svg";
import IconButton from "../IconButton";
import EmptyListBadge from "../EmptyListBadge";
import GreyContainer from "../GreyContainer";
import "./SprintsContainer.scss";

export default function SprintsContainer() {
  const projectSprints = useSelector((state) => state.projectSprints.payload);

  return (
    <div className="sprints">
      <h2 className="sprints-title">All retrospectives</h2>
      <GreyContainer className="sprints-container">
        {projectSprints.length > 0 ? (
          projectSprints.map((sprint) => (
            <li
              key={`sprint_${sprint.sprintId}`}
              className="panel sprints-container-panel"
            >
              <span className="sprints-container-panel-title">
                {sprint.sprintName}
              </span>
              <div className="sprints-container-panel-actions">
                <span className="sprints-container-panel-actions-dates">{`${formatDate(
                  sprint.startDate
                )} - ${formatDate(sprint.endDate)}`}</span>
                <IconButton
                  icon={<ArrowIcon />}
                  to={`sprints/${sprint.sprintId}/review`}
                />
              </div>
            </li>
          ))
        ) : (
          <EmptyListBadge text="This project doesn't have any sprints" />
        )}
      </GreyContainer>
    </div>
  );
}
