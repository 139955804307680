import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Select from "react-select";
import "./SearchSelect.scss";

const borderColor = "#e2e3e6";
const customStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    borderRadius: 5,
    borderColor: isFocused ? borderColor : borderColor,
    boxShadow: "none",
    "&:hover": {
      borderColor: borderColor,
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: 14,
    color: "#adb3bc",
  }),
  valueContainer: (styles) => ({
    ...styles,
    height: 40,
    paddingLeft: 14,
  }),
  dropdownIndicator: (styles, { selectProps }) => ({
    ...styles,
    color: "#000",
    marginRight: 4,
    "&:hover": {
      color: "#000",
    },
    transform: selectProps.menuIsOpen && "rotate(180deg)",
  }),
  input: (styles) => ({
    ...styles,
    fontSize: 14,
  }),
  option: (styles) => ({
    ...styles,
    fontSize: 13,
    textAlign: "left",
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: 14,
  }),
};

const SearchSelect = (allProps) => {
  const {
    field,
    setFieldValue,
    options,
    selectedValues,
    error = false,
    ...props
  } = allProps;

  // if selected value is not in options set it to null
  useEffect(() => {
    const selectedValue = options.find(
      (option) => option?.value === field?.value?.value
    );

    if (!selectedValue && field.value && field.value !== null) {
      setFieldValue(field.name, null);
    }
  }, [field, options, setFieldValue]);

  const isSelected = (value) =>
    !!selectedValues?.find((item) => value === item?.value);

  const searchSelect = (
    <Select
      className={cx({ "search-select-error": error })}
      styles={customStyles}
      {...field}
      {...props}
      onChange={(selectedOption) => setFieldValue(field.name, selectedOption)}
      options={options}
      isOptionDisabled={(option) => isSelected(option.value)}
    />
  );

  if (error) {
    return (
      <div className="search-select-error-container">
        {searchSelect}
        <span className="alert-text">This field is required</span>
      </div>
    );
  }

  return searchSelect;
};

SearchSelect.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  field: PropTypes.object,
  options: PropTypes.array.isRequired,
  selectedValues: PropTypes.array,
  error: PropTypes.bool,
};

export default SearchSelect;
