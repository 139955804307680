import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setSelfUserInit = () => {
  return {
    type: "GET_SELF_USER_INIT",
  };
};

const setFetchedSelfUserLoading = () => {
  return {
    type: "GET_SELF_USER_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedSelfUser = (response) => {
  return {
    type: "GET_SELF_USER",
    payload: response.data,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedSelfUserError = () => {
  return {
    type: "GET_SELF_USER_ERROR",
    status: STATUSES.ERROR,
  };
};

const fetchedDataToUserEntity = ({ data: userId }) => {
  return { data: { userId } };
};

export const getSelfUser = () => {
  return (dispatch) => {
    dispatch(setFetchedSelfUserLoading());
    _fetch("api/CurrentUser")
      .then((response) => {
        if (response.success) {
          const selfUser = fetchedDataToUserEntity(response);
          dispatch(setFetchedSelfUser(selfUser));
        } else {
          dispatch(setFetchedSelfUserError());
        }
      })
      .catch(() => dispatch(setFetchedSelfUserError()));
  };
};

export const resetSelfUser = () => {
  return (dispatch) => {
    dispatch(setSelfUserInit());
  };
};
