import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setActiveUsersInit = () => {
  return {
    type: "GET_ACTIVE_USERS_INIT",
  };
};

const setFetchedActiveUsersLoading = () => {
  return {
    type: "GET_ACTIVE_USERS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedActiveUsers = (response) => {
  return {
    type: "GET_ACTIVE_USERS",
    payload: response,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedActiveUsersError = () => {
  return {
    type: "GET_ACTIVE_USERS_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getActiveUsers = (channelId) => {
  return (dispatch) => {
    dispatch(setFetchedActiveUsersLoading());
    _fetch(`api/Teams/${channelId}/Active-Users`)
      .then((response) => {
        if (response.success) {
          dispatch(setFetchedActiveUsers(response));
        } else {
          dispatch(setFetchedActiveUsersError());
        }
      })
      .catch(() => dispatch(setFetchedActiveUsersError()));
  };
};

export const resetActiveUsers = () => {
  return (dispatch) => {
    dispatch(setActiveUsersInit());
  };
};
