import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import * as msTeams from "@microsoft/teams-js";
import { Formik, Form, Field } from "formik";
import _fetch from "../../utils/fetch";
import SearchSelect from "../SearchSelect";
import BackButton from "../BackButton";
import Tagger from "../Tagger";
import { VALIDATIONS } from "../../constants/validations";
import "./SelectProjectForm.scss";

const SelectProjectForm = ({ channelUsers }) => {
  const formRef = useRef();

  msTeams.initialize();

  const userProjects = useSelector((state) => state.userProjects.payload);

  const [initialValues, setInitialValues] = useState(null);

  const usersToTagEntity = (users) => {
    return users.map((user) => ({
      id: user.username,
      name: user.fullName,
    }));
  };

  const usersFormatting = (users) => {
    return users.map((user) => ({
      Username: user.id,
      FullName: user.name,
    }));
  };

  const getInitialValues = useCallback(() => {
    msTeams.getContext(({ channelId, entityId }) => {
      const url = `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_CLIENT_ID}/Retro?label=Retro-2020&context=%7B%22channelId%22%3A%22${channelId}%22%7D`;
      setInitialValues({
        project: null,
        link: url,
        channelId: channelId,
        entityId: entityId,
        adminUsers: [],
        users: usersToTagEntity(channelUsers),
      });
    });
  }, [channelUsers]);

  const validate = ({ project, adminUsers, users }) => {
    const errors = {};

    if (!project) {
      errors.project = VALIDATIONS.REQUIRED;
    }

    if (adminUsers.length === 0) {
      errors.adminUsers = VALIDATIONS.REQUIRED;
    }

    if (!users) {
      errors.users = VALIDATIONS.REQUIRED;
    }

    return Object.keys(errors).length > 0 ? errors : false;
  };

  useEffect(() => {
    getInitialValues();

    msTeams.settings.registerOnSaveHandler((saveEvent) => {
      if (formRef.current) {
        const { values, validateForm } = formRef.current;
        validateForm().then((errors) => {
          if (!errors.project && !errors.adminUsers) {
            const { project, channelId, adminUsers, users } = values;
            let formData = {
              projectName: project.label,
              teamsChannelId: channelId,
              adminUsers: usersFormatting(adminUsers),
              users: usersFormatting(users),
            };

            _fetch(`api/project/${project.value}`, {
              method: "PUT",
              data: formData,
            }).then(({ status }) => {
              if (status >= 400) {
                saveEvent.notifyFailure();
              } else {
                msTeams.settings.setSettings({
                  contentUrl: window.location.origin,
                  entityId: process.env.REACT_APP_TEAMS_ENTITY_ID, //used in deep links to identify a tab
                });
                saveEvent.notifySuccess();
              }
            });
          }
        });
      }
    });
    msTeams.settings.setValidityState(true);
  }, [getInitialValues]);

  const projectsToOptionsEntity = (items) => {
    return items
      .filter((item) => item.isAdmin)
      .map((item) => ({
        label: item.projectName,
        value: item.projectId,
      }));
  };

  if (!initialValues) {
    return null;
  }

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      validate={validate}
      onSubmit={() => {}}
      innerRef={formRef}
    >
      {({ values, setFieldValue, errors }) => (
        <Form className="select-project-form">
          <BackButton push="/teams-config" />
          <div className="search">
            <label className={errors.project ? "danger" : undefined}>
              Select Project
            </label>
            <Field
              component={SearchSelect}
              options={projectsToOptionsEntity(userProjects)}
              setFieldValue={setFieldValue}
              name="project"
              value={values.project}
            />
          </div>
          <div className="tag-container">
            <label className={errors.adminUsers ? "danger" : undefined}>
              Project Admins
            </label>
            <Field
              component={Tagger}
              tags={values.adminUsers}
              suggestions={values.users}
              setFieldValue={setFieldValue}
              name="adminUsers"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SelectProjectForm;
