import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Intent } from "@blueprintjs/core";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFfile from "./PDFfile";
import Button from "../../Button";
import Toaster from "../../Toaster";

const PDFButton = () => {
  const [generatedPdf, setGeneratedPdf] = useState(false);
  const [chartBase64, setChartBase64] = useState();

  const sprint = useSelector(({ sprint }) => sprint.payload);
  const sprintUsers = useSelector(({ sprintUsers }) => sprintUsers.payload);

  const { doWellComments, improveComments } = useSelector(
    ({ retroComments }) => retroComments.payload
  );
  const teamCompetencesAverages = useSelector(
    ({ teamCompetencesAverages }) => teamCompetencesAverages.payload
  );

  const generatePdf = () => {
    const chart = document.getElementById("export-chart");
    const chartBase64string = chart.toDataURL("image/png", 1).split(",")[1];
    setGeneratedPdf(true);
    setChartBase64(chartBase64string);
    return true;
  };

  const generatePdfButton = (
    <Button secondary onClick={() => !generatedPdf && generatePdf()}>
      <i className="far fa-file-pdf" /> Generate PDF
    </Button>
  );

  const downloadPdfButton = (
    <PDFDownloadLink
      document={
        <PDFfile
          chartBase64={chartBase64}
          moodScore={sprint.moodAverage}
          doWellComments={doWellComments}
          improveComments={improveComments}
          competenceAverages={teamCompetencesAverages}
          sprintUsers={sprintUsers}
          sprintName={sprint.sprintName}
        />
      }
      fileName={`Retro - ${sprint.sprintName}.pdf`}
    >
      {({ loading }) =>
        loading ? (
          <Button secondary>Generating document...</Button>
        ) : (
          <Button
            secondary
            onClick={() => {
              Toaster.show({
                message: "Downloaded retro.pdf to default download folder",
                intent: Intent.SUCCESS,
              });
            }}
          >
            <i className="far fa-file-pdf" /> Download PDF
          </Button>
        )
      }
    </PDFDownloadLink>
  );

  return !generatedPdf ? generatePdfButton : downloadPdfButton;
};

export default PDFButton;
