import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "../Button";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import Tabs from "../Tabs";
import { ROUTES } from "../../constants/routes";
import { setFeedbackTab } from "../../actions/setFeedbackTab";
import ReceivedFeedbacks from "./ReceivedFeedbacks";
import GivenFeedbacks from "./GivenFeedbacks/GivenFeedbacks";
import CCFeedbacks from "./CCFeedbacks/CCFeedbacks";
import FeedbacksFilter from "./FeedbacksFilter";
import FixedActionHeader from "../FixedActionHeader";
import IconButton from "../IconButton";
import { setFeedbacksToExcelExport } from "../../actions/setFeedbacksToExcelExport";
import "./FeedbacksContainer.scss";

const Feedbacks = () => {
  const dispatch = useDispatch();

  const feedbackTab = useSelector(({ feedbackTab }) => feedbackTab);
  const receivedFeedbacks = useSelector(
    ({ receivedFeedbacks }) => receivedFeedbacks.payload
  );
  const receivedFeedbackRequests = useSelector(
    ({ receivedFeedbackRequests }) => receivedFeedbackRequests.payload
  );
  const receivedFeedbackAnswers = useSelector(
    ({ receivedFeedbackAnswers }) => receivedFeedbackAnswers.payload
  );
  const givenFeedbacks = useSelector(
    ({ givenFeedbacks }) => givenFeedbacks.payload
  );
  const givenFeedbackRequests = useSelector(
    ({ givenFeedbackRequests }) => givenFeedbackRequests.payload
  );
  const givenFeedbackAnswers = useSelector(
    ({ givenFeedbackAnswers }) => givenFeedbackAnswers.payload
  );
  const ccFeedbacks = useSelector(({ ccFeedbacks }) => ccFeedbacks).payload;
  const notifications = useSelector(
    ({ notifications }) => notifications.payload.data
  );
  const feedbacksToExcelExport = useSelector(
    ({ feedbacksToExcelExport }) => feedbacksToExcelExport
  );

  const [filters, setFilters] = useState({
    feedback: false,
    request: false,
    answer: false,
  });

  const handleExcelCancel = useCallback(
    () =>
      dispatch(
        setFeedbacksToExcelExport({
          selectedIds: [],
          selectable: false,
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    const onEscape = (event) => {
      if (event.key === "Escape") {
        handleExcelCancel();
      }
    };

    document.addEventListener("keydown", onEscape);

    return () => {
      document.removeEventListener("keydown", onEscape);
      handleExcelCancel();
    };
  }, [handleExcelCancel]);

  const handleExcelClick = () => {
    setFilters({ feedback: true, request: true, answer: false });
    dispatch(
      setFeedbacksToExcelExport({ ...feedbacksToExcelExport, selectable: true })
    );
    dispatch(setFeedbackTab(0));
  };

  const excelDownloadQuery = feedbacksToExcelExport.selectedIds.reduce(
    (acc, currVal, index) =>
      index === 0
        ? `${acc}?requestId=${currVal}`
        : `${acc}&requestId=${currVal}`,
    ""
  );

  const allReceivedCount =
    receivedFeedbacks.length +
    receivedFeedbackRequests.length +
    receivedFeedbackAnswers.length;

  const allGivenCount =
    givenFeedbacks.length +
    givenFeedbackRequests.length +
    givenFeedbackAnswers.length;

  const hasReceivedNotification =
    notifications.unreadFeedback +
      notifications.unreadRequestedFeedback +
      notifications.unreadAnsweredFeedback >
    0;
  const hasCCNotification = notifications.unreadMentionedInCcFeedback > 0;

  const tabs = [
    {
      text: "Received",
      component: (
        <ReceivedFeedbacks
          hasNotification={hasReceivedNotification}
          filters={filters}
        />
      ),
      messages: allReceivedCount,
      hasNotification: hasReceivedNotification,
    },
    {
      text: "Given",
      component: <GivenFeedbacks filters={filters} />,
      messages: allGivenCount,
    },
    {
      text: "Shared (CC)",
      component: <CCFeedbacks hasNotification={hasCCNotification} />,
      messages: ccFeedbacks.length,
      hasNotification: hasCCNotification,
    },
  ];

  const selectedIdsLength = feedbacksToExcelExport.selectedIds.length;

  const answersSelectedText = (length) =>
    length === 0
      ? "Select received answers to export"
      : `${length} answer${length === 1 ? "" : "s"} selected`;

  return (
    <>
      <div className="feedbacks-container-actions">
        <Button icon={<AddIcon />} to={ROUTES.CREATE_FEEDBACK.path}>
          New feedback
        </Button>
        <Button
          secondary
          icon={<AddIcon />}
          to={ROUTES.CREATE_FEEDBACK_REQUEST.path}
        >
          New request
        </Button>
      </div>
      <div className="feedbacks-container-secondary-actions">
        <FeedbacksFilter values={filters} setValues={setFilters} />
        <Button
          size="xsmall"
          className="feedbacks-container-excel-button"
          onClick={handleExcelClick}
        >
          <i className="far fa-file-excel" /> Export as Excel
        </Button>
      </div>
      {feedbacksToExcelExport.selectable && (
        <FixedActionHeader className="feedbacks-container-export-header">
          <span>{answersSelectedText(selectedIdsLength)}</span>
          <div className="feedbacks-container-export-header-actions">
            <Button
              size="xsmall"
              disabled={selectedIdsLength === 0}
              onClick={handleExcelCancel}
              externalTo={`${process.env.REACT_APP_API}/api/FeedbackRequestsAsFile${excelDownloadQuery}`}
            >
              Download
            </Button>
            <IconButton
              size="small"
              secondary
              icon={<DeleteIcon />}
              onClick={handleExcelCancel}
            />
          </div>
        </FixedActionHeader>
      )}
      <Tabs
        tabs={tabs}
        activeTab={feedbackTab}
        className="feedbacks-container-tabs"
        onTabClick={(index) => dispatch(setFeedbackTab(index))}
      />
    </>
  );
};

export default Feedbacks;
