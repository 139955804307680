import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminStartSprintForm from "../../components/AdminSprintInfo/AdminStartSprintForm";
import {
  getProjectUsers,
  resetProjectUsers,
} from "../../actions/getProjectUsers";
import { getProject, resetProject } from "../../actions/getProject";
import {
  getAllProjectCompetences,
  resetAllProjectCompetences,
} from "../../actions/getAllProjectCompetences";
import {
  getLatestSprint,
  resetLatestSprint,
} from "../../actions/getLatestSprint";
import { setPageTitle } from "../../actions/setPageTitle";
import Spinner from "../../components/Spinner";
import { ROUTES } from "../../constants/routes";
import { hasErrors, areInitiallyLoading } from "../../utils/pageStates";

const AdminStartSprint = (props) => {
  const dispatch = useDispatch();

  const projectUsers = useSelector(({ projectUsers }) => projectUsers);
  const project = useSelector(({ project }) => project);
  const allProjectCompetences = useSelector(
    ({ allProjectCompetences }) => allProjectCompetences
  );
  const latestSprint = useSelector(({ latestSprint }) => latestSprint);

  const projectId = props.match.params.projectId;

  const fetches = [projectUsers, project, allProjectCompetences, latestSprint];

  useEffect(() => {
    dispatch(
      setPageTitle({
        text: "New retro",
        backPath: ROUTES.RETRO_HOME.path,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProject(projectId));
    dispatch(getProjectUsers(projectId));
    dispatch(getLatestSprint(projectId));
    dispatch(getAllProjectCompetences(projectId));

    return () => {
      dispatch(resetProject());
      dispatch(resetProjectUsers());
      dispatch(resetLatestSprint());
      dispatch(resetAllProjectCompetences());
    };
  }, [dispatch, projectId]);

  const validToStartSprint = () =>
    !latestSprint.payload ||
    (latestSprint.payload.sprint &&
      latestSprint.payload.sprint.sprintStatus === 2);

  if (areInitiallyLoading(fetches)) {
    return <Spinner />;
  }

  if (hasErrors(fetches)) {
    return <h2>Something went wrong</h2>;
  }

  if (validToStartSprint) {
    return <AdminStartSprintForm projectId={projectId} />;
  }

  return <h2>Finish previous sprint before starting new one</h2>;
};

export default AdminStartSprint;
