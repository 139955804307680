import React from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import GreyContainer from "../../GreyContainer";
import Switch from "../../Switch";
import Evaluation from "./Evaluation";
import IconButton from "../../IconButton";
import "./Evaluations.scss";

const Evaluations = ({ sprintUsers, competences, allowIncognito = true }) => {
  const handleAddNew = (arrayHelpers) =>
    arrayHelpers.push({
      competences: competences.map((competence) => ({
        competenceValue: undefined,
        competenceName: competence.competenceName,
        competenceId: competence.competenceId,
      })),
      evaluationId: 0,
      evaluatedUser: undefined,
      isMandatory: false,
    });

  return (
    <GreyContainer
      title="Evaluate team members competences"
      topRightComponent={
        <Switch
          name="isIncognito"
          label="Incognito"
          icon={<i className="fas fa-user-secret fa-lg incognito-icon" />}
          large
          disabled={!allowIncognito}
        />
      }
    >
      <FieldArray
        name="evaluations"
        render={(arrayHelpers) => {
          const { evaluations } = arrayHelpers.form.values;

          return (
            <div className="evaluations">
              {evaluations.map((_item, index) => {
                const { evaluations: errors } = arrayHelpers.form.errors;
                const error = errors ? errors[index] : null;

                return (
                  <Evaluation
                    key={`evaluation_${index}`}
                    mandatoryUser={index < 2 ? sprintUsers[index] : null}
                    sprintUsers={index >= 2 ? sprintUsers : null}
                    user={sprintUsers[index]}
                    arrayHelpers={arrayHelpers}
                    evaluation={evaluations[index]}
                    evaluationIndex={index}
                    error={!!error}
                  />
                );
              })}
              {evaluations.length < sprintUsers.length && (
                <div className="add-evaluation-button-container">
                  <IconButton
                    type="button"
                    onClick={() => handleAddNew(arrayHelpers)}
                    icon={<AddIcon />}
                    className="full-width-on-mobile"
                    text="Add another person"
                  />
                </div>
              )}
            </div>
          );
        }}
      />
    </GreyContainer>
  );
};

Evaluations.propTypes = {
  sprintUsers: PropTypes.array,
  competences: PropTypes.array.isRequired,
  allowIncognito: PropTypes.bool,
};

export default Evaluations;
