import Retro from "../pages/Retro";
import Feedbacks from "../pages/Feedbacks";
import CreateFeedback from "../pages/CreateFeedback";
import CreateFeedbackRequest from "../pages/CreateFeedbackRequest";
import Projects from "../pages/Projects";
import AdminStartSprint from "../pages/AdminStartSprint";
import AdminEditSprint from "../pages/AdminEditSprint";
import AdminCompleteSprint from "../pages/AdminCompleteSprint";
import RetroReview from "../pages/RetroReview";
import Sprints from "../pages/Sprints";
import FrontPage from "../pages/FrontPage";
import TeamsConfig from "../pages/TeamsConfig";
import LoginStart from "../pages/Login/LoginStart";
import LoginEnd from "../pages/Login/LoginEnd";
import LoginPage from "../pages/Login";
import SelectProject from "../pages/SelectProject";
import TermsAndConditions from "../pages/TermsAndConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ContactUs from "../pages/ContactUs";
import UserManual from "../pages/UserManual";
import DefaultLayout from "../components/layouts/DefaultLayout";
import EmptyLayout from "../components/layouts/EmptyLayout";
import FrontPageLayout from "../components/layouts/FrontPageLayout";
import EditProjectAdmins from "../pages/EditProjectAdmins/EditProjectAdmins";

export const ROUTES = {
  HOME: { path: "/", component: FrontPage, layout: FrontPageLayout },
  FEEDBACKS: {
    path: "/feedbacks",
    component: Feedbacks,
    layout: DefaultLayout,
  },
  CREATE_FEEDBACK: {
    path: "/create-feedback",
    component: CreateFeedback,
    layout: DefaultLayout,
  },
  CREATE_FEEDBACK_REQUEST: {
    path: "/create-feedback-request",
    component: CreateFeedbackRequest,
    layout: DefaultLayout,
  },
  RETRO_HOME: {
    path: "/retro",
    component: Projects,
    layout: DefaultLayout,
  },
  RETRO_PROJECTS: {
    path: "/retro/projects",
    component: Projects,
    layout: DefaultLayout,
  },
  RETRO_USER_PROJECT: {
    path: "/retro/projects/:projectId",
    component: Retro,
    layout: DefaultLayout,
  },
  RETRO_ADMIN_START_SPRINT: {
    path: "/retro/projects/:projectId/admin/start-sprint",
    component: AdminStartSprint,
    layout: DefaultLayout,
  },
  RETRO_ADMIN_EDIT_SPRINT: {
    path: "/retro/projects/:projectId/admin/edit-sprint",
    component: AdminEditSprint,
    layout: DefaultLayout,
  },
  RETRO_ADMIN_COMPLETE_SPRINT: {
    path: "/retro/projects/:projectId/admin/complete-sprint",
    component: AdminCompleteSprint,
    layout: DefaultLayout,
  },
  RETRO_REVIEW: {
    path: "/retro/projects/:projectId/sprints/:sprintId/review",
    component: RetroReview,
    layout: DefaultLayout,
  },
  RETRO_SPRINTS: {
    path: "/retro/projects/:projectId/sprints",
    component: Sprints,
    layout: DefaultLayout,
  },
  TEAMS_CONFIG: {
    path: "/teams-config",
    component: TeamsConfig,
    layout: EmptyLayout,
  },
  LOGIN_START: {
    path: "/login-start",
    component: LoginStart,
    layout: EmptyLayout,
  },
  LOGIN_END: {
    path: "/login-end",
    component: LoginEnd,
    layout: EmptyLayout,
  },
  LOGIN_PAGE: {
    path: "/login",
    component: LoginPage,
    layout: EmptyLayout,
  },
  SELECT_PROJECT_PAGE: {
    path: "/select-project",
    component: SelectProject,
    layout: EmptyLayout,
  },
  TERMS_AND_CONDITIONS_PAGE: {
    path: "/termsandconditions",
    component: TermsAndConditions,
    layout: DefaultLayout,
  },
  PRIVACY_POLICY_PAGE: {
    path: "/privacypolicy",
    component: PrivacyPolicy,
    layout: DefaultLayout,
  },
  CONTACT_US_PAGE: {
    path: "/contactus",
    component: ContactUs,
    layout: DefaultLayout,
  },
  USER_MANUAL_PAGE: {
    path: "/user-manual",
    component: UserManual,
    layout: DefaultLayout,
  },
  EDIT_PROJECT_ADMINS: {
    path: "/edit-project-admins",
    component: EditProjectAdmins,
    layout: DefaultLayout,
  },
};
