import React, { useState } from "react";
import PropTypes from "prop-types";
import { ROUTES } from "../../../constants/routes";
import Button from "../../Button";
import IconButton from "../../IconButton";
import Dropdown from "../../Dropdown";
import CompleteRetroModal from "./CompleteRetroModal";
import { ReactComponent as ListIcon } from "../../../assets/icons/list.svg";
import { ReactComponent as MoreIcon } from "../../../assets/icons/more.svg";
import "./ActiveProjectPanel.scss";

const ActiveProjectPanel = (props) => {
  const {
    projectName,
    projectId,
    sprintName,
    sprintDate,
    sprintStatus,
    sprintId,
    isAdmin = false,
  } = props;

  const [isCompleteRetroModalOpen, setIsCompleteRetroModalOpen] = useState(
    false
  );

  const adminActions = [
    {
      text: "Edit sprint info",
      to: `${ROUTES.RETRO_PROJECTS.path}/${projectId}/admin/edit-sprint`,
      disabled: sprintStatus !== 0,
    },
    {
      text: "Start retro",
      onClick: () => setIsCompleteRetroModalOpen(true),
      disabled: sprintStatus !== 0,
    },
    {
      text: "Final form",
      to: `${ROUTES.RETRO_PROJECTS.path}/${projectId}/admin/complete-sprint`,
      disabled: sprintStatus !== 1,
    },
    { text: "Edit project admins", to: ROUTES.EDIT_PROJECT_ADMINS.path },
  ];

  const getButtonTitle = () => {
    if (new Date(sprintDate) > new Date()) {
      return "Can't evaluate for now";
    }

    if (sprintStatus !== 0) {
      return "Retro evaluation has finished";
    }

    return "Evaluate";
  };

  return (
    <>
      <div className="panel active-project-panel">
        <div className="active-project-panel-info">
          <h2 className="active-project-panel-info-title">{projectName}</h2>
          <div className="active-project-panel-info-sprint">
            <span>{sprintName}</span>{" "}
            <span className="active-project-panel-info-sprint-date">
              ({sprintDate})
            </span>
          </div>
        </div>
        <div className="active-project-panel-actions">
          <Button
            to={`${ROUTES.RETRO_PROJECTS.path}/${projectId}`}
            disabled={sprintStatus !== 0 || new Date(sprintDate) > new Date()}
            title={getButtonTitle()}
          >
            Evaluate
          </Button>
          <IconButton
            to={`${ROUTES.RETRO_PROJECTS.path}/${projectId}/sprints`}
            icon={<ListIcon />}
          />
          {isAdmin && (
            <Dropdown menuItems={adminActions}>
              <IconButton icon={<MoreIcon />} />
            </Dropdown>
          )}
        </div>
      </div>
      <CompleteRetroModal
        isOpen={isCompleteRetroModalOpen}
        onClose={() => setIsCompleteRetroModalOpen(false)}
        sprintId={sprintId}
      />
    </>
  );
};

ActiveProjectPanel.propTypes = {
  projectName: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  sprintName: PropTypes.string.isRequired,
  sprintDate: PropTypes.string.isRequired,
  sprintStatus: PropTypes.number.isRequired,
  sprintId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool,
};

export default ActiveProjectPanel;
