import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as msTeams from "@microsoft/teams-js";
import {
  getTeamsConfigInfo,
  resetTeamsConfigInfo,
} from "../../actions/getTeamsConfigInfo";
import { hasErrors, areInitiallyLoading } from "../../utils/pageStates";
import { setPageTitle } from "../../actions/setPageTitle";
import Spinner from "../../components/Spinner";
import EditProjectAdminsForm from "../../components/EditProjectAdminsForm";
import { ROUTES } from "../../constants/routes";

const EditProjectAdmins = () => {
  const dispatch = useDispatch();

  const teamsConfigInfo = useSelector(({ teamsConfigInfo }) => teamsConfigInfo);

  const fetches = [teamsConfigInfo];

  useEffect(() => {
    msTeams.initialize();
    msTeams.getContext(({ channelId }) =>
      dispatch(getTeamsConfigInfo(channelId))
    );
    dispatch(
      setPageTitle({
        text: "Select project admins",
        backPath: ROUTES.RETRO_HOME.path,
      })
    );

    return () => dispatch(resetTeamsConfigInfo());
  }, [dispatch]);

  if (hasErrors(fetches)) {
    return <h2>Something went wrong :(</h2>;
  }

  if (areInitiallyLoading(fetches)) {
    return <Spinner />;
  }

  return <EditProjectAdminsForm />;
};

export default EditProjectAdmins;
