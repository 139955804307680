import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Prompt } from "react-router";
import { Formik, Form, yupToFormErrors } from "formik";
import { object, array, number, string } from "yup";
import { Intent } from "@blueprintjs/core";
import Toaster from "../Toaster";
import _fetch from "../../utils/fetch";
import PersonalMood from "./PersonalMood";
import Evaluations from "./Evaluations";
import RetroComments from "./RetroComments";
import Button from "../Button";
import FormikLocalStorageSaver from "../FormikLocalStorageSaver";
import LeaveFormPageWarning from "../LeaveFormPageWarning";
import formatDate from "../../utils/formatDate";
import { ROUTES } from "../../constants/routes";
import "./RetroForm.scss";

const commentSchema = object().shape({ comment: string().min(1).required() });
const evaluationSchema = object().shape({
  competences: array().of(
    object().shape({
      competenceValue: number().min(0).max(5).required(),
    })
  ),
});
const validationSchema = object().shape({
  evaluations: array().of(evaluationSchema),
  doWellComments: array().of(commentSchema),
  improveComments: array().of(commentSchema),
  personalMood: number().min(1).max(5).required(),
});

const RetroForm = ({ projectId }) => {
  const [redirectToRetroHome, setRedirectToRetroHome] = useState(false);

  const userPersonalMood = useSelector(
    ({ userPersonalMood }) => userPersonalMood.payload
  );
  const userEvaluations = useSelector(
    ({ userEvaluations }) => userEvaluations.payload
  );
  const userComments = useSelector(({ userComments }) => userComments.payload);
  const userProject = useSelector(({ userProject }) => userProject.payload);
  const projectCompetences = useSelector(
    ({ project }) => project.payload.competences
  );
  const mandatoryUser = useSelector(
    ({ mandatoryUser }) => mandatoryUser.payload
  );
  const selfUser = useSelector(({ selfUser }) => selfUser.payload);
  const sprintUsers = useSelector(({ sprintUsers }) => sprintUsers.payload);

  const sprintUsersToEvaluationsEntity = () => {
    let sortedUsers = sprintUsers.filter(
      (item) =>
        item.userId !== mandatoryUser.userId && item.userId !== selfUser.userId
    );

    sortedUsers.unshift(
      {
        fullName: "Self evaluation",
        userId: selfUser.userId,
      },
      mandatoryUser
    );

    return sortedUsers;
  };

  const isEdit = () =>
    !!(
      userPersonalMood.personalMoodScore &&
      userEvaluations.length > 0 &&
      userComments.doWellComments.length > 0 &&
      userComments.improveComments.length > 0
    );

  const localStorageName = `retroEvaluation_${projectId}`;

  const setInitialValues = () => {
    const evaluations = isEdit()
      ? userEvaluations
      : [
          {
            evaluatedUserId: selfUser.userId,
            evaluationCompetences: projectCompetences,
            isMandatory: true,
          },
          {
            evaluatedUserId: mandatoryUser.userId,
            evaluationCompetences: projectCompetences,
            isMandatory: true,
          },
          ...Array(userProject.sprint.mandatoryEvaluationCount - 1) // -1 because first is selected randomly
            .fill()
            .map(() => ({
              evaluatedUserId: "",
              evaluationCompetences: projectCompetences,
              isMandatory: true,
            })),
        ];

    return {
      personalMood: isEdit() ? userPersonalMood.personalMoodScore : undefined,
      evaluations: evaluations.map((evaluation) => {
        return {
          evaluationId: evaluation.evaluationId,
          competences: evaluation.evaluationCompetences.map((competence) => {
            return {
              competenceValue:
                competence.competenceValue >= 0
                  ? competence.competenceValue
                  : undefined,
              competenceName: competence.competenceName,
              competenceId: competence.competenceId,
            };
          }),
          evaluatedUser: evaluation.evaluatedUserId,
          isMandatory: true,
        };
      }),
      doWellComments: isEdit()
        ? userComments.doWellComments
        : [{ comment: "" }],
      improveComments: isEdit()
        ? userComments.improveComments
        : [{ comment: "" }],
      isIncognito: isEdit()
        ? userEvaluations[0].isAnonymous
        : userProject.sprint.allowAnonymous,
    };
  };

  const validate = async (values) => {
    const errors = await validationSchema
      .validate(values, { abortEarly: false })
      .catch((err) => {
        Toaster.show({
          message: "Please fill all required fields",
          intent: Intent.DANGER,
        });
        return err;
      });

    return yupToFormErrors(errors);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const { personalMood } = values;

    const doWellComments = values.doWellComments.map((item) => ({
      Comment: item.comment,
      CommentType: 0,
      commentId: item.commentId,
    }));

    const improveComments = values.improveComments.map((item) => ({
      Comment: item.comment,
      CommentType: 1,
      commentId: item.commentId,
    }));

    const formData = {
      personalMood: {
        personalMoodId: isEdit() ? userPersonalMood.personalMoodId : 0,
        personalMoodScore: parseInt(personalMood, 10),
      },
      evaluationList: values.evaluations.map((evaluation) => {
        return {
          evaluationId: evaluation.evaluationId,
          evaluatedUserId: parseInt(evaluation.evaluatedUser, 10),
          isAnonymous: values.isIncognito,
          evaluationCompetences: evaluation.competences.map((item) => ({
            competenceId: item.competenceId,
            competenceName: item.competenceName,
            competenceValue: parseInt(item.competenceValue, 10),
          })),
        };
      }),
      RetroComments: doWellComments.concat(improveComments),
    };

    const response = await _fetch(
      isEdit()
        ? `api/Sprints/${userProject.sprint.sprintId}/User-Retro-Form`
        : `api/Sprints/${userProject.sprint.sprintId}/User-Retro-Forms`,
      {
        method: isEdit() ? "PUT" : "POST",
        data: formData,
      }
    );

    if (response.success) {
      Toaster.show({
        message: "Results sent",
        intent: Intent.SUCCESS,
      });
      localStorage.removeItem(localStorageName);
      setRedirectToRetroHome(true);
    } else if (response.data.errors) {
      Object.keys(response.data.errors).map((err) => {
        return Toaster.show({
          message: response.data.errors[err],
          intent: Intent.DANGER,
        });
      });
      setSubmitting(false);
    }
  };

  if (redirectToRetroHome) {
    return <Redirect to={ROUTES.RETRO_HOME.path} />;
  }

  return (
    <>
      <div className="project-title">
        <strong>{userProject.projectName}</strong>,{" "}
        {userProject.sprint.sprintName}
        <span> ({formatDate(userProject.sprint.retroStartDate)})</span>
      </div>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={setInitialValues()}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ errors, isSubmitting, dirty }) => (
          <>
            <Form className="retro-form">
              <div className="retro-form-inputs-container">
                <PersonalMood error={!!errors.personalMood} />
                <Evaluations
                  sprintUsers={sprintUsersToEvaluationsEntity()}
                  competences={projectCompetences}
                  allowIncognito={userProject.sprint.allowAnonymous}
                />
                <RetroComments />
              </div>
              <Button size="large" type="submit" disabled={isSubmitting}>
                {isEdit() ? "Save" : "Evaluate"}
              </Button>
              <FormikLocalStorageSaver storageName={localStorageName} />
            </Form>
            <Prompt
              when={dirty}
              message="Are you sure you want to leave without submitting?"
            />
            <LeaveFormPageWarning />
          </>
        )}
      </Formik>
    </>
  );
};

RetroForm.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default RetroForm;
