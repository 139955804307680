import React from "react";
import PropTypes from "prop-types";
import "./RadioButton.scss";

const RadioButton = ({ field, text, ...props }) => {
  return (
    <label className="radio-button">
      <input {...field} {...props} type="radio" />
      <span />
      <span className="radio-button-text">{text}</span>
    </label>
  );
};

RadioButton.propTypes = {
  field: PropTypes.object,
  text: PropTypes.string,
};

export default RadioButton;
