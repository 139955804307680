import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setTeamsConfigInfoInit = () => {
  return {
    type: "GET_TEAMS_CONFIG_INFO_INIT",
  };
};

const setFetchedTeamsConfigInfoLoading = () => {
  return {
    type: "GET_TEAMS_CONFIG_INFO_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedTeamsConfigInfo = (response) => {
  return {
    type: "GET_TEAMS_CONFIG_INFO",
    payload: response,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedTeamsConfigInfoError = () => {
  return {
    type: "GET_TEAMS_CONFIG_INFO_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getTeamsConfigInfo = (channelId) => {
  return (dispatch) => {
    dispatch(setFetchedTeamsConfigInfoLoading());
    _fetch(`api/Teams/${channelId}/config-info`)
      .then((response) => {
        if (response.success) {
          dispatch(setFetchedTeamsConfigInfo(response));
        } else {
          dispatch(setFetchedTeamsConfigInfoError());
        }
      })
      .catch(() => dispatch(setFetchedTeamsConfigInfoError()));
  };
};

export const resetTeamsConfigInfo = () => {
  return (dispatch) => {
    dispatch(setTeamsConfigInfoInit());
  };
};
