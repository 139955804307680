import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form, FieldArray } from "formik";
import { Redirect } from "react-router-dom";
import { Prompt } from "react-router";
import { object, array, string } from "yup";
import { yupToFormErrors } from "formik";
import { useSelector } from "react-redux";
import { Intent } from "@blueprintjs/core";
import Toaster from "../../Toaster";
import Tabs from "../../Tabs";
import Button from "../../Button";
import CommentInputPanel from "./CommentInputPanel";
import FormikLocalStorageSaver from "../../FormikLocalStorageSaver";
import LeaveFormPageWarning from "../../LeaveFormPageWarning";
import _fetch from "../../../utils/fetch";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import { ROUTES } from "../../../constants/routes";
import formatDate from "../../../utils/formatDate";
import "./AdminCompleteSprintForm.scss";

const actionPointSchema = object().shape({
  text: string().min(1).required(),
});
const commentSchema = object().shape({
  text: string().min(1).required(),
  actionPoints: array().of(actionPointSchema),
});
const validationSchema = object().shape({
  doWellComments: array().of(commentSchema).required(),
  improveComments: array().of(commentSchema).required(),
});

const TAB_TYPES = [
  { index: 0, name: "doWellComments" },
  { index: 1, name: "improveComments" },
];

const AdminCompleteSprintForm = ({ projectId }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [autoFocusCommentInput, setAutoFocusCommentInput] = useState(false);
  const [redirectToRetroMain, setRedirectToRetroMain] = useState(false);

  const { doWellComments, improveComments } = useSelector(
    ({ retroComments }) => retroComments.payload
  );
  const { sprintId } = useSelector(
    ({ userProject }) => userProject.payload.sprint
  );

  const commentsToFormValueEntity = (comments) =>
    comments.map((item) => ({
      id: item.id,
      text: item.text,
      actionPoints: [],
    }));

  const getCommentsFieldArray = (name, comments) => (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {comments.map((_item, index) => (
            <CommentInputPanel
              key={`${arrayHelpers.name}_${index}`}
              index={index}
              arrayHelpers={arrayHelpers}
              autoFocus={autoFocusCommentInput}
            />
          ))}
          <Button
            className="admin-complete-sprint-form-add-comment-button"
            onClick={() => {
              arrayHelpers.push({ text: "", actionPoints: [] });
              setAutoFocusCommentInput(true);
            }}
            icon={<AddIcon />}
          >
            Add another comment
          </Button>
        </>
      )}
    />
  );

  const localStorageName = `adminCompleteSprintForm_${projectId}_${sprintId}`;

  const setInitialValues = () => ({
    doWellComments: commentsToFormValueEntity(doWellComments),
    improveComments: commentsToFormValueEntity(improveComments),
  });

  const validate = async (values) => {
    const errors = await validationSchema
      .validate(values, { abortEarly: false })
      .catch((err) => {
        Toaster.show({
          message: "Please fill all required fields",
          intent: Intent.DANGER,
        });
        return err;
      });

    const formikErrors = yupToFormErrors(errors);

    const nonActiveTab = TAB_TYPES.find((tab) => tab.index !== activeTab);
    if (
      !formikErrors[TAB_TYPES[activeTab].name] &&
      formikErrors[nonActiveTab.name]
    ) {
      setActiveTab(nonActiveTab.index);
    }

    return formikErrors;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const commentsToFormData = (comments, commentType) =>
      comments.map((item) => ({
        commentId: item.id,
        comment: item.text,
        commentType: commentType,
        starred: true,
        actionPoints: item.actionPoints.map((actionPoint) => ({
          actionPoint: actionPoint.text,
          assignedUserId:
            actionPoint.assignedUser && actionPoint.assignedUser.value
              ? actionPoint.assignedUser.value
              : null,
          completionDate: actionPoint.assignedDate
            ? formatDate(actionPoint.assignedDate)
            : null,
        })),
      }));

    const formData = commentsToFormData(values.doWellComments, 0).concat(
      commentsToFormData(values.improveComments, 1)
    );

    const response = await _fetch(`api/Sprints/${sprintId}/Admin-Retro-Forms`, {
      method: "POST",
      data: formData,
    });

    if (response.success) {
      Toaster.show({
        message: "Results sent",
        intent: Intent.SUCCESS,
      });
      localStorage.removeItem(localStorageName);
      setRedirectToRetroMain(true);
    } else if (response.data.errors) {
      Object.keys(response.data.errors).map((err) => {
        return Toaster.show({
          message: response.data.errors[err],
          intent: Intent.DANGER,
        });
      });
      setSubmitting(false);
    }
  };

  if (!improveComments || !doWellComments) {
    return null;
  }

  if (redirectToRetroMain) {
    return <Redirect to={ROUTES.RETRO_HOME.path} />;
  }

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={setInitialValues()}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, dirty }) => {
        const tabs = [
          {
            text: "What we did well?",
            component: getCommentsFieldArray(
              "doWellComments",
              values.doWellComments
            ),
          },
          {
            text: "What should we improve on?",
            component: getCommentsFieldArray(
              "improveComments",
              values.improveComments
            ),
          },
        ];

        return (
          <>
            <Form className="admin-complete-sprint-form">
              <Tabs
                tabs={tabs}
                onTabClick={(index) => setActiveTab(index)}
                activeTab={activeTab}
              />
              <Button
                type="submit"
                size="large"
                className="admin-complete-sprint-form-button"
                disabled={isSubmitting}
              >
                Save
              </Button>
              <FormikLocalStorageSaver storageName={localStorageName} />
            </Form>
            <Prompt
              when={dirty}
              message="Are you sure you want to leave without submitting?"
            />
            <LeaveFormPageWarning />
          </>
        );
      }}
    </Formik>
  );
};

AdminCompleteSprintForm.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default AdminCompleteSprintForm;
