import React from "react";
import GreyContainer from "../../GreyContainer";
import RetroCommentsPanel from "./RetroCommentsPanel";
import "./RetroComments.scss";

const RetroComments = () => {
  return (
    <GreyContainer title="Evaluate this sprint">
      <div className="retro-comments">
        <RetroCommentsPanel name="doWellComments" title="What we did well?" />
        <RetroCommentsPanel
          name="improveComments"
          title="What should we improve on?"
        />
      </div>
    </GreyContainer>
  );
};

export default RetroComments;
