import React, { useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { DateInput as BPDateInput } from "@blueprintjs/datetime";
import formatDateWithTime from "../../utils/formatDateWithTime";
import formatDate from "../../utils/formatDate";
import { subYears, addYears } from "date-fns";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import "./DateInput.scss";

const DateInput = (allProps) => {
  const {
    field,
    setFieldValue,
    withTime = false,
    text = null,
    maxDate = null,
    minDate = null,
    error = false,
    ...props
  } = allProps;

  const ref = useRef();

  const jsDateFormatter = {
    // note that the native implementation of Date functions differs between browsers
    formatDate: (date) =>
      withTime ? formatDateWithTime(date) : formatDate(date),
    parseDate: (str) => new Date(str),
    placeholder: "YYYY-MM-DD",
    showActionsBar: false,
    shortcuts: false,
  };

  if (withTime) {
    props.timePrecision = true;
  }

  delete props.form;

  const dateInput = (
    <BPDateInput
      {...field}
      {...props}
      ref={ref}
      initialMonth={new Date()}
      dayPickerProps={{ firstDayOfWeek: 1 }}
      minDate={minDate ? minDate : subYears(new Date(), 5)}
      maxDate={maxDate ? maxDate : addYears(new Date(), 5)}
      rightElement={
        <CalendarIcon
          onClick={() => ref.current.handleInputFocus()}
          className="date-input-icon"
        />
      }
      className={cx("date-input", { "date-input-error": error })}
      onChange={(selectedDate) => setFieldValue(field.name, selectedDate)}
      {...jsDateFormatter}
    />
  );

  if (!text) {
    return dateInput;
  }

  return (
    <div className="date-input-container">
      <span className="date-input-title">{text}</span>
      {error ? (
        <div className="date-input-error-container">
          {dateInput}
          <span className="alert-text">This field is required</span>
        </div>
      ) : (
        dateInput
      )}
    </div>
  );
};

DateInput.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  field: PropTypes.object,
  withTime: PropTypes.bool,
  text: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  error: PropTypes.bool,
};

export default DateInput;
