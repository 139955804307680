import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import formatDate from "../../../../utils/formatDate";
import font from "../../../../assets/fonts/gilroy-medium.woff";

Font.register({ family: "Gilroy", src: font });

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFFF",
    paddingTop: 30,
    fontFamily: "Gilroy",
  },
  table: {
    flexDirection: "row",
    margin: 30,
  },
  column: {
    flexDirection: "column",
    width: `${100 / 5}%`,
  },
  tableHeaderNoBorder: {
    fontSize: 10,
    height: "40px",
    paddingTop: 10,
    paddingLeft: 3,
    paddingRight: 3,
    borderBottom: "1px solid #91c88c",
    textAlign: "center",
  },
  tableHeader: {
    fontSize: 10,
    height: "40px",
    paddingTop: 10,
    paddingLeft: 3,
    paddingRight: 3,
    borderLeft: "1px solid #91c88c",
    borderBottom: "1px solid #91c88c",
    textAlign: "center",
  },
  tableItemNoBorder: {
    fontSize: 14,
    padding: 10,
    paddingLeft: 3,
    paddingRight: 3,
    textAlign: "center",
  },
  tableItem: {
    fontSize: 14,
    borderLeft: "1px solid #91c88c",
    padding: 10,
    paddingLeft: 3,
    paddingRight: 3,
    textAlign: "center",
  },
  moodLabel: {
    fontSize: 14,
    marginBottom: 10,
  },
  imageSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  moodScore: {
    marginBottom: 20,
  },
  commentsSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 20,
    marginRight: 20,
    marginLeft: 20,
  },
  sectionP:{
    display: "flex",
    fontSize: 12,
    paddingTop: 10,
    marginRight: 20,
    marginLeft: 20,
  },
  section: {
    width: "50%",
  },
  doWellSection: {
    backgroundColor: "#f3f9f5",
    border: "1px solid #91c88c",
    padding: 10,
    marginBottom: 10,
  },
  improveSection: {
    backgroundColor: "#fff8f8",
    border: "1px solid #d04438",
    padding: 10,
    marginBottom: 10,
    marginLeft: 12,
  },
  commentHeader: {
    fontSize: 14,
  },
  doWellComment: {
    fontSize: 12,
    marginLeft: 0,
    marginBottom: 6,
  },
  improveComment: {
    fontSize: 13,
    marginLeft: 12,
    marginBottom: 6,
  },
  doWellActionPoint: {
    fontSize: 11,
    marginLeft: 12,
    marginBottom: 6,
  },
  improveActionPoint: {
    fontSize: 11,
    marginLeft: 24,
    marginBottom: 6,
  },
  actionPointInfo: {
    color: "#adb3bc",
    fontWeight: "bold",
    fontSize: 10,
  },
  image: {
    height: 300,
    width: 450,
  },
});

const PDFfile = (props) => {
  const {
    chartBase64,
    moodScore,
    doWellComments,
    improveComments,
    competenceAverages,
    sprintUsers,
    sprintName
  } = props;

  const actionPointInfo = (actionPoint) => (
    <Text style={styles.actionPointInfo}>
      {(actionPoint.assignedFullUserName || actionPoint.completionDate) &&
        " - "}
      {!!actionPoint.assignedFullUserName && actionPoint.assignedFullUserName}
      {actionPoint.assignedFullUserName && actionPoint.completionDate && ","}
      {!!actionPoint.completionDate &&
        ` ${formatDate(actionPoint.completionDate)}`}
    </Text>
  );

  const doWellCommentsList = doWellComments.map((doWellComment, index) => (
    <View wrap={false} key={`pdf_do_well_comment_${doWellComment.id}`}>
      <Text style={styles.doWellComment}>
        {index + 1}. {doWellComment.text}
      </Text>
      <View>
        {doWellComment.actionPoints.map((actionPoint, index) => (
          <Text
            style={styles.doWellActionPoint}
            key={`do_well_action_point${index}`}
          >
            • {actionPoint.actionPoint}
            {actionPointInfo(actionPoint)}
          </Text>
        ))}
      </View>
    </View>
  ));

  const sprintUserList = sprintUsers.map((sprintUser, index) => (
    <View wrap={false} >
      <Text>
        {index + 1}. {sprintUser.fullName}
      </Text>
    </View>
  ));

  const improveCommentsList = improveComments.map((improveComment, index) => (
    <View wrap={false} key={`pdfImproveComment_${improveComment.id}`}>
      <Text style={styles.improveComment}>
        {index + 1}. {improveComment.text}
      </Text>
      <View>
        {improveComment.actionPoints.map((actionPoint, index) => (
          <Text
            style={styles.improveActionPoint}
            key={`improve_action_point${index}`}
          >
            • {actionPoint.actionPoint}
            {actionPointInfo(actionPoint)}
          </Text>
        ))}
      </View>
    </View>
  ));

  const pdfColumn = (header, item, index) => (
    <View style={styles.column} key={`pdf_competence_col_${index}`}>
      <Text
        style={index === 0 ? styles.tableHeaderNoBorder : styles.tableHeader}
      >
        {header}
      </Text>
      <Text style={index === 0 ? styles.tableItemNoBorder : styles.tableItem}>
        {item}
      </Text>
    </View>
  );

  return (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        <View style={styles.imageSection}>
        <Text style={styles.moodLabel}>Sprint: {sprintName}</Text>
          <Text style={styles.moodLabel}>Team mood:</Text>
          <Text style={styles.moodScore}>{moodScore} / 5</Text>
          <Text style={styles.moodLabel}>Team competences:</Text>
          <Image
            style={styles.image}
            src={`data:application/pdf;base64,${chartBase64}`}
          />
        </View>
        <View style={styles.table}>
          {competenceAverages.competences.map((competence, index) =>
            pdfColumn(
              competence,
              competenceAverages.teamAverages.data[index],
              index
            )
          )}
        </View>
        <View style={styles.commentsSection}>
          <View style={styles.section}>
            <View style={styles.doWellSection}>
              <Text style={styles.commentHeader}>What did we do well?</Text>
            </View>
            {doWellCommentsList}
          </View>
          <View style={styles.section}>
            <View style={styles.improveSection}>
              <Text style={styles.commentHeader}>
                What should we have done better?
              </Text>
            </View>
            {improveCommentsList}
          </View>
          </View>
        <View style={styles.sectionP}>
          <View>
              <Text style={styles.commentHeader}>
                Participant list:
              </Text>
          </View>
            {sprintUserList}
        </View>
      </Page>
    </Document>
  );
};

export default PDFfile;
