import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Tag from "../../Tag";
import FeedbackPanelInfo from "../FeedbackPanel/FeedbackPanelInfo";
import FeedbackRequestQuestionsAnswers from "../FeedbackRequestQuestionsAnswers";
import BlurredCollapse from "../../BlurredCollapse";
import { setFeedbacksToExcelExport } from "../../../actions/setFeedbacksToExcelExport";
import "./FeedbackRequestAnswerPanel.scss";

const FeedbackRequestAnswerPanel = (props) => {
  const {
    fromName = null,
    toName = null,
    date,
    data,
    isViewed = true,
    selectable = false,
    feedbackRequestId,
  } = props;

  const dispatch = useDispatch();

  const feedbacksToExcelExport = useSelector(
    ({ feedbacksToExcelExport }) => feedbacksToExcelExport
  );

  const onSelectFn = selectable
    ? () => {
        let selectedFeedbackIds = feedbacksToExcelExport.selectedIds;

        if (selectedFeedbackIds.includes(feedbackRequestId)) {
          const idIndex = selectedFeedbackIds.indexOf(feedbackRequestId);
          selectedFeedbackIds.splice(idIndex, 1);
        } else {
          selectedFeedbackIds.push(feedbackRequestId);
        }

        dispatch(
          setFeedbacksToExcelExport({
            ...feedbacksToExcelExport,
            selectedIds: selectedFeedbackIds,
          })
        );
      }
    : null;

  return (
    <BlurredCollapse
      isOpen={false}
      className="panel feedback-request-answer-panel"
      onSelect={onSelectFn}
      isSelected={feedbacksToExcelExport.selectedIds.includes(
        feedbackRequestId
      )}
    >
      <FeedbackPanelInfo
        fromName={fromName}
        toName={toName}
        isViewed={isViewed}
        date={date}
      />
      <FeedbackRequestQuestionsAnswers data={data} />
      <Tag className="feedback-request-answer-panel-tag" color="red">
        Answer
      </Tag>
    </BlurredCollapse>
  );
};

FeedbackRequestAnswerPanel.propTypes = {
  fromName: PropTypes.string,
  toName: PropTypes.string,
  date: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  isViewed: PropTypes.bool,
  selectable: PropTypes.bool,
};

export default FeedbackRequestAnswerPanel;
