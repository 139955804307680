import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setMandatoryUserInit = () => {
  return {
    type: "GET_MANDATORY_USER_INIT",
  };
};

const setFetchedMandatoryUserLoading = () => {
  return {
    type: "GET_MANDATORY_USER_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedMandatoryUser = (response) => {
  return {
    type: "GET_MANDATORY_USER",
    payload: response.data,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedMandatoryUserError = () => {
  return {
    type: "GET_MANDATORY_USER_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getMandatoryUser = (projectId) => {
  return (dispatch) => {
    dispatch(setFetchedMandatoryUserLoading());
    _fetch(`api/projects/${projectId}/mandatory-user`)
      .then((response) => {
        if (response.success) {
          dispatch(setFetchedMandatoryUser(response));
        } else {
          dispatch(setFetchedMandatoryUserError());
        }
      })
      .catch(() => dispatch(setFetchedMandatoryUserError()));
  };
};

export const resetMandatoryUser = () => {
  return (dispatch) => {
    dispatch(setMandatoryUserInit());
  };
};
