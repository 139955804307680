import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setReceivedFeedbackAnswersInit = () => {
  return {
    type: "GET_RECEIVED_FEEDBACK_ANSWERS_INIT",
  };
};

const setFetchedReceivedFeedbackAnswersLoading = () => {
  return {
    type: "GET_RECEIVED_FEEDBACK_ANSWERS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedReceivedFeedbackAnswers = (response) => {
  return {
    type: "GET_RECEIVED_FEEDBACK_ANSWERS",
    payload: response.data,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedReceivedFeedbackAnswersError = () => {
  return {
    type: "GET_RECEIVED_FEEDBACK_ANSWERS_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getReceivedFeedbackAnswers = () => {
  return (dispatch) => {
    dispatch(setFetchedReceivedFeedbackAnswersLoading());
    _fetch(`api/FeedbackRequests?answered=true&given=true`)
      .then((response) => {
        if (response.data) {
          dispatch(setFetchedReceivedFeedbackAnswers(response));
        } else {
          dispatch(setFetchedReceivedFeedbackAnswersError());
        }
      })
      .catch(() => dispatch(setFetchedReceivedFeedbackAnswersError()));
  };
};

export const resetReceivedFeedbackAnswers = () => {
  return (dispatch) => {
    dispatch(setReceivedFeedbackAnswersInit());
  };
};
