import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as msTeams from "@microsoft/teams-js";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Intent, Spinner, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _fetch from "../../../../utils/fetch";
import Modal from "../../../Modal";
import Button from "../../../Button";
import Toaster from "../../../Toaster";
import { getUserProjects } from "../../../../actions/getUserProjects";
import {
  getRetroComments,
  resetRetroComments,
} from "../../../../actions/getRetroComments";

const CompleteRetroModal = ({ isOpen, onClose, sprintId }) => {
  const dispatch = useDispatch();

  const { doWellComments, improveComments } = useSelector(
    ({ retroComments }) => retroComments.payload
  );

  const canComplete = () =>
    doWellComments.length > 0 && improveComments.length > 0;

  useEffect(() => {
    if (isOpen) {
      dispatch(getRetroComments(sprintId));
    } else {
      dispatch(resetRetroComments());
    }

    return () => dispatch(resetRetroComments());
  }, [dispatch, sprintId, isOpen]);

  const submitPatch = () => {
    _fetch(`api/Sprint/${sprintId}/AveragesCalculated`, {
      method: "PATCH",
      data: true,
    }).then((response) => {
      const message = {
        message: "Retro evaluations has been succesfully completed",
        intent: Intent.SUCCESS,
      };

      if (response.success) {
        msTeams.initialize();
        msTeams.getContext(({ channelId }) => {
          dispatch(getUserProjects(channelId));
        });
        onClose();
      } else {
        message.message = "An error has occured";
        message.intent = Intent.DANGER;
      }
      Toaster.show(message);

      return response;
    });
  };

  const submitDelete = () => {
    _fetch(`api/Sprints/${sprintId}`, {
      method: "DELETE",
    }).then((response) => {
      const message = {
        message: "Sprint has been deleted",
        intent: Intent.SUCCESS,
      };

      if (response.success) {
        msTeams.getContext(({ channelId }) => {
          dispatch(getUserProjects(channelId));
        });
        onClose();
      } else {
        message.message = "An error has occured";
        message.intent = Intent.DANGER;
      }
      Toaster.show(message);

      return response;
    });
  };

  const handleSubmit = () => {
    if (canComplete()) {
      submitPatch();
    } else {
      submitDelete();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {doWellComments && improveComments ? (
        <Formik initialValues={{}} onSubmit={handleSubmit}>
          {({ isSubmitting }) => {
            return (
              <Form>
                <p>
                  {canComplete()
                    ? "Are you sure you want to start retro?"
                    : "This sprint has no evaluations, would you like to delete it?"}
                </p>
                <div className="modal-actions">
                  <Button secondary onClick={onClose}>
                    No
                  </Button>
                  <Button
                    type="submit"
                    danger={!canComplete()}
                    icon={
                      !canComplete() ? (
                        <Icon
                          icon={IconNames.TRASH}
                          iconSize={Icon.SIZE_LARGE}
                        />
                      ) : null
                    }
                    disabled={isSubmitting}
                  >
                    Yes
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};

CompleteRetroModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sprintId: PropTypes.number.isRequired,
};

export default CompleteRetroModal;
