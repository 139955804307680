import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import GreyContainer from "../GreyContainer";
import PersonalMoodContainer from "../PersonalMoodContainer";
import AveragesChart from "./AveragesChart";
import Tabs from "../Tabs";
import RetroReviewCommentPanel from "./RetroReviewCommentPanel";
import RetroReviewDetailedEvaluation from "./RetroReviewDetailedEvaluation";
import ConfluenceExport from "./ConfluenceExport";
import Button from "../Button";
import TextButton from "../TextButton";
import PDFButton from "./PDFButton";
import formatDate from "../../utils/formatDate";
import "./RetroReviewContainer.scss";

import RadarChart from "../RadarChart";
import { dataToChartDataset } from "../../utils/dataToChartDataset";

const TAB_TYPES = [
  { index: 0, type: "doWellComments" },
  { index: 1, type: "improveComments" },
];

const RetroReviewContainer = ({ projectId }) => {
  const [activeTab, setActiveTab] = useState({
    index: 0,
    type: "doWellComments",
  });
  const [isDetailedEvaluationOpen, setIsDetailedEvaluationOpen] = useState(
    false
  );
  const [isConfluenceExportOpen, setIsConfluenceExportOpen] = useState(false);

  const project = useSelector(({ project }) => project.payload);
  const sprint = useSelector(({ sprint }) => sprint.payload);
  const teamCompetencesAverages = useSelector(
    ({ teamCompetencesAverages }) => teamCompetencesAverages.payload
  );
  const userCompetencesAverages = useSelector(
    ({ userCompetencesAverages }) => userCompetencesAverages.payload
  );
  const userEvaluation = useSelector(
    ({ userEvaluation }) => userEvaluation.payload
  );
  const { doWellComments, improveComments } = useSelector(
    ({ retroComments }) => retroComments.payload
  );
  const sprintParticipantCount = useSelector(
    ({ sprintParticipantCount }) => sprintParticipantCount.payload
  );

  const allEvaluations = [
    teamCompetencesAverages.teamAverages,
    userCompetencesAverages,
    userEvaluation,
  ];

  const getTab = (text, data) => ({
    text: text,
    component: (
      <ol className="retro-review-container-comments-list">
        {data.map((item, index) => (
          <RetroReviewCommentPanel
            key={`retro_review_comment_${item.id}`}
            index={index}
            text={item.text}
            actionPoints={item.actionPoints}
            tabType={activeTab.type}
          />
        ))}
      </ol>
    ),
  });

  const tabs = [
    getTab("What we did well?", doWellComments ? doWellComments : []),
    getTab(
      "What should we improve on?",
      improveComments ? improveComments : []
    ),
  ];

  return (
    <>
      <div className="project-title">
        <strong>{project.projectName}</strong>, {sprint.sprintName}
        <span> ({formatDate(sprint.retroStartDate)})</span>
      </div>
      <div className="retro-review-container-top">
        <GreyContainer title="Team mood" blueTitle>
          <PersonalMoodContainer moodAverage={sprint.moodAverage} />
        </GreyContainer>
        <GreyContainer
          title="Team evaluation"
          blueTitle
          topRightComponent={
            <TextButton onClick={() => setIsDetailedEvaluationOpen(true)}>
              Show detailed evaluation
            </TextButton>
          }
        >
          <AveragesChart
            competences={teamCompetencesAverages.competences}
            allEvaluations={allEvaluations}
            optionsData={userCompetencesAverages.data}
          />
        </GreyContainer>
      </div>
      <Tabs
        className="retro-review-container-comments"
        tabs={tabs}
        activeTab={activeTab.index}
        onTabClick={(index) => setActiveTab(TAB_TYPES[index])}
      />
      <div className="retro-review-container-export-buttons-container">
        <PDFButton />
        {sprint.isAdmin && (
          <Button secondary onClick={() => setIsConfluenceExportOpen(true)}>
            <i className="fab fa-confluence" /> Export to Confluence
          </Button>
        )}
      </div>
      <RetroReviewDetailedEvaluation
        isOpen={isDetailedEvaluationOpen}
        onClose={() => setIsDetailedEvaluationOpen(false)}
        competences={teamCompetencesAverages.competences}
        teamAverages={teamCompetencesAverages.teamAverages.data}
        teamMood={sprint.moodAverage}
        participantsCount={sprintParticipantCount}
      />
      <ConfluenceExport
        isOpen={isConfluenceExportOpen}
        onClose={() => setIsConfluenceExportOpen(false)}
        sprintId={sprint.sprintId}
        competences={teamCompetencesAverages.competences}
        chartData={[teamCompetencesAverages.teamAverages]}
        projectId={projectId}
      />
      <RadarChart
        id="export-chart"
        labels={teamCompetencesAverages.competences}
        chartData={dataToChartDataset([teamCompetencesAverages.teamAverages])}
        width={100}
        height={70}
        className="to-base64"
      />
    </>
  );
};

RetroReviewContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default RetroReviewContainer;
