import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setUserPersonalMoodInit = () => {
  return {
    type: "GET_USER_PERSONAL_MOOD_INIT",
  };
};

const setFetchedUserPersonalMoodLoading = () => {
  return {
    type: "GET_USER_PERSONAL_MOOD_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedUserPersonalMood = (response) => {
  return {
    type: "GET_USER_PERSONAL_MOOD",
    payload: response,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedUserPersonalMoodError = () => {
  return {
    type: "GET_USER_PERSONAL_MOOD_ERROR",
    status: STATUSES.ERROR,
  };
};

const fetchedDataToForm = ({ data }) => {
  if (data) {
    return {
      personalMoodId: data.personalMoodId,
      personalMoodScore: data.personalMoodScore.toString(),
    };
  }
  return { personalMoodScore: null };
};

export const getUserPersonalMood = (sprintId) => {
  return (dispatch) => {
    dispatch(setFetchedUserPersonalMoodLoading());
    _fetch(`api/Sprints/${sprintId}/PersonalMoods`)
      .then((response) => {
        if (response.success) {
          const formData = fetchedDataToForm(response);
          dispatch(setFetchedUserPersonalMood(formData));
        } else {
          dispatch(setFetchedUserPersonalMoodError());
        }
      })
      .catch(() => dispatch(setFetchedUserPersonalMoodError()));
  };
};

export const resetUserPersonalMood = () => {
  return (dispatch) => {
    dispatch(setUserPersonalMoodInit());
  };
};
