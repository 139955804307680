import React from "react";
import PropTypes from "prop-types";
import "./StatementContainer.scss";

const StatementContainer = ({ children }) => {
  return <div className="statement-container">{children}</div>;
};

StatementContainer.propTypes = {
  children: PropTypes.node,
};

export default StatementContainer;
