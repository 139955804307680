import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./NotificationBadge.scss";

const NotificationBadge = ({ borderColor = "#f6f7fb", size = "medium" }) => (
  <div
    style={{ borderColor: borderColor }}
    className={cx("notification-badge", {
      "notification-badge-medium": size === "medium",
      "notification-badge-large": size === "large",
    })}
  />
);

NotificationBadge.propTypes = {
  borderColor: PropTypes.string,
  size: PropTypes.oneOf(["medium", "large"]),
};

export default NotificationBadge;
