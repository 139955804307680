import React, { useEffect } from "react";
import * as msTeams from "@microsoft/teams-js";
import { useSelector, useDispatch } from "react-redux";
import { setPageTitle } from "../../actions/setPageTitle";
import { ROUTES } from "../../constants/routes";
import CreateFeedbackForm from "../../components/CreateFeedbackForm";
import Spinner from "../../components/Spinner";
import { getActiveUsers, resetActiveUsers } from "../../actions/getActiveUsers";
import { hasErrors, areInitiallyLoading } from "../../utils/pageStates";

const CreateFeedback = () => {
  const dispatch = useDispatch();

  const activeUsers = useSelector(({ activeUsers }) => activeUsers);

  const fetches = [activeUsers];

  msTeams.initialize();

  useEffect(() => {
    msTeams.getContext(({ channelId }) => {
      dispatch(getActiveUsers(channelId));
    });

    return () => dispatch(resetActiveUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      setPageTitle({
        text: "New feedback",
        backPath: ROUTES.FEEDBACKS.path,
      })
    );
  }, [dispatch]);

  if (hasErrors(fetches)) {
    return <h2>Something went wrong :(</h2>;
  }

  if (areInitiallyLoading(fetches)) {
    return <Spinner />;
  }

  return <CreateFeedbackForm />;
};

export default CreateFeedback;
