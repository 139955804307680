import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useDispatch } from "react-redux";
import FeedbackPanelInfo from "./FeedbackPanelInfo";
import Tag from "../../Tag";
import IconButton from "../../IconButton";
import BlurredCollapse from "../../BlurredCollapse";
import { ReactComponent as HeartIcon } from "../../../assets/icons/heart.svg";
import _fetch from "../../../utils/fetch";
import { getReceivedFeedbacks } from "../../../actions/getReceivedFeedbacks";
import "./FeedbackPanel.scss";

const FeedbackPanel = (props) => {
  const {
    fromName,
    toName,
    date,
    feedback,
    feedbackId,
    isMarked,
    isViewed,
  } = props;

  const dispatch = useDispatch();

  const [isHeartLoading, setIsHeartLoading] = useState(false);

  const handleMarkClick = (e) => {
    e.stopPropagation(); // prevents parent on click event

    setIsHeartLoading(true);

    _fetch(`api/Feedbacks/${feedbackId}/Mark`, { method: "PATCH" })
      .then(() => dispatch(getReceivedFeedbacks()))
      .then(() => setIsHeartLoading(false));
  };

  return (
    <BlurredCollapse className="panel feedback-panel">
      <FeedbackPanelInfo
        fromName={fromName}
        toName={toName}
        isViewed={isViewed}
        date={date}
      />
      <div className="feedback-panel-feedback-container">
        <span className="feedback-panel-feedback">{feedback}</span>
      </div>
      <div className="feedback-panel-right">
        <Tag color="grey">Feedback</Tag>
        <div className="feedback-panel-right-actions">
          {!toName && (
            <IconButton
              icon={<HeartIcon />}
              size="small"
              onClick={handleMarkClick}
              isLoading={isHeartLoading}
              className={cx("feedback-panel-right-actions-heart-button", {
                "feedback-panel-right-actions-heart-button-marked": isMarked,
              })}
            />
          )}
          {!!toName && isMarked && (
            <HeartIcon className="feedback-panel-right-actions-heart" />
          )}
        </div>
      </div>
    </BlurredCollapse>
  );
};

FeedbackPanel.propTypes = {
  fromName: PropTypes.string,
  toName: PropTypes.string,
  date: PropTypes.string.isRequired,
  feedback: PropTypes.string.isRequired,
  feedbackId: PropTypes.number.isRequired,
  isMarked: PropTypes.bool,
  isViewed: PropTypes.bool,
};

export default FeedbackPanel;
