import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setProjectSprintsInit = () => {
  return {
    type: "GET_PROJECT_SPRINTS_INIT",
  };
};

const setFetchedProjectSprintsLoading = () => {
  return {
    type: "GET_PROJECT_SPRINTS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedProjectSprints = (response) => {
  return {
    type: "GET_PROJECT_SPRINTS",
    payload: response.data,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedProjectSprintsError = () => {
  return {
    type: "GET_PROJECT_SPRINTS_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getProjectSprints = (projectId, sprintStatus = null) => {
  const query = sprintStatus >= 0 ? `?sprintStatus=${sprintStatus}` : null;
  return (dispatch) => {
    dispatch(setFetchedProjectSprintsLoading());
    _fetch(`api/Projects/${projectId}/Sprints${query}`)
      .then((response) => {
        if (response.success) {
          dispatch(setFetchedProjectSprints(response));
        } else {
          dispatch(setFetchedProjectSprintsError());
        }
      })
      .catch(() => dispatch(setFetchedProjectSprintsError()));
  };
};

export const resetProjectSprints = () => {
  return (dispatch) => {
    dispatch(setProjectSprintsInit());
  };
};
