import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import NotificationBadge from "../NotificationBadge";
import "./Badge.scss";

const Badge = ({
  unactive,
  hasNotification = false,
  notificationBorderColor = "#f6f7fb",
  children,
}) => (
  <div
    className={cx("badge", {
      "badge-unactive": unactive,
      "badge-with-notification": hasNotification,
      "badge-smaller-text": children.toString().length > 2,
    })}
  >
    {hasNotification && (
      <NotificationBadge borderColor={notificationBorderColor} />
    )}
    {children}
  </div>
);

Badge.propTypes = {
  unactive: PropTypes.bool,
  hasNotification: PropTypes.bool,
  children: PropTypes.number.isRequired,
  notificationBorderColor: PropTypes.string,
};

export default Badge;
