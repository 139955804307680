import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminEditSprintForm from "../../components/AdminSprintInfo/AdminEditSprintForm";
import { getUserProject, resetUserProject } from "../../actions/getUserProject";
import { setPageTitle } from "../../actions/setPageTitle";
import Spinner from "../../components/Spinner";
import { ROUTES } from "../../constants/routes";
import { hasErrors, areInitiallyLoading } from "../../utils/pageStates";

const AdminEditSprint = (props) => {
  const dispatch = useDispatch();

  const userProject = useSelector(({ userProject }) => userProject);

  const projectId = props.match.params.projectId;

  const fetches = [userProject];

  useEffect(() => {
    dispatch(
      setPageTitle({
        text: "Edit sprint",
        backPath: ROUTES.RETRO_HOME.path,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserProject(projectId));

    return () => dispatch(resetUserProject());
  }, [dispatch, projectId]);

  if (areInitiallyLoading(fetches)) {
    return <Spinner />;
  }

  if (hasErrors(fetches)) {
    return <h2>Something went wrong</h2>;
  }

  if (userProject.payload.sprint.sprintStatus === 0) {
    return <AdminEditSprintForm projectId={projectId} />;
  }

  return <h2>This sprint can't be edited yet</h2>;
};

export default AdminEditSprint;
