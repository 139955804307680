import React from "react";
import Routes from "../Routes";
import "./App.scss";

const App = () => (
  <div className="app">
    <Routes />
  </div>
);

export default App;
