import React from "react";
import PropTypes from "prop-types";
import "./EmptyLayout.scss";

const EmptyLayout = ({ children }) => {
  return <div className="empty-layout-content">{children}</div>;
};

EmptyLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
};

export default EmptyLayout;
