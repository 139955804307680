import React from "react";
import PropTypes from "prop-types";
import FeedbackPanelInfo from "../../FeedbackPanel/FeedbackPanelInfo";
import FeedbackRequestPanelQuestions from "../../FeedbackRequestPanelQuestions";
import Tag from "../../../Tag";
import BlurredCollapse from "../../../BlurredCollapse";
import "./GivenFeedbackRequestPanel.scss";

const GivenFeedbackRequestPanel = ({ toName, date, questions }) => (
  <BlurredCollapse className="panel given-feedback-request-panel ">
    <FeedbackPanelInfo toName={toName} date={date} />
    <FeedbackRequestPanelQuestions questions={questions} />
    <Tag className="given-feedback-request-panel-tag" color="yellow">
      Request
    </Tag>
  </BlurredCollapse>
);

GivenFeedbackRequestPanel.propTypes = {
  toName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
};

export default GivenFeedbackRequestPanel;
