import { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

const FormikLocalStorageSaver = ({ storageName }) => {
  const form = useFormikContext();

  useEffect(() => {
    const setFormValues = form.setValues;
    const storageData = JSON.parse(localStorage.getItem(storageName));

    if (storageData && storageData !== form.initialValues) {
      setFormValues(storageData);
    }
  }, [storageName, form.initialValues, form.setValues]);

  useEffect(() => {
    form.getFieldProps().onChange = localStorage.setItem(
      storageName,
      JSON.stringify(form.values)
    );
  }, [form, storageName]);

  return null;
};

FormikLocalStorageSaver.propTypes = {
  storageName: PropTypes.string.isRequired,
};

export default FormikLocalStorageSaver;
