import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../Tooltip";
import "./InfoBadge.scss";

const InfoBadge = ({ content }) => (
  <Tooltip content={content}>
    <div className="info-badge">?</div>
  </Tooltip>
);

InfoBadge.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default InfoBadge;
