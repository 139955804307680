import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from "react-router-dom";
import { Popover, Menu, Position } from "@blueprintjs/core";

const Dropdown = ({ children, menuItems }) => {
  const menu = (
    <Menu>
      {menuItems.map((item, index) => (
        <li key={`dropdown_item_${index}`}>
          {item.to && !item.disabled ? (
            <Link
              to={item.to}
              className={cx("bp3-menu-item", { "bp3-disabled": item.disabled })}
            >
              {item.text}
            </Link>
          ) : (
            <button
              onClick={!item.disabled ? item.onClick : () => {}}
              type="button"
              className={cx("bp3-menu-item", { "bp3-disabled": item.disabled })}
            >
              {item.text}
            </button>
          )}
        </li>
      ))}
    </Menu>
  );

  return (
    <Popover content={menu} position={Position.BOTTOM_LEFT}>
      {children}
    </Popover>
  );
};

Dropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  menuItems: PropTypes.array.isRequired,
};

export default Dropdown;
