import React from "react";
import PropTypes from "prop-types";
import NotificationDot from "../../../NotificationDot";
import formatDate from "../../../../utils/formatDate";
import "./FeedbackPanelInfo.scss";

const FeedbackPanelInfo = ({ fromName, toName, isViewed = true, date }) => {
  const nameWithNotification = (name) => (
    <span className="feedback-panel-info-name">
      {!isViewed && <NotificationDot />}
      {name}
    </span>
  );

  let names;

  if (fromName && toName) {
    names = (
      <div className="feedback-panel-info-names">
        <span className="feedback-panel-info-names-label">From:</span>
        {nameWithNotification(fromName)}
        <span className="feedback-panel-info-names-label">To:</span>
        <span className="feedback-panel-info-names-name">{toName}</span>
      </div>
    );
  } else {
    const name = fromName ? fromName : toName;
    names = nameWithNotification(name);
  }

  return (
    <div className="feedback-panel-info">
      {names}
      <span className="feedback-panel-info-date">{formatDate(date)}</span>
    </div>
  );
};

FeedbackPanelInfo.propTypes = {
  fromName: PropTypes.string,
  toName: PropTypes.string,
  isViewed: PropTypes.bool,
  date: PropTypes.string,
};

export default FeedbackPanelInfo;
