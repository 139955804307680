import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./TextButton.scss";

const TextButton = ({
  children,
  type = "button",
  className = null,
  ...props
}) => (
  <button className={cx("text-button", className)} type={type} {...props}>
    {children}
  </button>
);

TextButton.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default TextButton;
