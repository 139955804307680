import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import { object, array, string } from "yup";
import { Intent } from "@blueprintjs/core";
import Button from "../../../../Button";
import Toaster from "../../../../Toaster";
import FeedbackRequestAnswerFormInput from "./FeedbackRequestAnswerFormInput";
import _fetch from "../../../../../utils/fetch";
import { getReceivedFeedbackRequests } from "../../../../../actions/getReceivedFeedbackRequests";
import { getReceivedFeedbackAnswers } from "../../../../../actions/getReceivedFeedbackAnswers";
import { getGivenFeedbackAnswers } from "../../../../../actions/getGivenFeedbackAnswers";
import "./FeedbackRequestAnswerForm.scss";

const validationSchema = object().shape({
  answers: array().of(
    object({
      text: string().min(1).required(),
      evaluation: string().required(),
    })
  ),
});

const FeedbackRequestAnswerForm = ({ requestId, questions, onCancel }) => {
  const dispatch = useDispatch();

  const setInitialValues = () => ({
    id: requestId,
    answers: questions.map((question) => ({
      text: "",
      evaluation: question.isEvaluationRequired ? "" : "none",
      feedbackRequestDataId: question.feedbackRequestDataId,
    })),
  });

  const handleSubmit = (values) => {
    const formData = values.answers.map((answer) => ({
      feedbackRequestDataId: answer.feedbackRequestDataId,
      answer: answer.text,
      evaluation:
        answer.evaluation === "none" ? null : parseInt(answer.evaluation, 10),
    }));

    _fetch(`api/FeedbackRequest/${requestId}`, {
      method: "PUT",
      data: formData,
    })
      .then((response) => {
        const message = {
          message: "Answer sent!",
          intent: Intent.SUCCESS,
        };

        if (response.success) {
          dispatch(getReceivedFeedbackRequests());
          dispatch(getReceivedFeedbackAnswers());
          dispatch(getGivenFeedbackAnswers());
        } else {
          message.message = "Something went wrong";
          message.intent = Intent.DANGER;
        }

        Toaster.show(message);
      })
      .catch(() => {
        Toaster.show({
          message: "Something went wrong",
          intent: Intent.DANGER,
        });
      });
  };

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={setInitialValues()}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, isSubmitting }) => (
        <Form className="feedback-request-answer-form">
          <FieldArray
            name="answers"
            render={() =>
              questions.map((question, index) => (
                <FeedbackRequestAnswerFormInput
                  name={`answers.${index}`}
                  key={`feedback_request_answer_input_${index}`}
                  question={question.question}
                  errors={errors.answers?.[index]}
                  isEvaluationRequired={question.isEvaluationRequired}
                />
              ))
            }
          />
          <div className="feedback-request-answer-form-buttons">
            <Button
              size="small"
              type="submit"
              disabled={isSubmitting}
              className="full-width-on-mobile"
            >
              Submit
            </Button>
            <Button
              size="small"
              className="full-width-on-mobile"
              secondary
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

FeedbackRequestAnswerForm.propTypes = {
  requestId: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  onCancel: PropTypes.func,
};

export default FeedbackRequestAnswerForm;
