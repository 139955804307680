import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import FeedbackPanel from "../FeedbackPanel";
import _fetch from "../../../utils/fetch";
import { getNotifications } from "../../../actions/getNotifications";
import EmptyListBadge from "../../EmptyListBadge";

const CCFeedbacks = ({ hasNotification }) => {
  const dispatch = useDispatch();

  const ccFeedbacks = useSelector(({ ccFeedbacks }) => ccFeedbacks.payload);

  useEffect(() => {
    if (hasNotification) {
      const formData = { unreadMentionedInCcFeedback: true };
      _fetch("api/notifications", { method: "PUT", data: formData }).then(() =>
        dispatch(getNotifications())
      );
    }
  }, [dispatch, hasNotification]);

  if (ccFeedbacks.length > 0) {
    return (
      <>
        {ccFeedbacks.map((feedback, index) => (
          <FeedbackPanel
            key={`received_feedback_panel_${index}`}
            toName={feedback.toFullName}
            fromName={feedback.fromFullName}
            date={feedback.feedbackDate}
            feedback={feedback.feedback}
            feedbackId={feedback.feedbackId}
            isViewed={feedback.viewed}
          />
        ))}
      </>
    );
  }

  return <EmptyListBadge />;
};

CCFeedbacks.propTypes = {
  hasNotification: PropTypes.bool,
};

export default CCFeedbacks;
