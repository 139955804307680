import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setSprintParticipantCountInit = () => {
  return {
    type: "GET_SPRINT_PARTICIPANT_COUNT_INIT",
  };
};

const setFetchedSprintParticipantCountLoading = () => {
  return {
    type: "GET_SPRINT_PARTICIPANT_COUNT_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedSprintParticipantCount = (response) => {
  return {
    type: "GET_SPRINT_PARTICIPANT_COUNT",
    payload: response,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedSprintParticipantCountError = () => {
  return {
    type: "GET_SPRINT_USERS_PARTICIPANT_COUNT",
    status: STATUSES.ERROR,
  };
};

export const getSprintParticipantCount = (sprintId) => {
  return (dispatch) => {
    dispatch(setFetchedSprintParticipantCountLoading());
    _fetch(`api/Sprints/${sprintId}/SprintParticipantCount`)
      .then((res) => {
        if (res.success) {
          dispatch(setFetchedSprintParticipantCount(res.data));
        } else {
          dispatch(setFetchedSprintParticipantCountError());
        }
      })
      .catch(() => {
        dispatch(setFetchedSprintParticipantCountError());
      });
  };
};

export const resetSprintParticipantCount = () => {
  return (dispatch) => {
    dispatch(setSprintParticipantCountInit());
  };
};
