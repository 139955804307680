import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import "./Select.scss";

const Select = ({ options, name, placeholder }) => (
  <Field name={name} as="select" className="select">
    <option value="">{placeholder}</option>
    {options.map((item, index) => (
      <option
        value={item.value}
        disabled={item.disabled}
        key={`${item.name}_${index}`}
      >
        {item.title}
      </option>
    ))}
  </Field>
);

Select.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default Select;
