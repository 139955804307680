import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import IconButton from "../IconButton";
import { ReactComponent as CollapseIcon } from "../../assets/icons/expand.svg";
import "./Collapse.scss";

const Collapse = (props) => {
  const {
    title,
    children,
    isOpen,
    setIsOpen,
    hideCollapseButton = false,
    className = null,
  } = props;

  return (
    <div className={cx("collapse", className)}>
      <div className="collapse-header">
        <div className="collapse-header-title">{title}</div>
        <div className="collapse-header-icon">
          {!hideCollapseButton && (
            <IconButton
              icon={<CollapseIcon />}
              secondary
              size="small"
              onClick={() => setIsOpen(!isOpen)}
              className={cx({
                "collapse-header-icon-checked": isOpen,
              })}
            />
          )}
        </div>
      </div>
      {isOpen && children}
    </div>
  );
};

Collapse.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  hideCollapseButton: PropTypes.bool,
  className: PropTypes.string,
};

export default Collapse;
