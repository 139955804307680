import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../actions/setPageTitle";
import { ROUTES } from "../../constants/routes";
import StatementContainer from "../../components/StatementContainer";
import StatementSection from "../../components/StatementContainer/StatementSection";
import StatementSectionContent from "../../components/StatementContainer/StatementSection/StatementSectionContent";

const ContactUs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setPageTitle({
        text: "Contact us",
        backPath: ROUTES.HOME.path,
      })
    );
  }, [dispatch]);

  return (
    <StatementContainer>
      <StatementSection>
        <StatementSectionContent>
          If you have any questions or suggestions that we can help you with,{" "}
          <a href="mailto:retro@ba.lt">
            <u>just let us know</u>
          </a>
          .
        </StatementSectionContent>
      </StatementSection>
    </StatementContainer>
  );
};

export default ContactUs;
