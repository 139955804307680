import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { object, string, array, date, number } from "yup"; // for only what you need
import * as msTeams from "@microsoft/teams-js";
import { Intent } from "@blueprintjs/core";
import _fetch from "../../../utils/fetch";
import Toaster from "../../Toaster";
import GreyContainer from "../../GreyContainer";
import PanelFormInput from "../../PanelFormInput/PanelFormInput";
import TextInput from "../../TextInput";
import Tagger from "../../Tagger";
import DateInput from "../../DateInput";
import RadioButton from "../../RadioButton";
import NumberInput from "../../NumberInput";
import ProjectCompetencesSelect from "./ProjectCompetencesSelect";
import Button from "../../Button";
import TextButton from "../../TextButton";
import EditProjectCompetencesModal from "./EditProjectCompetencesModal";
import { ROUTES } from "../../../constants/routes";
import formatDate from "../../../utils/formatDate";
import { usersToTagEntity } from "../../../utils/usersToTagEntity";
import "./AdminStartSprintForm.scss";

const validationSchema = object({
  sprintName: string().min(1).required(),
  sprintParticipants: array().min(1).required(),
  sprintStartDate: date().required(),
  sprintEndDate: date().required(),
  retroStartDate: date().required(),
  allowIncognito: string()
    .matches(/(true|false)/)
    .required(),
  mandatoryEvaluationCount: number().required(),
  competences: array()
    .min(5)
    .max(5)
    .of(object({ value: number().required() }))
    .required(),
});

const AdminStartSprintForm = ({ projectId }) => {
  const [
    shouldRedirectToProjectsList,
    setShouldRedirectToProjectsList,
  ] = useState(false);
  const [isEditCompetencesOpen, setIsEditCompetencesOpen] = useState(false);

  const projectUsers = useSelector(({ projectUsers }) => projectUsers.payload);
  const allProjectCompetences = useSelector(
    ({ allProjectCompetences }) => allProjectCompetences.payload.data
  );
  const latestSprint = useSelector(
    ({ latestSprint }) => latestSprint.payload.sprint
  );
  const projectCompetences = useSelector(
    ({ project }) => project.payload.competences
  );

  const isInIframe = () => {
    try {
      return window.self !== window.top;
    } catch (_e) {
      return true;
    }
  };

  const setInitialValues = () => ({
    sprintName: "",
    sprintParticipants: usersToTagEntity(projectUsers, []),
    sprintStartDate: null,
    sprintEndDate: null,
    retroStartDate: null,
    allowIncognito: latestSprint
      ? latestSprint.allowAnonymous.toString()
      : "true",
    mandatoryEvaluationCount: latestSprint
      ? latestSprint.mandatoryEvaluationCount
      : 0,
    competences:
      projectCompetences && projectCompetences.length > 0
        ? projectCompetences.map((competence) => ({
            label: competence.competenceName,
            value: competence.competenceId,
          }))
        : [null, null, null, null, null],
  });

  const submitSprint = async (values, sprintUrl, setSubmitting) => {
    const formData = {
      sprintUrl: sprintUrl,
      sprintId: 0,
      sprintName: values.sprintName,
      startDate: formatDate(values.sprintStartDate),
      endDate: formatDate(values.sprintEndDate),
      retroStartDate: formatDate(values.retroStartDate),
      userIds: values.sprintParticipants.map((item) => item.id),
      mandatoryEvaluationCount: values.mandatoryEvaluationCount,
      projectCompetences: values.competences.map((item) => ({
        competenceId: item.value,
      })),
      allowAnonymous: values.allowIncognito === "true",
    };

    const { success } = await _fetch(`api/projects/${projectId}/sprints`, {
      method: "POST",
      data: formData,
    });

    const message = { message: "Sprint info sent", intent: Intent.SUCCESS };

    if (success) {
      setShouldRedirectToProjectsList(true);
    } else {
      message.message = "An error has occured";
      message.intent = Intent.DANGER;
      setSubmitting(false);
    }

    Toaster.show(message);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (isInIframe()) {
      msTeams.initialize();
      msTeams.getContext(({ channelId }) => {
        const sprintUrl = `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_CLIENT_ID}/Retro2020?label=Retro-2020&context=%7B%22channelId%22%3A%22${channelId}%22%7D`;
        submitSprint(values, sprintUrl, setSubmitting);
      });
    } else {
      const sprintUrl = `${window.location.origin}${ROUTES.RETRO_PROJECTS.path}/${projectId}`;
      submitSprint(values, sprintUrl, setSubmitting);
    }
  };

  if (shouldRedirectToProjectsList) {
    return <Redirect to={ROUTES.RETRO_HOME.path} />;
  }

  return (
    <>
      <GreyContainer className="admin-start-sprint-container">
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          handleBlur={() => {}}
          initialValues={setInitialValues()}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, setFieldValue, isSubmitting }) => {
            const participantsLength = values.sprintParticipants.length;

            return (
              <Form className="admin-start-sprint-form">
                <div className="panel admin-start-sprint-form-container">
                  <PanelFormInput title="Retro name">
                    <Field
                      component={TextInput}
                      name="sprintName"
                      value={values.sprintName}
                      placeholder="Insert retro name..."
                      error={!!errors.sprintName}
                    />
                  </PanelFormInput>
                  <PanelFormInput title="Participants" tall>
                    <Field
                      component={Tagger}
                      placeholder="Insert participant name..."
                      tags={values.sprintParticipants}
                      suggestions={usersToTagEntity(
                        projectUsers,
                        values.sprintParticipants
                      )}
                      setFieldValue={setFieldValue}
                      fieldValueName="sprintParticipants"
                      error={!!errors.sprintParticipants}
                      className="react-tags-root-admin-start-sprint-form "
                    />
                  </PanelFormInput>
                  <PanelFormInput title="Sprint period" horizontal wrapWidth>
                    <Field
                      text="From"
                      maxDate={values.sprintEndDate}
                      component={DateInput}
                      name="sprintStartDate"
                      setFieldValue={setFieldValue}
                      error={!!errors.sprintStartDate}
                    />
                    <Field
                      text="To"
                      minDate={values.sprintStartDate}
                      component={DateInput}
                      name="sprintEndDate"
                      setFieldValue={setFieldValue}
                      error={!!errors.sprintEndDate}
                    />
                  </PanelFormInput>
                  <PanelFormInput title="Retro start date" wrapWidth>
                    <Field
                      text="From"
                      component={DateInput}
                      name="retroStartDate"
                      setFieldValue={setFieldValue}
                      error={!!errors.retroStartDate}
                    />
                  </PanelFormInput>
                  <PanelFormInput
                    title="Incognito evaluation"
                    horizontal
                    wrapWidth
                  >
                    <Field
                      component={RadioButton}
                      name="allowIncognito"
                      value="true"
                      checked={values.allowIncognito === "true"}
                      text="Allow"
                    />
                    <Field
                      component={RadioButton}
                      name="allowIncognito"
                      value="false"
                      checked={values.allowIncognito === "false"}
                      text="Don't allow"
                    />
                  </PanelFormInput>
                  <PanelFormInput title="Mandatory evaluations" wrapWidth>
                    <Field
                      component={NumberInput}
                      name="mandatoryEvaluationCount"
                      value={values.mandatoryEvaluationCount}
                      minValue={participantsLength >= 2 ? 1 : 0}
                      maxValue={
                        participantsLength >= 1 ? participantsLength - 1 : 0 // -1 because excluding self
                      }
                      setFieldValue={setFieldValue}
                    />
                  </PanelFormInput>
                  <PanelFormInput
                    title="Competences to evaluate"
                    titleAction={
                      <TextButton
                        onClick={() => setIsEditCompetencesOpen(true)}
                        className="admin-start-sprint-edit-competences-button"
                      >
                        Edit project competences
                      </TextButton>
                    }
                    competences
                    tall
                  >
                    <ProjectCompetencesSelect
                      allProjectCompetences={allProjectCompetences}
                      setFieldValue={setFieldValue}
                    />
                  </PanelFormInput>
                </div>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </Form>
            );
          }}
        </Formik>
      </GreyContainer>
      <EditProjectCompetencesModal
        isOpen={isEditCompetencesOpen}
        onClose={() => setIsEditCompetencesOpen(false)}
        projectId={projectId}
      />
    </>
  );
};

AdminStartSprintForm.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default AdminStartSprintForm;
