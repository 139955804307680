import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getNotifications,
  resetNotifications,
} from "../../actions/getNotifications";
import FrontPageContainer from "../../components/FrontPageContainer";

const FrontPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotifications());

    return () => dispatch(resetNotifications());
  });

  return <FrontPageContainer />;
};

export default FrontPage;
