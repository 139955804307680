import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setFetchedReceivedFeedbackRequestsInit = () => {
  return {
    type: "GET_RECEIVED_FEEDBACK_REQUESTS_INIT",
  };
};

const setFetchedReceivedFeedbackRequestsLoading = () => {
  return {
    type: "GET_RECEIVED_FEEDBACK_REQUESTS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedReceivedFeedbackRequests = (response) => {
  return {
    type: "GET_RECEIVED_FEEDBACK_REQUESTS",
    payload: response.data,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedReceivedFeedbackRequestsError = () => {
  return {
    type: "GET_RECEIVED_FEEDBACK_REQUESTS_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getReceivedFeedbackRequests = () => {
  return (dispatch) => {
    dispatch(setFetchedReceivedFeedbackRequestsLoading());
    _fetch("api/FeedbackRequests?answered=false&given=false")
      .then(async (response) => {
        if (response.success) {
          await dispatch(setFetchedReceivedFeedbackRequests(response));
        } else {
          dispatch(setFetchedReceivedFeedbackRequestsError());
        }
      })
      .catch(() => dispatch(setFetchedReceivedFeedbackRequestsError()));
  };
};

export const resetReceivedFeedbackRequests = () => {
  return (dispatch) => {
    dispatch(setFetchedReceivedFeedbackRequestsInit());
  };
};
