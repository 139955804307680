import { STATUSES } from "../constants/statuses";

export const areInitiallyLoading = (data) =>
  !!data.find(
    ({ status, payload }) =>
      [STATUSES.INIT, STATUSES.LOADING].includes(status) && !payload
  );

export const hasErrors = (data) =>
  !!data.find(({ status }) => status === STATUSES.ERROR);
