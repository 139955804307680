import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./FixedActionHeader.scss";

const FixedActionHeader = ({ children, className }) => (
  <div className={cx("fixed-action-header", className)}>{children}</div>
);

FixedActionHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default FixedActionHeader;
