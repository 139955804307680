import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setTeamCompetencesAveragesInit = () => {
  return {
    type: "GET_TEAM_COMPETENCES_AVERAGE_INIT",
  };
};

const setFetchedTeamCompetencesAveragesLoading = () => {
  return {
    type: "GET_TEAM_COMPETENCES_AVERAGE_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedTeamCompetencesAverages = (response) => {
  return {
    type: "GET_TEAM_COMPETENCES_AVERAGE",
    payload: response,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedTeamCompetencesAveragesError = () => {
  return {
    type: "GET_TEAM_COMPETENCES_AVERAGE_ERROR",
    status: STATUSES.ERROR,
  };
};

const teamCompetencesToChartData = (teamCompetencesAverages) => {
  if (teamCompetencesAverages) {
    return teamCompetencesAverages.reduce(
      (results, item) => {
        results.teamAverages.data.push(item.averageScore);
        results.competences.push(item.competenceName);
        return results;
      },
      {
        teamAverages: {
          legendLabel: "Team evaluation",
          data: [],
          filterable: false,
        },
        competences: [],
      }
    );
  }
  return null;
};

export const getTeamCompetencesAverages = (sprintId) => {
  return (dispatch) => {
    dispatch(setFetchedTeamCompetencesAveragesLoading());
    _fetch(`api/sprints/${sprintId}/team-competences-averages`)
      .then((res) => {
        if (res.success) {
          const avgData = teamCompetencesToChartData(res.data);
          dispatch(setFetchedTeamCompetencesAverages(avgData));
        } else {
          dispatch(setFetchedTeamCompetencesAveragesError());
        }
      })
      .catch(() => {
        dispatch(setFetchedTeamCompetencesAveragesError());
      });
  };
};

export const resetTeamCompetencesAverages = () => {
  return (dispatch) => {
    dispatch(setTeamCompetencesAveragesInit());
  };
};
