import React from "react";
import PropTypes from "prop-types";
import RadarChart from "../../RadarChart";
import { dataToChartDataset } from "../../../utils/dataToChartDataset";
import "./TeamAverageCompetences.scss";

const TeamAverageCompetences = ({ competences, chartData }) => {
  return (
    <div className="panel team-average-competences">
      <RadarChart
        id="complete-sprint-chart"
        width={400}
        height={200}
        labels={competences}
        chartData={dataToChartDataset(chartData)}
      />
    </div>
  );
};

TeamAverageCompetences.propTypes = {
  competences: PropTypes.array.isRequired,
  chartData: PropTypes.array.isRequired,
};

export default TeamAverageCompetences;
