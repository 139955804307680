import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logos/logo-transparent.svg";
import { ReactComponent as FeedbackIcon } from "../../assets/icons/chat.svg";
import { ReactComponent as RetroIcon } from "../../assets/icons/scale.svg";
import { ROUTES } from "../../constants/routes";
import NotificationBadge from "../NotificationBadge";
import "./FrontPageContainer.scss";

const FrontPageContainer = () => {
  const unreadNotifications = useSelector(
    ({ notifications }) => notifications.payload.data.unreadTotal
  );

  return (
    <div className="front-page">
      <div className="front-page-welcome">
        <Logo />
        <h1>Welcome!</h1>
      </div>
      <div className="front-page-actions">
        <Link
          to={ROUTES.FEEDBACKS.path}
          className="panel front-page-actions-panel"
        >
          {unreadNotifications > 0 && (
            <NotificationBadge borderColor="#f6f7fb" size="large" />
          )}
          <FeedbackIcon className="front-page-actions-panel-feedback-icon" />
          <div className="front-page-actions-panel-text">
            <h1>Feedback</h1>
            <span>Give feedback to your colleagues</span>
          </div>
        </Link>
        <Link
          to={ROUTES.RETRO_HOME.path}
          className="panel front-page-actions-panel"
        >
          <RetroIcon className="front-page-actions-panel-retro-icon" />
          <div className="front-page-actions-panel-text">
            <h1>Retro</h1>
            <span>Fill out the retrospective form</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default FrontPageContainer;
