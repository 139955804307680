import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as msTeams from "@microsoft/teams-js";
import _fetch from "../../utils/fetch";
import Spinner from "../../components/Spinner";
import SelectProjectForm from "../../components/SelectProjectForm";
import {
  getUserProjects,
  resetUserProjects,
} from "../../actions/getUserProjects";
import { STATUSES } from "../../constants/statuses";

const SelectProject = () => {
  msTeams.initialize();

  const dispatch = useDispatch();

  const [channelUsers, setChannelUsers] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const userProjects = useSelector((state) => state.userProjects);

  useEffect(() => {
    msTeams.getContext(({ channelId }) => {
      dispatch(getUserProjects(channelId));
    });

    return () => dispatch(resetUserProjects());
  }, [dispatch]);

  useEffect(() => {
    msTeams.getContext(({ channelId }) => {
      _fetch(`api/Teams/${channelId}/Users`)
        .then((response) => {
          setChannelUsers(response.data);
          setLoadingData(false);
        })
        .catch(() => setChannelUsers([]));
    });
    return () => setLoadingData(true);
  }, []);

  if (
    loadingData ||
    userProjects.status === STATUSES.INIT ||
    userProjects.status === STATUSES.LOADING
  ) {
    return <Spinner />;
  }

  if (channelUsers.length > 0 && userProjects.status === STATUSES.SUCCESS) {
    return <SelectProjectForm channelUsers={channelUsers} />;
  }

  return <h1>Something wrong</h1>;
};
export default SelectProject;
