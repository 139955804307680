import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Field } from "formik";
import "./Switch.scss";

const Switch = (props) => {
  const {
    name,
    icon,
    large,
    label,
    disabled = false,
    className = null,
  } = props;

  return (
    <label
      className={cx(
        "bp3-control",
        "bp3-switch",
        "bp3-align-right",
        "switch",
        className,
        {
          "bp3-large": large,
        }
      )}
    >
      <Field name={name} type="checkbox" disabled={disabled} />
      <span className="bp3-control-indicator" />
      {icon} {label}
    </label>
  );
};

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.node,
  large: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default Switch;
