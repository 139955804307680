import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { FieldArray, Field } from "formik";
import { VALUES } from "../../../../constants/values";
import TextInput from "../../../TextInput";
import IconButton from "../../../IconButton";
import { ReactComponent as AddIcon } from "../../../../assets/icons/add.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import "./RetroCommentsPanel.scss";

const RetroCommentsPanel = ({ name, title }) => {
  const [autoFocus, setAutoFocus] = useState(false);

  return (
    <div className="panel retro-comments-panel">
      <span>{title}</span>
      <FieldArray
        name={`${name}`}
        render={(arrayHelpers) => {
          const { values, errors } = arrayHelpers.form;

          return (
            <div className="retro-comments-panel-actions">
              <div className="retro-comments-panel-actions-inputs">
                {values[name].map(({ comment }, index) => {
                  const error = errors[name] ? errors[name][index] : null;

                  return (
                    <div key={`retro_comment_${name}_${index}`}>
                      <span>{index + 1}</span>
                      <Field
                        component={TextInput}
                        name={`${arrayHelpers.name}.${index}.comment`}
                        value={comment}
                        maxLength={VALUES.MAX_USER_COMMENT_LENGTH}
                        autoFocus={autoFocus}
                        error={!!error}
                        textarea
                        autoGrowHeight
                      />
                      <IconButton
                        className={cx({ hidden: index === 0 })}
                        icon={<DeleteIcon />}
                        secondary
                        size="xsmall"
                        onClick={() => {
                          arrayHelpers.remove(index);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              {values[name].length < 7 && (
                <IconButton
                  icon={<AddIcon />}
                  text="Add a comment"
                  className="full-width-on-mobile"
                  onClick={() => {
                    arrayHelpers.push({ comment: "" });
                    setAutoFocus(true);
                  }}
                />
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

RetroCommentsPanel.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default RetroCommentsPanel;
