import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setProjectInit = () => {
  return {
    type: "GET_PROJECT_NIT",
  };
};

const setFetchedProjectLoading = () => {
  return {
    type: "GET_PROJECT_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedProject = (response) => {
  return {
    type: "GET_PROJECT",
    payload: response.data,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedProjectError = () => {
  return {
    type: "GET_PROJECT_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getProject = (projectId) => {
  return (dispatch) => {
    dispatch(setFetchedProjectLoading());
    _fetch(`api/Projects/${projectId}`)
      .then((response) => {
        if (response.success) {
          dispatch(setFetchedProject(response));
        } else {
          dispatch(setFetchedProjectError());
        }
      })
      .catch(() => dispatch(setFetchedProjectError()));
  };
};

export const resetProject = () => {
  return (dispatch) => {
    dispatch(setProjectInit());
  };
};
