import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./GreyContainer.scss";

const GreyContainer = (props) => {
  const {
    title,
    children,
    topRightComponent,
    blueTitle = false,
    className = null,
  } = props;

  return (
    <div
      className={cx("grey-container", className, {
        "grey-container-blue-title": blueTitle,
      })}
    >
      {!!title && (
        <div className="grey-container-title-container">
          <h2
            className={cx("grey-container-title", {
              "grey-container-title-blue": blueTitle,
            })}
          >
            {title}
          </h2>

          {!!topRightComponent && (
            <div className="grey-container-top-right-component">
              {topRightComponent}
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

GreyContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
  topRightComponent: PropTypes.object,
  blueTitle: PropTypes.bool,
  className: PropTypes.string,
};

export default GreyContainer;
