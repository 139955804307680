import React from "react";
import PropTypes from "prop-types";
import "./FeedbackRequestPanelQuestions.scss";

const FeedbackRequestPanelQuestions = ({ questions }) => (
  <ul className="feedback-request-panel-questions">
    {questions.map((question, index) => (
      <li
        key={`feedback_request_question_${index}`}
        className="feedback-request-panel-questions-question"
      >
        {question.question}
      </li>
    ))}
  </ul>
);

FeedbackRequestPanelQuestions.propTypes = {
  questions: PropTypes.array.isRequired,
};

export default FeedbackRequestPanelQuestions;
