import React from "react";
import { Route } from "react-router-dom";

export default function RouteWrapper(props) {
  const {
    component: Component,
    layout: Layout,
    title,
    backPath,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props} title={title} backPath={backPath}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
