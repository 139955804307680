export const usersToTagEntity = (users, tags) =>
  users.reduce((results, user) => {
    if (!tags.find((tag) => tag.id === user.userId)) {
      results.push({
        id: user.userId,
        name: user.fullName,
      });
    }
    return results;
  }, []);
