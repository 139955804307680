import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setNotificationsInit = () => {
  return {
    type: "GET_NOTIFICATIONS_INIT",
  };
};

const setFetchedNotificationsLoading = () => {
  return {
    type: "GET_NOTIFICATIONS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedNotifications = (response) => {
  return {
    type: "GET_NOTIFICATIONS",
    payload: response,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedNotificationsError = () => {
  return {
    type: "GET_NOTIFICATIONS_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getNotifications = () => {
  return (dispatch) => {
    dispatch(setFetchedNotificationsLoading());
    _fetch("api/Notifications")
      .then((response) => {
        if (response.success) {
          dispatch(setFetchedNotifications(response));
        } else {
          dispatch(setFetchedNotificationsError());
        }
      })
      .catch(() => dispatch(setFetchedNotificationsError()));
  };
};

export const resetNotifications = () => {
  return (dispatch) => {
    dispatch(setNotificationsInit());
  };
};
