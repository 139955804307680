import React from "react";
import PropTypes from "prop-types";
import RadioButtonScale from "../../../../RadioButtonScale";
import { VALUES } from "../../../../../constants/values";
import "./CompetenceEvaluationPanel.scss";

const NON_EVALUTING = [
  {
    value: "0",
    label: "Can't say",
    color: "blue",
  },
];

const CompetenceEvaluationPanel = (props) => {
  const { arrayHelpers, competenceName, competenceIndex } = props;

  return (
    <div className="competence-evaluation-panel">
      <div className="competence-evaluation-panel-name">{competenceName}</div>
      <div className="competence-evaluation-panel-scale">
        <RadioButtonScale
          name={`${arrayHelpers.name}.${competenceIndex}.competenceValue`}
          inputs={VALUES.SCALE}
        />
      </div>
      <RadioButtonScale
        name={`${arrayHelpers.name}.${competenceIndex}.competenceValue`}
        inputs={NON_EVALUTING}
      />
    </div>
  );
};

CompetenceEvaluationPanel.propTypes = {
  arrayHelpers: PropTypes.object.isRequired,
  competenceName: PropTypes.string.isRequired,
  competenceIndex: PropTypes.number.isRequired,
};

export default CompetenceEvaluationPanel;
