import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Collapse from "../../Collapse";
import Avatar from "../../Avatar";
import formatDate from "../../../utils/formatDate";
import { nameToInitials } from "../../../utils/nameToInitials";
import { getAvatarColor } from "../../../utils/getAvatarColor";
import { setCollapsedRetroReviewComments } from "../../../actions/setCollapsedRetroReviewComments";
import "./RetroReviewCommentPanel.scss";

const RetroReviewCommentPanel = (props) => {
  const { index, text, actionPoints, tabType } = props;

  const dispatch = useDispatch();

  const projectUsersIds = useSelector(({ projectUsers }) =>
    projectUsers.payload.map(({ userId }) => userId)
  );
  const collapsedRetroReviewComments = useSelector(
    ({ collapsedRetroReviewComments }) => collapsedRetroReviewComments
  );

  const setIsOpen = () => {
    const tabCommentsStates = collapsedRetroReviewComments[tabType];

    tabCommentsStates[index] = !tabCommentsStates[index];

    const allCommentsStates = collapsedRetroReviewComments;
    allCommentsStates[tabType] = tabCommentsStates;

    dispatch(setCollapsedRetroReviewComments(allCommentsStates));
  };

  const getTableData = (actionPoints) => {
    const notChosenText = (
      <span className="retro-review-comment-panel-table-action-point-not-chosen">
        Not selected
      </span>
    );

    return actionPoints.map((actionPoint, index) => (
      <tr key={`action_point_${index}`}>
        <td className="retro-review-comment-panel-table-action-point">
          <span className="retro-review-comment-panel-table-action-point-bullet-point">
            •
          </span>
          <span className="retro-review-comment-panel-table-action-point-text">
            {actionPoint.actionPoint}
          </span>
        </td>
        <td className="retro-review-comment-panel-table-action-point">
          {actionPoint.completionDate
            ? formatDate(actionPoint.completionDate)
            : notChosenText}
        </td>
        <td className="retro-review-comment-panel-table-action-point">
          {actionPoint.assignedFullUserName ? (
            <div className="retro-review-comment-panel-table-action-point-name">
              <Avatar
                color={getAvatarColor(
                  projectUsersIds,
                  actionPoint.assignedUserId
                )}
              >
                {nameToInitials(actionPoint.assignedFullUserName)}
              </Avatar>

              <span>{actionPoint.assignedFullUserName}</span>
            </div>
          ) : (
            notChosenText
          )}
        </td>
      </tr>
    ));
  };

  return (
    <li className="panel retro-review-comment-panel">
      <Collapse
        title={
          <span className="retro-review-comment-panel-text">
            <span className="retro-review-comment-panel-text-number">
              {index + 1}.
            </span>
            {text}
          </span>
        }
        isOpen={
          !!collapsedRetroReviewComments[tabType][index] &&
          actionPoints.length > 0
        }
        setIsOpen={setIsOpen}
        hideCollapseButton={!actionPoints.length > 0}
      >
        <div className="retro-review-comment-panel-table-container">
          <table className="retro-review-comment-panel-table">
            <colgroup>
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>Actions we are going to take:</th>
                <th>Deadline:</th>
                <th>Responsible persons:</th>
              </tr>
              {getTableData(actionPoints)}
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </Collapse>
    </li>
  );
};

RetroReviewCommentPanel.propTypes = {
  index: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  actionPoints: PropTypes.array.isRequired,
  tabType: PropTypes.string.isRequired,
};

export default RetroReviewCommentPanel;
