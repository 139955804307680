import React from "react";
import PropTypes from "prop-types";
import "./StatementSectionHeader.scss";

const StatementSectionHeader = ({ children }) => {
  return <div className="statement-section-header">{children}</div>;
};

StatementSectionHeader.propTypes = {
  children: PropTypes.node,
};

export default StatementSectionHeader;
