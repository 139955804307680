import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Field } from "formik";
import "./PersonalMoodInput.scss";

const PersonalMoodInput = ({ value, type, children }) => {
  return (
    <div className="personal-mood-input">
      <label>
        <Field
          type="radio"
          name="personalMood"
          value={value}
          className={cx(
            "personal-mood-radio-button",
            `personal-mood-radio-button-${type}`
          )}
        />
        {children}
      </label>
    </div>
  );
};

PersonalMoodInput.propTypes = {
  value: PropTypes.oneOf(["1", "2", "3", "4", "5"]),
  type: PropTypes.oneOf(["vsad", "sad", "meh", "smile", "happy"]).isRequired,
  children: PropTypes.object.isRequired,
};

export default PersonalMoodInput;
