import React, { Fragment } from "react";
import { Field, FieldArray } from "formik";
import PanelFormInput from "../../PanelFormInput";
import TextInput from "../../TextInput";
import Switch from "../../Switch";
import IconButton from "../../IconButton";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import "./CreateFeedbackRequestFormQuestions.scss";

const CreateFeedbackRequestFormQuestions = () => (
  <FieldArray
    name="questions"
    render={(arrayHelpers) => {
      const { values, errors } = arrayHelpers.form;

      return (
        <>
          {values.questions.map((question, index) => (
            <Fragment key={`feedback_request_form_question_${index}`}>
              <PanelFormInput title={`Question ${index + 1}`}>
                <div className="feedback-request-question-container">
                  <Field
                    component={TextInput}
                    name={`questions.${index}.text`}
                    value={question.text}
                    placeholder="Write your question..."
                    error={!!errors.questions?.[index]}
                    textarea
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    size="xsmall"
                    secondary
                    onClick={() => arrayHelpers.remove(index)}
                    invisible={!(index !== 0)}
                  />
                </div>
              </PanelFormInput>
              <PanelFormInput wrapWidth>
                <Switch
                  name={`questions.${index}.requestEvaluation`}
                  label="Add 5-point scale:"
                  large
                />
              </PanelFormInput>
            </Fragment>
          ))}
          <PanelFormInput wrapWidth>
            <IconButton
              icon={<AddIcon />}
              text="Add question"
              className="full-width-on-mobile"
              onClick={() => {
                arrayHelpers.push({ text: "", requestEvaluation: false });
              }}
            />
          </PanelFormInput>
        </>
      );
    }}
  />
);

export default CreateFeedbackRequestFormQuestions;
