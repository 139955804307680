import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./BlurredCollapse.scss";

const BlurredCollapse = (props) => {
  const { className, children, onSelect, isSelected } = props;

  const ref = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [isTooBig, setIsTooBig] = useState(null);

  useEffect(() => {
    setIsTooBig(ref?.current?.clientHeight >= 270);
  }, [ref]);

  const handleClick = () => {
    if (onSelect) {
      onSelect();
    } else if (isTooBig) {
      setIsOpen(true);
    }
  };

  return (
    <div
      className={cx("blurred-collapse", className, {
        "blurred-collapse-closed": !isOpen && isTooBig,
        "blurred-collapse-selectable": !!onSelect,
        "blurred-collapse-selected": isSelected && !!onSelect,
        hidden: isTooBig === null,
      })}
      onClick={handleClick}
      ref={ref}
    >
      {children}
    </div>
  );
};

BlurredCollapse.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default BlurredCollapse;
