import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setFetchedGivenFeedbackRequestsInit = () => {
  return {
    type: "GET_GIVEN_FEEDBACK_REQUESTS_INIT",
  };
};

const setFetchedGivenFeedbackRequestsLoading = () => {
  return {
    type: "GET_GIVEN_FEEDBACK_REQUESTS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedGivenFeedbackRequests = (response) => {
  return {
    type: "GET_GIVEN_FEEDBACK_REQUESTS",
    payload: response.data,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedGivenFeedbackRequestsError = () => {
  return {
    type: "GET_GIVEN_FEEDBACK_REQUESTS_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getGivenFeedbackRequests = () => {
  return (dispatch) => {
    dispatch(setFetchedGivenFeedbackRequestsLoading());
    _fetch("api/FeedbackRequests?given=true")
      .then(async (response) => {
        if (response.success) {
          await dispatch(setFetchedGivenFeedbackRequests(response));
        } else {
          dispatch(setFetchedGivenFeedbackRequestsError());
        }
      })
      .catch(() => dispatch(setFetchedGivenFeedbackRequestsError()));
  };
};

export const resetGivenFeedbackRequests = () => {
  return (dispatch) => {
    dispatch(setFetchedGivenFeedbackRequestsInit());
  };
};
