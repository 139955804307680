import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./Tag.scss";

const Tag = ({ children, color, className }) => {
  const getColor = () => {
    if (color === "yellow") {
      return "#fff5de";
    } else if (color === "red") {
      return "#ffe3de";
    }
    return "#f1f3fa";
  };

  return (
    <div
      className={cx("tag", className)}
      style={{ backgroundColor: getColor() }}
    >
      {children}
    </div>
  );
};

Tag.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  color: PropTypes.oneOf(["yellow", "grey", "red"]),
  className: PropTypes.string,
};

export default Tag;
