import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as ListIcon } from "../../../assets/icons/list.svg";
import { ReactComponent as MoreIcon } from "../../../assets/icons/more.svg";
import { ROUTES } from "../../../constants/routes";
import IconButton from "../../IconButton";
import Dropdown from "../../Dropdown";
import "./InactiveProjectPanel.scss";

const InactiveProjectPanel = ({ projectName, projectId, isAdmin }) => {
  const adminActions = [
    {
      text: "Create retro",
      to: `${ROUTES.RETRO_PROJECTS.path}/${projectId}/admin/start-sprint`,
    },
    {
      text: "Edit project admins",
      to: ROUTES.EDIT_PROJECT_ADMINS.path,
    },
  ];

  return (
    <div className="panel inactive-project-panel">
      <h2 className="inactive-project-panel-title">{projectName}</h2>
      <div className="inactive-project-panel-right">
        <span>There are no active sprints</span>
        <div className="inactive-project-panel-actions">
          <IconButton
            to={`${ROUTES.RETRO_PROJECTS.path}/${projectId}/sprints`}
            icon={<ListIcon />}
          />
          {isAdmin && (
            <Dropdown menuItems={adminActions}>
              <IconButton icon={<MoreIcon />} />
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
};

InactiveProjectPanel.propTypes = {
  projectName: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default InactiveProjectPanel;
