import React from "react";
import Article from "../Article";
import GreyContainer from "../GreyContainer";
import Video from "../Video";

const UserManualArticle = () => (
  <Article>
    <ol className="article-list">
      <GreyContainer className="article-section">
        <li className="article-section-header">Add Retro app</li>
        <ul>
          <li className="article-section-item">
            Create a team{" "}
            <strong>
              <i>
                (Teams {">"} Join or create a team {">"} Create a new team)
              </i>
            </strong>{" "}
            and invite people to join it.
          </li>
          <li className="article-section-item">
            Every Team by default comes with{" "}
            <strong>
              <i>General channel</i>
            </strong>
            , but you can add as many channels as you want.
          </li>
          <li className="article-section-item">
            Go to the channel, click{" "}
            <strong>
              <i>Add a tab</i>{" "}
              <img
                src={require("../../assets/images/user-manual-article/add-button.png")}
                alt="more-button"
                className="article-text-image"
              />{" "}
            </strong>{" "}
            and choose <strong>Retro</strong> app.
          </li>
          <li className="article-section-item">
            The app should be added in every channel. Use a separate channel or
            a team for every new project.
          </li>
          <li className="article-section-item">
            After adding this app, you have to choose the person who will be the
            Project admin and organize a team feedback/retrospective meeting.
          </li>
          <li className="article-section-item">
            You will see two sections in the application: Feedback and Retro.
          </li>
          <Video className="article-video" src="Videos/install.mp4" />
        </ul>
      </GreyContainer>
      <GreyContainer className="article-section">
        <li className="article-section-header">Feedback section</li>{" "}
        <p>This section is dedicated to personal feedback. User can:</p>
        <ul>
          <li className="article-section-item">
            Give the feedback individually for every member of his/her team.
          </li>
          <li className="article-section-item">
            Use the <i>Incognito</i> feature, which lets him give the feedback
            anonymously. In this case, the employee who receives the feedback
            sees <i>Anonymous</i> instead of the name of the feedback giver.
          </li>
          <Video className="article-video" src="Videos/feedback.mp4" />
          <li className="article-section-item">
            Receive personal feedback from all team members.
          </li>
          <li className="article-section-item">
            See the history of received feedback about himself/herself and
            comments provided to colleagues.
          </li>
          <li className="article-section-item">
            Add CC and share feedback about colleagues with third parties.
          </li>
          <li className="article-section-item">
            Initiate the receiving of feedback and request colleagues to answer
            the questions. It is possible to use open-ended questions or{" "}
            <strong>Request for evaluation</strong> function, which gives a
            5-point Likert scale.
          </li>
          <Video className="article-video" src="Videos/request.mp4" />
        </ul>
      </GreyContainer>
      <GreyContainer className="article-section">
        <li className="article-section-header">Retro section</li>{" "}
        <p>
          This part is dedicated for the team and its work. Employees can share
          their feedback and opinion within the team and evaluate efficiency of
          their work.
        </p>
        <h4>The process</h4>
        <ul>
          <li className="article-section-item">
            Project administrator starts retro and fills in setting form.
          </li>
          <li className="article-section-item">
            Each participant must fill in an individual form before a
            retrospective meeting.
          </li>
          <li className="article-section-item">
            The administrator stops filling the individual forms and completes
            retro. After that the answers of all participants land into a final
            form.
          </li>
          <li className="article-section-item">
            The team discusses comments provided by all participants and agrees
            on the actions that will be taken.
          </li>
          <li className="article-section-item">
            Items discussed and filled in the final form can be exported to
            Confluence or PDF.
          </li>
        </ul>
        <h4>Start retro</h4>
        <p>
          The application has an admin panel. People, who have Project admin
          roles, can take more actions by pressing the button More{" "}
          <img
            src={require("../../assets/images/user-manual-article/more-button.png")}
            alt="more-button"
            className="article-text-image"
          />
          .
        </p>
        <ul>
          <li>
            <p>
              The administrator must choose Start retro and fill in setting form
              – retro name, participants, sprint period and date of
              retrospective meeting. Retro start date – the day when all
              participants will be able to fill in individual forms.
            </p>
          </li>
          <li>
            <p>
              Also, the team should define main 5 competencies that will be
              measured during the project. Each person has to give his/her
              feedback for several other team members. Team can use{" "}
              <i>Incognito</i> feature.
            </p>
          </li>
          <Video className="article-video" src="Videos/settings.mp4" />
          <li>
            <p>
              The settings of sprint period and retrospective date can be
              adjusted later by the administrator{" "}
              <i>(More {">"} Edit sprint info)</i>.{" "}
            </p>
          </li>
        </ul>
        <Video className="article-video" src="Videos/edit-sprint.mp4" />
        <h4>Fill individual form</h4>
        <p>In retro form each participant should:</p>
        <ul>
          <li className="article-section-item">
            Describe how he/she felt during this sprint.
          </li>
          <img
            className="article-image"
            src={require("../../assets/images/user-manual-article/mood.png")}
            alt="mood-evaluation"
          />
          <li className="article-section-item">
            Evaluate competencies of himself/herself and randomly selected team
            member.
          </li>
          <li className="article-section-item">
            Also, he/she can add and rate additional team member.
          </li>
          <img
            className="article-image"
            src={require("../../assets/images/user-manual-article/evaluations.png")}
            alt="competences-evaluations"
          />
          <li className="article-section-item">
            Use the <i>Incognito</i> feature, which allows to submit rates
            anonymously.
          </li>
          <li className="article-section-item">
            Provide comments on what the team did well and what should be
            improved.
          </li>
          <img
            className="article-image"
            src={require("../../assets/images/user-manual-article/comments.png")}
            alt="retrospective-comments"
          />
        </ul>
        <h4>Retro team discussion</h4>
        <ul>
          <li className="article-section-item">
            When all participants submit their forms, the administrator
            completes retro and stops filling <i>(More {">"} Complete retro)</i>
            .
          </li>
          <li className="article-section-item">
            After this action, participants can no longer edit their forms. The
            answers of all the members are displayed in the final form
            <i>(More {">"} Final form)</i>.
          </li>
          <li className="article-section-item">
            During the retrospective meeting the participants discuss the
            comments what the team did well and what should be improved. Also,
            the team agrees on the actions that will be taken, responsible
            persons and deadlines.
          </li>
          <Video className="article-video" src="Videos/final-form.mp4" />
        </ul>
        <h4>Export results</h4>
        <p>
          The results of edited and saved final form can be exported to
          Confluence or PDF. The administrator can export results immediately
          after saving the form or opening this from archive later.
        </p>
        <p>Results exported to PDF are saved in the Downloads folder.</p>
        <p>Exporting to Confluence need such information:</p>
        <ul>
          <li className="article-section-item">Confluence URL</li>
          <li className="article-section-item">Confluence username</li>
          <li className="article-section-item">Confluence password</li>
          <li className="article-section-item">
            Page Space Key – this information can be found in the Confluence
            space by selecting{" "}
            <i>
              Space tool {">"} Overview {">"} Space details
            </i>{" "}
            and copying the value next to the Key label.
          </li>
          <li className="article-section-item">
            Primary page name – it is a page created inside Confluence space. If
            you would like to use default primary page, you can leave this field
            blank.
          </li>
        </ul>
        <h4>Retro archive</h4>
        <p>
          Each project has its own archive. It can be found by clicking the
          button Archive{" "}
          <img
            className="article-text-image"
            src={require("../../assets/images/user-manual-article/archive-button.png")}
            alt="archive-button"
          />
          . Here user can find:
        </p>
        <ul>
          <li className="article-section-item">
            All final forms of retrospective meetings.
          </li>
          <li className="article-section-item">
            All comments on what the team did well and what should be improved.
          </li>
          <li className="article-section-item">Evaluation of competencies:</li>
          <ul>
            <li className="article-section-item">
              How he/she evaluates himself/herself;
            </li>
            <li className="article-section-item">
              How he/she is evaluated by other team members;
            </li>
            <li className="article-section-item">
              The overall evaluation of the team.
            </li>
            <img
              className="article-image"
              src={require("../../assets/images/user-manual-article/evaluations-graphic.png")}
              alt="retrospective-comments"
            />
          </ul>
        </ul>
      </GreyContainer>
    </ol>
  </Article>
);

export default UserManualArticle;
