import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../actions/setPageTitle";
import { ROUTES } from "../../constants/routes";
import StatementContainer from "../../components/StatementContainer";
import StatementSection from "../../components/StatementContainer/StatementSection";
import StatementSectionHeader from "../../components/StatementContainer/StatementSection/StatementSectionHeader";
import StatementSectionContent from "../../components/StatementContainer/StatementSection/StatementSectionContent";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setPageTitle({
        text: "Privacy policy",
        backPath: ROUTES.HOME.path,
      })
    );
  }, [dispatch]);

  return (
    <StatementContainer>
      <StatementSection>
        <StatementSectionHeader>1. Introduction</StatementSectionHeader>
        <StatementSectionContent>
          <p>Welcome to Retro</p>
          <p>
            UAB Baltic Amadeus (“us”, “we”, or “our”) operates Retro
            (hereinafter referred to as “Service”).
          </p>
          <p>
            Our Privacy Policy governs your visit to{" "}
            <a href="https://retro.ba.lt">https://retro.ba.lt</a>, and explains
            how we collect, safeguard and disclose information that results from
            your use of our Service.
          </p>
          <p>
            We use your data to provide and improve Service. By using Service,
            you agree to the collection and use of information in accordance
            with this policy
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>2. Definitions</StatementSectionHeader>
        <StatementSectionContent>
          <p>SERVICE means the Retro app operated by UAB Baltic Amadeus </p>
          <p>
            PERSONAL DATA means data about a living individual who can be
            identified from those data (or from those and other information
            either in our possession or likely to come into our possession).
          </p>
          <p>
            USAGE DATA is data collected automatically either generated by the
            use of Service or from Service infrastructure itself (for example,
            the duration of a page visit). COOKIES are small files stored on
            your device (computer or mobile device).
          </p>
          <p>
            DATA CONTROLLER means a natural or legal person who (either alone or
            jointly or in common with other persons) determines the purposes for
            which and the manner in which any personal data are, or are to be,
            processed. For the purpose of this Privacy Policy, we are a Data
            Controller of your data.
          </p>
          <p>
            DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal
            person who processes the data on behalf of the Data Controller. We
            may use the services of various Service Providers in order to
            process your data more effectively.
          </p>
          <p>
            DATA SUBJECT is any living individual who is the subject of Personal
            Data.
          </p>
          <p>
            THE USER is the individual using our Service. The User corresponds
            to the Data Subject, who is the subject of Personal Data.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>
          3. Information Collection and Use
        </StatementSectionHeader>
        <StatementSectionContent>
          <p>
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>
          4. Types of Data Collected
        </StatementSectionHeader>
        <StatementSectionContent>
          <p>Personal Data</p>
          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you (“Personal Data”). Personally identifiable information
            may include, but is not limited to:
          </p>
          <ul>
            <li>
              Received messages and data that Data subject provided to it.
            </li>
            <li>
              Access Data subject profile information such as name, email
              address, company name, and preferred language.
            </li>
            <li>
              Receive messages and data that team members provide to it in a
              channel.
            </li>
            <li>
              Access this team's information such as team name, channel list and
              roster (including team member's names and email addresses) - and
              use this to contact them​.
            </li>
          </ul>
          <p>Usage Data</p>
          <p>
            We may also collect information that your browser sends whenever you
            visit our Service or when you access Service by or through any
            device (“Usage Data”). This Usage Data may include information such
            as your computer’s Internet Protocol address (e.g. IP address),
            browser type, browser version, the pages of our Service that you
            visit, the time and date of your visit, the time spent on those
            pages, unique device identifiers and other diagnostic data. When you
            access Service with a device, this Usage Data may include
            information such as the type of device you use, your device unique
            ID, the IP address of your device, your device operating system, the
            type of Internet browser you use, unique device identifiers and
            other diagnostic data.
          </p>
          <p>Location Data</p>
          <p>
            We may use and store information about your location if you give us
            permission to do so (“Location Data”). We use this data to provide
            features of our Service, to improve and customize our Service.
          </p>
          <p>Tracking Cookies Data</p>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information. Cookies are
            files with a small amount of data which may include an anonymous
            unique identifier. Cookies are sent to your browser from a website
            and stored on your device. Other tracking technologies are also used
            such as beacons, tags and scripts to collect and track information
            and to improve and analyze our Service. You can instruct your
            browser to refuse all cookies or to indicate when a cookie is being
            sent. However, if you do not accept cookies, you may not be able to
            use some portions of our Service.
          </p>
          <p>Examples of Cookies we use:</p>
          <ul>
            <li>Session Cookies: </li>
            <li>Preference Cookies: </li>
            <li>Security Cookies:</li>
            <li>Analytics Cookies:</li>
          </ul>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>5. Use of Data</StatementSectionHeader>
        <StatementSectionContent>
          <p>Retro may use the collected data for various purposes:</p>
          <ul>
            <li>to provide and maintain our Service;</li>
            <li>to notify you about changes to our Service;</li>
            <li>
              to allow you to participate in interactive features of our Service
              when you choose to do so;
            </li>
            <li>to provide customer support;</li>
            <li>
              to gather analysis or valuable information so that we can improve
              our Service;
            </li>
            <li>to monitor the usage of our Service;</li>
            <li>to detect, prevent and address technical issues;</li>
            <li>to fulfil any other purpose for which you provide it;</li>
            <li>
              to carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for
              billing and collection;
            </li>
            <li>
              in any other way we may describe when you provide the information;
            </li>
            <li>for any other purpose with your consent.</li>
          </ul>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>6. Retention of Data</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies. We will also retain Usage Data
            for internal analysis purposes. Usage Data is generally retained for
            a shorter period, except when this data is used to strengthen the
            security or to improve the functionality of our Service, or we are
            legally obligated to retain this data for longer time periods.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>7. Transfer of Data</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            Your information, including Personal Data, may be transferred to –
            and maintained on – computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>
          <p>
            If you are located outside Ireland and choose to provide information
            to us, please note that we transfer the data, including Personal
            Data, to Ireland and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            Retro will take all the steps reasonably necessary to ensure that
            your data is treated securely and in accordance with this Privacy
            Policy and no transfer of your Personal Data will take place to an
            organisation or a country unless there are adequate controls in
            place including the security of your data and other personal
            information.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>8. Disclosure of Data</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            We may disclose personal information that we collect, or you
            provide:
          </p>
          <ul>
            <li>Disclosure for Law Enforcement.</li>
          </ul>
          <p>
            Under certain circumstances, we may be required to disclose your
            Personal Data if required to do so by law or in response to valid
            requests by public authorities.
          </p>
          <ul>
            <li>Business Transaction.</li>
          </ul>
          <p>
            If we or our subsidiaries are involved in a merger, acquisition or
            asset sale, your Personal Data may be transferred.
          </p>
          <ul>
            <li>Other cases. We may disclose your information also:</li>
            <li>to our subsidiaries and affiliates;</li>
            <li>
              to contractors, service providers, and other third parties we use
              to support our business;
            </li>
            <li>to fulfill the purpose for which you provide it;</li>
            <li>
              for the purpose of including your company’s logo on our website;
            </li>
            <li>
              for any other purpose disclosed by us when you provide the
              information;
            </li>
            <li>with your consent in any other cases;</li>
            <li>
              if we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of the Company, our customers, or
              others.
            </li>
          </ul>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>9. Security of Data</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>10. Service Providers</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            We may employ third party companies and individuals to facilitate
            our Service ("Service Providers"), provide Service on our behalf,
            perform Service-related services or assist us in analysing how our
            Service is used.
          </p>
          <p>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>11. Analytics</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>12. CI/CD tools</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            We may use third-party Service Providers to automate the development
            process of our Service.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>
          13. Behavioral Remarketing
        </StatementSectionHeader>
        <StatementSectionContent>
          <p>
            We may use remarketing services to advertise on third party websites
            to you after you visited our Service. We and our third-party vendors
            use cookies to inform, optimise and serve ads based on your past
            visits to our Service.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>
          14. Links to Other Sites
        </StatementSectionHeader>
        <StatementSectionContent>
          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party’s site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>15. Children’s Privacy</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            Our Services are not intended for use by children under the age of
            16 ("Child" or "Children").
          </p>
          <p>
            We do not knowingly collect personally identifiable information from
            Children under 18. If you become aware that a Child has provided us
            with Personal Data, please contact us. If we become aware that we
            have collected Personal Data from Children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>
          16. Changes to This Privacy Policy
        </StatementSectionHeader>
        <StatementSectionContent>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update
            "effective date" at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>17. Bot service</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            Our Retro bot is enabled by Microsoft Bot Framework. The Microsoft
            Bot Framework is a set of web-services that enable intelligent
            services and connections using conversation channels you authorize.
            As a service provider, Microsoft will transmit content you provide
            to our bot/service in order to enable the service. For more
            information about Microsoft privacy policies please see their
            privacy statement here:{" "}
            <a
              href="http://go.microsoft.com/fwlink/?LinkId=521839"
              target="_parent"
            >
              http://go.microsoft.com/fwlink/?LinkId=521839
            </a>
            . In addition, your interactions with this bot/service are also
            subject to the conversational channel's applicable terms of use,
            privacy and data collection policies. To report abuse when using a
            bot that uses the Microsoft Bot Framework to Microsoft, please visit
            the Microsoft Bot Framework website at{" "}
            <a href="https://www.botframework.com" target="_parent">
              https://www.botframework.com
            </a>{" "}
            and use the "Report Abuse" link in the menu to contact Microsoft.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>18. Contact Us</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us by email: info@ba.lt
          </p>
        </StatementSectionContent>
      </StatementSection>
    </StatementContainer>
  );
};

export default PrivacyPolicy;
