import React, { useEffect, useRef, forwardRef, memo } from "react";
import PropTypes from "prop-types";
import Chart from "chart.js";
import { isMobile } from "../../utils/viewports";
import "./RadarChart.scss";

const RadarChart = memo(
  forwardRef((props, fRef) => {
    const { id, labels, chartData, width, height, className } = props;

    const chartRef = useRef();

    useEffect(() => {
      const ref = chartRef.current;

      const chart = new Chart(ref, {
        type: "radar",
        data: {
          labels: labels,
          datasets: chartData,
        },

        options: {
          responsive: true,
          scale: {
            pointLabels: { fontSize: isMobile() ? 8 : 11 },
            ticks: {
              min: 0,
              max: 5,
              stepSize: 1,
            },
          },
          legend: { display: true, position: "bottom" },
        },
      });

      if (fRef) {
        fRef.current = chart;
      }
    }, [labels, chartData, fRef]);

    return (
      <canvas
        id={id}
        height={height}
        width={width}
        ref={chartRef}
        className={className}
      />
    );
  })
);

RadarChart.propTypes = {
  id: PropTypes.string.isRequired,
  labels: PropTypes.array.isRequired,
  chartData: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default RadarChart;
