import React from "react";
import * as msTeams from "@microsoft/teams-js";

export default function LoginStart() {
  msTeams.initialize();

  msTeams.getContext(function (context) {
    const state = _guid();
    localStorage.setItem("auth.state", state); //TODO: rename auth.state to auth.state (auth.error and auth.result too). And take them from constants file
    localStorage.removeItem("auth.error");

    const queryParams = {
      client_id: process.env.REACT_APP_CLIENT_ID, //TODO: get client_id from config file
      response_type: "id_token token",
      response_mode: "fragment",
      scope: `openid api://${process.env.REACT_APP_WEB_API_CLIENT_ID}/user_access`, //TODO: get scope id from config file
      redirect_uri: window.location.origin + "/login-end",
      nonce: _guid(),
      state: state,
      login_hint: context.loginHint,
    };

    const authorizeEndpoint = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${toQueryString(
      queryParams
    )}`;

    window.location.assign(authorizeEndpoint);
  });

  function toQueryString(queryParams) {
    let encodedQueryParams = [];
    for (let key in queryParams) {
      encodedQueryParams.push(key + "=" + encodeURIComponent(queryParams[key]));
    }
    return encodedQueryParams.join("&");
  }

  function _decimalToHex(number) {
    let hex = number.toString(16);
    while (hex.length < 2) {
      hex = "0" + hex;
    }
    return hex;
  }

  function _guid() {
    // RFC4122: The version 4 UUID is meant for generating UUIDs from truly-random or
    // pseudo-random numbers.
    // The algorithm is as follows:
    //     Set the two most significant bits (bits 6 and 7) of the
    //        clock_seq_hi_and_reserved to zero and one, respectively.
    //     Set the four most significant bits (bits 12 through 15) of the
    //        time_hi_and_version field to the 4-bit version number from
    //        Section 4.1.3. Version4
    //     Set all the other bits to randomly (or pseudo-randomly) chosen
    //     values.
    // UUID                   = time-low "-" time-mid "-"time-high-and-version "-"clock-seq-reserved and low(2hexOctet)"-" node
    // time-low               = 4hexOctet
    // time-mid               = 2hexOctet
    // time-high-and-version  = 2hexOctet
    // clock-seq-and-reserved = hexOctet:
    // clock-seq-low          = hexOctet
    // node                   = 6hexOctet
    // Format: xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
    // y could be 1000, 1001, 1010, 1011 since most significant two bits needs to be 10
    // y values are 8, 9, A, B
    const cryptoObj = window.crypto || window.msCrypto; // for IE 11
    if (cryptoObj && cryptoObj.getRandomValues) {
      const buffer = new Uint8Array(16);
      cryptoObj.getRandomValues(buffer);
      //buffer[6] and buffer[7] represents the time_hi_and_version field. We will set the four most significant bits (4 through 7) of buffer[6] to represent decimal number 4 (UUID version number).
      buffer[6] |= 0x40; //buffer[6] | 01000000 will set the 6 bit to 1.
      buffer[6] &= 0x4f; //buffer[6] & 01001111 will set the 4, 5, and 7 bit to 0 such that bits 4-7 == 0100 = "4".
      //buffer[8] represents the clock_seq_hi_and_reserved field. We will set the two most significant bits (6 and 7) of the clock_seq_hi_and_reserved to zero and one, respectively.
      buffer[8] |= 0x80; //buffer[8] | 10000000 will set the 7 bit to 1.
      buffer[8] &= 0xbf; //buffer[8] & 10111111 will set the 6 bit to 0.
      return (
        _decimalToHex(buffer[0]) +
        _decimalToHex(buffer[1]) +
        _decimalToHex(buffer[2]) +
        _decimalToHex(buffer[3]) +
        "-" +
        _decimalToHex(buffer[4]) +
        _decimalToHex(buffer[5]) +
        "-" +
        _decimalToHex(buffer[6]) +
        _decimalToHex(buffer[7]) +
        "-" +
        _decimalToHex(buffer[8]) +
        _decimalToHex(buffer[9]) +
        "-" +
        _decimalToHex(buffer[10]) +
        _decimalToHex(buffer[11]) +
        _decimalToHex(buffer[12]) +
        _decimalToHex(buffer[13]) +
        _decimalToHex(buffer[14]) +
        _decimalToHex(buffer[15])
      );
    } else {
      const guidHolder = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
      const hex = "0123456789abcdef";
      let r = 0;
      let guidResponse = "";
      for (let i = 0; i < 36; i++) {
        if (guidHolder[i] !== "-" && guidHolder[i] !== "4") {
          // each x and y needs to be random
          r = (Math.random() * 16) | 0;
        }
        if (guidHolder[i] === "x") {
          guidResponse += hex[r];
        } else if (guidHolder[i] === "y") {
          // clock-seq-and-reserved first hex is filtered and remaining hex values are random
          r &= 0x3; // bit and with 0011 to set pos 2 to zero ?0??
          r |= 0x8; // set pos 3 to 1 as 1???
          guidResponse += hex[r];
        } else {
          guidResponse += guidHolder[i];
        }
      }
      return guidResponse;
    }
  }

  return <></>;
}
