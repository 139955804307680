import React from "react";
import PropTypes from "prop-types";
import Modal from "../../Modal";
import "./RetroReviewDetailedEvaluation.scss";

const RetroReviewDetailedEvaluation = (props) => {
  const {
    isOpen,
    onClose,
    competences,
    teamAverages,
    teamMood,
    participantsCount,
  } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2>Detailed evaluation</h2>
      <div className="retro-review-detailed-evaluation-table-container">
        <table className="retro-review-detailed-evaluation-table">
          <thead>
            <tr>
              <th>Participants:</th>
              <th>Team mood:</th>
              {competences.map((competence) => (
                <th key={`retro_review_competence_${competence}`}>
                  {competence}
                </th>
              ))}
            </tr>
            <tr>
              <td>{participantsCount}</td>
              <td>{teamMood}</td>
              {teamAverages.map((item, index) => (
                <td key={`retro_review_evaluation_average_${index}`}>{item}</td>
              ))}
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </Modal>
  );
};

RetroReviewDetailedEvaluation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  competences: PropTypes.array.isRequired,
  teamAverages: PropTypes.array.isRequired,
  teamMood: PropTypes.number.isRequired,
  participantsCount: PropTypes.number.isRequired,
};

export default RetroReviewDetailedEvaluation;
