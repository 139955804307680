export const VALUES = {
  MAX_USER_COMMENT_LENGTH: 255,
  MAX_ACTION_POINTS_COMMENT_LENGTH: 255,
  MAX_USER_FEEDBACK_LENGTH: 2048,
  SCALE: [
    {
      value: "1",
      label: "Very bad",
      color: "pink",
    },
    {
      value: "2",
      label: "Poor",
      color: "orange",
    },
    {
      value: "3",
      label: "Average",
      color: "yellow",
    },
    {
      value: "4",
      label: "Good",
      color: "lightgreen",
    },
    {
      value: "5",
      label: "Excellent",
      color: "green",
    },
  ],
};
