import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../actions/setPageTitle";
import { ROUTES } from "../../constants/routes";
import UserManualArticle from "../../components/UserManualArticle";

const UserManual = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setPageTitle({
        text: "User manual",
        backPath: ROUTES.HOME.path,
      })
    );
  }, [dispatch]);

  return <UserManualArticle />;
};

export default UserManual;
