import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setCCFeedbacksInit = () => {
  return {
    type: "GET_CC_FEEDBACKS_INIT",
  };
};

const setFetchedCCFeedbacksLoading = () => {
  return {
    type: "GET_CC_FEEDBACKS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedCCFeedbacks = (response) => {
  return {
    type: "GET_CC_FEEDBACKS",
    payload: response.data,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedCCFeedbacksError = () => {
  return {
    type: "GET_CC_FEEDBACKS_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getCCFeedbacks = () => {
  return (dispatch) => {
    dispatch(setFetchedCCFeedbacksLoading());
    _fetch("api/Feedbacks?mentionedInCc=true")
      .then((response) => {
        if (response.success) {
          dispatch(setFetchedCCFeedbacks(response));
        } else {
          dispatch(setFetchedCCFeedbacksError());
        }
      })
      .catch(() => dispatch(setFetchedCCFeedbacksError()));
  };
};

export const resetCCFeedbacks = () => {
  return (dispatch) => {
    dispatch(setCCFeedbacksInit());
  };
};
