import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as msTeams from "@microsoft/teams-js";
import FeedbacksContainer from "../../components/FeedbacksContainer";
import Spinner from "../../components/Spinner";
import { setPageTitle } from "../../actions/setPageTitle";
import {
  getReceivedFeedbacks,
  resetReceivedFeedbacks,
} from "../../actions/getReceivedFeedbacks";
import {
  getReceivedFeedbackRequests,
  resetReceivedFeedbackRequests,
} from "../../actions/getReceivedFeedbackRequests";
import {
  getGivenFeedbacks,
  resetGivenFeedbacks,
} from "../../actions/getGivenFeedbacks";
import { getCCFeedbacks, resetCCFeedbacks } from "../../actions/getCCFeedbacks";
import { getActiveUsers, resetActiveUsers } from "../../actions/getActiveUsers";
import {
  getNotifications,
  resetNotifications,
} from "../../actions/getNotifications";
import {
  getGivenFeedbackRequests,
  resetGivenFeedbackRequests,
} from "../../actions/getGivenFeedbackRequests";
import {
  getReceivedFeedbackAnswers,
  resetReceivedFeedbackAnswers,
} from "../../actions/getReceivedFeedbackAnswers";
import {
  getGivenFeedbackAnswers,
  resetGivenFeedbackAnswers,
} from "../../actions/getGivenFeedbackAnswers";
import { ROUTES } from "../../constants/routes";
import { hasErrors, areInitiallyLoading } from "../../utils/pageStates";

const Feedbacks = () => {
  const dispatch = useDispatch();

  const notifications = useSelector(({ notifications }) => notifications);
  const activeUsers = useSelector(({ activeUsers }) => activeUsers);
  const givenFeedbacks = useSelector(({ givenFeedbacks }) => givenFeedbacks);
  const receivedFeedbacks = useSelector(
    ({ receivedFeedbacks }) => receivedFeedbacks
  );
  const receivedFeedbackRequests = useSelector(
    ({ receivedFeedbackRequests }) => receivedFeedbackRequests
  );
  const receivedFeedbackAnswers = useSelector(
    ({ receivedFeedbackAnswers }) => receivedFeedbackAnswers
  );
  const givenFeedbackRequests = useSelector(
    ({ givenFeedbackRequests }) => givenFeedbackRequests
  );
  const givenFeedbackAnswers = useSelector(
    ({ givenFeedbackAnswers }) => givenFeedbackAnswers
  );
  const ccFeedbacks = useSelector(({ ccFeedbacks }) => ccFeedbacks);

  const fetches = [
    notifications,
    activeUsers,
    givenFeedbacks,
    receivedFeedbacks,
    receivedFeedbackRequests,
    receivedFeedbackAnswers,
    givenFeedbacks,
    givenFeedbackRequests,
    givenFeedbackAnswers,
    ccFeedbacks,
  ];

  useEffect(() => {
    dispatch(getReceivedFeedbacks());
    dispatch(getGivenFeedbacks());
    dispatch(getCCFeedbacks());
    dispatch(getReceivedFeedbackRequests());
    dispatch(getNotifications());
    dispatch(getGivenFeedbackRequests());
    dispatch(getReceivedFeedbackAnswers());
    dispatch(getGivenFeedbackAnswers());

    return () => {
      dispatch(resetReceivedFeedbacks());
      dispatch(resetGivenFeedbacks());
      dispatch(resetCCFeedbacks());
      dispatch(resetReceivedFeedbackRequests());
      dispatch(resetNotifications());
      dispatch(resetGivenFeedbackRequests());
      dispatch(resetReceivedFeedbackAnswers());
      dispatch(resetGivenFeedbackAnswers());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      setPageTitle({
        text: "Feedbacks",
        backPath: ROUTES.HOME.path,
      })
    );
    msTeams.initialize();
    msTeams.getContext(({ channelId }) => {
      dispatch(getActiveUsers(channelId));
    });

    return () => dispatch(resetActiveUsers());
  }, [dispatch]);

  if (hasErrors(fetches)) {
    return <h2>Something went wrong :(</h2>;
  }

  if (areInitiallyLoading(fetches)) {
    return <Spinner />;
  }

  return <FeedbacksContainer />;
};

export default Feedbacks;
