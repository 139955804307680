import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { FieldArray } from "formik";
import Select from "../../../Select";
import IconButton from "../../../IconButton";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import CompetenceEvaluationPanel from "./CompetenceEvaluationPanel";
import "./Evaluation.scss";

const Evaluation = (props) => {
  const {
    mandatoryUser,
    sprintUsers,
    arrayHelpers,
    evaluationIndex,
    error = false,
  } = props;

  const { evaluations } = arrayHelpers.form.values;

  const userToOptionsEntity = (items) => {
    const res = items.map((item) => {
      const isDisabled = !!evaluations.find(
        (evaluation) => parseInt(evaluation.evaluatedUser, 10) === item.userId
      );

      return {
        title: item.fullName,
        value: item.userId,
        disabled: isDisabled,
      };
    });

    return res;
  };

  return (
    <>
      <div className={cx("panel", "evaluation", { "evaluation-error": error })}>
        {mandatoryUser && (
          <h3 className="evaluation-user-name">{mandatoryUser.fullName}</h3>
        )}

        {sprintUsers && sprintUsers.length >= evaluationIndex && (
          <div className="evaluation-select">
            <Select
              className="evaluation-user-name"
              name={`${arrayHelpers.name}.${evaluationIndex}.evaluatedUser`}
              placeholder="Choose a colleague..."
              options={userToOptionsEntity(sprintUsers)}
            />
            {!evaluations[evaluationIndex].isMandatory && (
              <IconButton
                type="button"
                size="xsmall"
                onClick={() => arrayHelpers.remove(evaluationIndex)}
                icon={<DeleteIcon />}
              />
            )}
          </div>
        )}

        {evaluations[evaluationIndex].evaluatedUser && (
          <FieldArray
            name={`${arrayHelpers.name}.${evaluationIndex}.competences`}
            render={(arrayHelpers) => (
              <div className="competence-evaluations">
                {evaluations[evaluationIndex].competences.map(
                  (competence, competenceIndex) => {
                    return (
                      <CompetenceEvaluationPanel
                        key={`competence_evaluation_panel_${competenceIndex}`}
                        competenceName={competence.competenceName}
                        competenceIndex={competenceIndex}
                        arrayHelpers={arrayHelpers}
                      />
                    );
                  }
                )}
              </div>
            )}
          />
        )}
      </div>
      {error && <span className="alert-text">This field is required</span>}
    </>
  );
};

Evaluation.propTypes = {
  mandatoryUser: PropTypes.object,
  sprintUsers: PropTypes.array,
  arrayHelpers: PropTypes.object.isRequired,
  evaluationIndex: PropTypes.number.isRequired,
  error: PropTypes.bool,
};

export default Evaluation;
