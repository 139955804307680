import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setSprintUsersInit = () => {
  return {
    type: "GET_SPRINT_USERS_INIT",
  };
};

const setFetchedSprintUsersLoading = () => {
  return {
    type: "GET_SPRINT_USERS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedSprintUsers = (response) => {
  return {
    type: "GET_SPRINT_USERS",
    payload: response,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedSprintUsersError = () => {
  return {
    type: "GET_SPRINT_USERS_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getSprintUsers = (sprintId) => {
  return (dispatch) => {
    dispatch(setFetchedSprintUsersLoading());
    _fetch(`api/Sprints/${sprintId}/Users`)
      .then((res) => {
        if (res.success) {
          dispatch(setFetchedSprintUsers(res.data));
        } else {
          dispatch(setFetchedSprintUsersError());
        }
      })
      .catch(() => {
        dispatch(setFetchedSprintUsersError());
      });
  };
};

export const resetSprintUsers = () => {
  return (dispatch) => {
    dispatch(setSprintUsersInit());
  };
};
