import React from "react";
import PropTypes from "prop-types";
import { Field, FieldArray } from "formik";
import TextInput from "../../../TextInput";
import IconButton from "../../../IconButton";
import ActionPointsInputPanel from "./ActionPointsInputPanel";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { VALUES } from "../../../../constants/values";
import "./CommentInputPanel.scss";

const CommentInputPanel = (props) => {
  const { index, arrayHelpers, autoFocus = false } = props;
  const { name } = arrayHelpers;

  const error = arrayHelpers.form.errors[name]?.[index]?.text;

  return (
    <div className="panel comment-input-panel">
      <div className="comment-input-panel-comment">
        <span className="comment-input-panel-comment-index">{index + 1}</span>
        <Field name={`${arrayHelpers.name}.${index}.text`}>
          {({ field }) => (
            <TextInput
              className="comment-input-panel-comment-input"
              autoFocus={autoFocus}
              error={!!error}
              maxLength={VALUES.MAX_USER_COMMENT_LENGTH}
              textarea
              autoGrowHeight
              {...field}
            />
          )}
        </Field>
        <IconButton
          className="comment-input-panel-comment-remove"
          size="xsmall"
          secondary
          icon={<DeleteIcon />}
          onClick={() => arrayHelpers.remove(index)}
        />
      </div>
      <FieldArray
        name={`${name}.${index}.actionPoints`}
        render={(actionPointsArrayHelpers) => (
          <ActionPointsInputPanel
            arrayHelpers={actionPointsArrayHelpers}
            index={index}
            commentsType={name}
          />
        )}
      />
    </div>
  );
};

CommentInputPanel.propTypes = {
  index: PropTypes.number.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
  autoFocus: PropTypes.bool,
};

export default CommentInputPanel;
