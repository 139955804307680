import React from "react";
import PropTypes from "prop-types";
import { FieldArray, Field } from "formik";
import SearchSelect from "../../../SearchSelect";

const ProjectCompetencesSelect = ({ setFieldValue, allProjectCompetences }) => {
  const competencesToOptionsEntity = (competences) =>
    competences.map((competence) => ({
      label: competence.competenceName,
      value: competence.competenceId,
    }));

  return (
    <FieldArray
      name="competences"
      render={(arrayHelpers) => {
        const { competences } = arrayHelpers.form.values;
        const { competences: competencesErrors } = arrayHelpers.form.errors;

        return competences.map((_item, index) => {
          const error = competencesErrors ? !!competencesErrors[index] : false;

          return (
            <Field
              key={`competence_select_${index}`}
              component={SearchSelect}
              name={`${arrayHelpers.name}.${index}`}
              value={competences[index]}
              setFieldValue={setFieldValue}
              options={competencesToOptionsEntity(allProjectCompetences)}
              selectedValues={competences}
              placeholder="Insert competence..."
              error={error}
            />
          );
        });
      }}
    />
  );
};

ProjectCompetencesSelect.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  allProjectCompetences: PropTypes.array.isRequired,
};

export default ProjectCompetencesSelect;
