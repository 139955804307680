import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Field } from "formik";
import "./RadioButtonScale.scss";

const RadioButtonScale = ({ name, inputs, size = "large", error = false }) => {
  const scale = (
    <div
      className={cx("radio-button-scale", {
        "radio-button-scale-single": inputs.length === 1,
        "radio-button-scale-medium": size === "medium",
      })}
    >
      {inputs.map((input, index) => (
        <label key={`radio_button_scale_item${index}`}>
          <Field type="radio" name={name} value={input.value} />
          <span className={cx("radio-button-scale-indicator", input.color)} />
          <span className="radio-button-scale-text">{input.label}</span>
        </label>
      ))}
    </div>
  );

  if (!error) {
    return scale;
  }

  return (
    <div className="radio-button-scale-error">
      <div className="radio-button-scale-error-container">{scale}</div>
      <span className="alert-text">This field is required</span>
    </div>
  );
};

RadioButtonScale.propTypes = {
  name: PropTypes.string.isRequired,
  inputs: PropTypes.array.isRequired,
  size: PropTypes.string,
  error: PropTypes.bool,
};

export default RadioButtonScale;
