const initState = { selectable: false, selectedIds: [] };

export default function (state = initState, { type, payload }) {
  switch (type) {
    case "SET_FEEDBACKS_TO_EXCEL_EXPORT":
      return payload;
    default:
      return state;
  }
}
