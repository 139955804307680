import getColor from "./getColor";

export const dataToChartDataset = (data) =>
  data
    .filter((item) => item.data.length > 0)
    .map((item, index) => {
      return {
        label: item.legendLabel,
        data: item.data,
        backgroundColor: getColor(index, 0.1),
        borderWidth: 2,
        borderColor: getColor(index, 1),
        pointBackgroundColor: getColor(index, 1),
      };
    });
