import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PageNotFound from "../../pages/PageNotFound";
import { ROUTES } from "../../constants/routes";
import RouteWrapper from "./RouteWrapper";
import DefaultLayout from "../../components/layouts/DefaultLayout";

const Routes = () => {
  const { text, backPath } = useSelector(({ pageTitle }) => pageTitle);

  return (
    <Router>
      <Switch>
        {Object.values(ROUTES).map((route, index) => (
          <RouteWrapper
            key={index}
            exact={true}
            path={route.path}
            component={route.component}
            layout={route.layout}
            title={text}
            backPath={backPath}
          />
        ))}
        <RouteWrapper component={PageNotFound} layout={DefaultLayout} />
      </Switch>
    </Router>
  );
};

export default Routes;
