import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Intent } from "@blueprintjs/core";
import { object, array, string } from "yup";
import GreyContainer from "../GreyContainer";
import PanelFormInput from "../PanelFormInput";
import Tagger from "../Tagger";
import Button from "../Button";
import Toaster from "../Toaster";
import CreateFeedbackRequestFormQuestions from "./CreateFeedbackRequestFormQuestions";
import { usersToTagEntity } from "../../utils/usersToTagEntity";
import _fetch from "../../utils/fetch";
import { ROUTES } from "../../constants/routes";
import "./CreateFeedbackRequestForm.scss";

const validationSchema = object().shape({
  toUsers: array().min(1).required(),
  questions: array()
    .of(object({ text: string().min(1).required() }))
    .min(1)
    .required(),
});

const CreateFeedbackRequestForm = () => {
  const [redirectToFeedbacks, setRedirectToFeedbacks] = useState(false);

  const activeUsers = useSelector(
    ({ activeUsers }) => activeUsers.payload.data
  );

  const setInitialValues = () => ({
    toUsers: [],
    questions: [{ text: "", requestEvaluation: false }],
  });

  const handleSubmit = (values) => {
    const { toUsers, questions } = values;

    const requestObj = {
      toUserIds: toUsers.map((user) => user.id),
      feedbackRequestData: questions.map((question) => ({
        question: question.text,
        isEvaluationRequired: question.requestEvaluation,
      })),
    };

    _fetch("api/FeedbackRequests", {
      method: "POST",
      data: requestObj,
    })
      .then((response) => {
        const message = {
          message: "Feedback request sent!",
          intent: Intent.SUCCESS,
        };

        if (response.success) {
          setRedirectToFeedbacks(true);
        } else {
          message.message = "Something went wrong";
          message.intent = Intent.DANGER;
        }

        Toaster.show(message);
      })
      .catch(() => {
        Toaster.show({
          message: "Something went wrong",
          intent: Intent.DANGER,
        });
      });
  };

  if (redirectToFeedbacks) {
    return <Redirect to={ROUTES.FEEDBACKS.path} />;
  }

  return (
    <GreyContainer className="create-feedback-request-container">
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={setInitialValues()}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, setFieldValue, isSubmitting }) => (
          <Form className="create-feedback-form">
            <div className="panel create-feedback-form-panel">
              <PanelFormInput title="Request feedback from">
                <Field
                  component={Tagger}
                  fieldValueName="toUsers"
                  placeholder="Choose colleagues..."
                  setFieldValue={setFieldValue}
                  tags={values.toUsers}
                  error={!!errors.toUsers}
                  suggestions={usersToTagEntity(activeUsers, values.toUsers)}
                />
              </PanelFormInput>
              <CreateFeedbackRequestFormQuestions />
            </div>
            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </GreyContainer>
  );
};

export default CreateFeedbackRequestForm;
