import React from "react";
import PropTypes from "prop-types";
import "./StatementSection.scss";

const StatementSection = ({ children }) => {
  return <div className="statement-section">{children}</div>;
};

StatementSection.propTypes = {
  children: PropTypes.node,
};

export default StatementSection;
