import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as ListIcon } from "../../assets/icons/sprint-list.svg";
import "./EmptyListBadge.scss";

const EmptyListBadge = ({ text = "Empty" }) => (
  <div className="empty-list">
    <div className="empty-list-badge">
      <ListIcon />
    </div>
    <span className="empty-list-text">{text}</span>
  </div>
);

EmptyListBadge.propTypes = {
  text: PropTypes.string,
};

export default EmptyListBadge;
