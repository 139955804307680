import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as HappyIcon } from "../../assets/icons/emojis/happy.svg";
import { ReactComponent as MehIcon } from "../../assets/icons/emojis/meh.svg";
import { ReactComponent as SadIcon } from "../../assets/icons/emojis/sad.svg";
import { ReactComponent as SmileIcon } from "../../assets/icons/emojis/smile.svg";
import { ReactComponent as VSadIcon } from "../../assets/icons/emojis/vsad.svg";
import "./PersonalMoodContainer.scss";

const PersonalMoodContainer = ({ moodAverage }) => {
  const getEmoji = () => {
    switch (Math.round(moodAverage)) {
      case 1:
        return <VSadIcon />;
      case 2:
        return <SadIcon />;
      case 3:
        return <MehIcon />;
      case 4:
        return <SmileIcon />;
      case 5:
        return <HappyIcon />;
      default:
        return null;
    }
  };

  return (
    <div className="panel personal-mood-container">
      {getEmoji()}
      <span className="personal-mood-container-score">
        {<strong>{+moodAverage.toFixed(2)}</strong>}
        /5
      </span>
    </div>
  );
};

PersonalMoodContainer.propTypes = {
  moodAverage: PropTypes.number.isRequired,
};

export default PersonalMoodContainer;
