import React from "react";
import PropTypes from "prop-types";
import { Dialog, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import "./Modal.scss";

const Modal = (props) => {
  const {
    isOpen,
    onClose,
    hasEscape = true,
    children,
    className = null,
  } = props;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      canEscapeKeyClose
      className={className}
    >
      {hasEscape && (
        <Icon
          icon={IconNames.CROSS}
          iconSize={Icon.SIZE_LARGE}
          className="modal-escape"
          onClick={onClose}
        />
      )}
      {children}
    </Dialog>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  cross: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  className: PropTypes.string,
};

export default Modal;
