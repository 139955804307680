import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from "react-router-dom";
import { Spinner } from "@blueprintjs/core";
import "./IconButton.scss";

const IconButton = ({
  size = "medium",
  children,
  to = "",
  disabled = false,
  isLoading = false,
  text,
  icon,
  className = "",
  secondary = false,
  invisible = false,
  type = "button",
  onClick = () => {},
  ...props
}) => {
  const classNames = cx("icon-button", className, {
    "icon-button-no-text": !text,
    "icon-button-xsmall": size === "xsmall",
    "icon-button-small": size === "small",
    "icon-button-no-text-xsmall": size === "xsmall" && !text,
    "icon-button-no-text-small": size === "small" && !text,
    "icon-button-large": size === "large",
    "icon-button-no-text-large": size === "large" && !text,
    "icon-button-secondary": secondary,
    "icon-button-invisible": invisible,
  });

  if (to && !disabled) {
    return (
      <Link to={to} className={`${classNames}`}>
        {icon} {text && <span>{text}</span>}
      </Link>
    );
  }

  return (
    <button
      disabled={disabled}
      className={classNames}
      onClick={onClick}
      type={type}
      {...props}
    >
      {isLoading ? <Spinner /> : icon} {text && <span>{text}</span>}
    </button>
  );
};

IconButton.propTypes = {
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large"]),
  secondary: PropTypes.bool,
  invisible: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
  text: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
};

export default IconButton;
