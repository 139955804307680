import React, { useState } from "react";
import PropTypes from "prop-types";
import FeedbackPanelInfo from "../../FeedbackPanel/FeedbackPanelInfo";
import Tag from "../../../Tag";
import Button from "../../../Button";
import FeedbackRequestPanelQuestions from "../../FeedbackRequestPanelQuestions";
import FeedbackRequestAnswerForm from "./FeedbackRequestAnswerForm";
import BlurredCollapse from "../../../BlurredCollapse";
import "./ReceivedFeedbackRequestPanel.scss";

const FeedbackRequestPanel = (props) => {
  const { fromName, date, questions, requestId, isViewed } = props;

  const [isOnAnswer, setIsOnAnswer] = useState(false);

  return (
    <BlurredCollapse className="panel received-feedback-request-panel">
      <FeedbackPanelInfo fromName={fromName} isViewed={isViewed} date={date} />
      {isOnAnswer ? (
        <FeedbackRequestAnswerForm
          requestId={requestId}
          questions={questions}
          onCancel={() => setIsOnAnswer(false)}
        />
      ) : (
        <FeedbackRequestPanelQuestions questions={questions} />
      )}
      <Tag className="received-feedback-request-panel-tag" color="yellow">
        Request
      </Tag>
      <div className="received-feedback-request-panel-actions">
        {!isOnAnswer && (
          <Button size="small" onClick={() => setIsOnAnswer(true)}>
            Answer
          </Button>
        )}
      </div>
    </BlurredCollapse>
  );
};

FeedbackRequestPanel.propTypes = {
  fromName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  requestId: PropTypes.number.isRequired,
  isViewed: PropTypes.bool,
};

export default FeedbackRequestPanel;
