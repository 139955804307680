import React from "react";
import PropTypes from "prop-types";

const Video = ({ className = null, src, type = "video/mp4" }) => (
  <video className={className} controls>
    <source src={src} type={type} />
    Your browser does not support the video tag.
  </video>
);

Video.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default Video;
