import React from "react";
import PropTypes from "prop-types";
import "./Article.scss";

const Article = ({ children }) => (
  <article className="article">{children}</article>
);

Article.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default Article;
