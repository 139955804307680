import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, FieldArray, Field } from "formik";
import { array, object, string } from "yup";
import { Intent } from "@blueprintjs/core";
import axios from "axios";
import Modal from "../../../Modal";
import Button from "../../../Button";
import IconButton from "../../../IconButton";
import TextInput from "../../../TextInput";
import Toaster from "../../../Toaster";
import { ReactComponent as AddIcon } from "../../../../assets/icons/add.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import _fetch from "../../../../utils/fetch";
import { getAllProjectCompetences } from "../../../../actions/getAllProjectCompetences";
import "./EditProjectCompetencesModal.scss";

const validationSchema = object().shape({
  competences: array()
    .of(object({ competenceName: string().min(1).required() }))
    .required(),
});

const EditProjectCompetencesModal = ({ isOpen, onClose, projectId }) => {
  const dispatch = useDispatch();

  const [autofocus, setAutofocus] = useState(false);

  const allProjectCompetences = useSelector(
    ({ allProjectCompetences }) => allProjectCompetences.payload.data
  );

  const setInitialValues = () => ({
    competences: allProjectCompetences.filter(
      (competence) => competence.competenceType === 1
    ),
  });

  const handleSubmit = (values) => {
    const { competencesToDelete, competencesToAdd } = values.competences.reduce(
      ({ competencesToDelete, competencesToAdd }, competence) => {
        if (competence.toRemove) {
          competencesToDelete.push(competence);
        } else if (!competence.competenceId) {
          competencesToAdd.push(competence);
        }

        return { competencesToDelete, competencesToAdd };
      },
      {
        competencesToDelete: [],
        competencesToAdd: [],
      }
    );

    const postCall = _fetch(`api/Projects/${projectId}/Competences`, {
      method: "POST",
      data: competencesToAdd.map((competence) => competence.competenceName),
    });

    const deleteCall = _fetch(`api/Projects/${projectId}/Competences`, {
      method: "DELETE",
      data: competencesToDelete.map((competence) => competence.competenceId),
    });

    axios
      .all([postCall, deleteCall])
      .then(
        axios.spread((...responses) => {
          const message = {
            message: "Competence changes has been saved",
            intent: Intent.SUCCESS,
          };

          if (responses.find((res) => !res.success)) {
            message.message = "An error has occured";
            message.intent = Intent.DANGER;
          } else {
            dispatch(getAllProjectCompetences(projectId));
            onClose();
          }
          Toaster.show(message);
        })
      )
      .catch(() => {
        Toaster.show({
          message: "An error has occured",
          intent: Intent.DANGER,
        });
      });
  };

  const handleAddCompetence = (arrayHelpers) => {
    arrayHelpers.push({
      competenceId: null,
      competenceName: "",
    });
    setAutofocus(true);
  };

  const handleRemoveCompetence = (
    index,
    value,
    arrayHelpers,
    isNew,
    setFieldValue,
    fieldName
  ) => {
    isNew ? arrayHelpers.remove(index) : setFieldValue(fieldName, value);
  };

  return (
    <Modal
      className="edit-project-competences-modal"
      isOpen={isOpen}
      onClose={onClose}
    >
      <h2>Edit project competences</h2>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={setInitialValues()}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, isSubmitting, setFieldValue }) => {
          const isListEmpty = values.competences.length === 0;

          return (
            <Form>
              <FieldArray
                name="competences"
                render={(arrayHelpers) => (
                  <>
                    <div
                      className={cx(
                        "edit-project-competences-modal-competences",
                        {
                          "edit-project-competences-modal-competences-empty": isListEmpty,
                        }
                      )}
                    >
                      {values.competences.map((competence, index) => {
                        const toRemove = competence.toRemove;

                        return (
                          <div
                            className="edit-project-competences-modal-competence"
                            key={`editatable_competence_${index}`}
                          >
                            <Field
                              name={`${arrayHelpers.name}.${index}.competenceName`}
                            >
                              {({ field }) => (
                                <TextInput
                                  autoFocus={autofocus}
                                  disabled={!!competence.competenceId}
                                  removed={toRemove}
                                  error={!!errors?.competences?.[index]}
                                  {...field}
                                >
                                  <IconButton
                                    size="xsmall"
                                    secondary
                                    icon={
                                      toRemove ? <AddIcon /> : <DeleteIcon />
                                    }
                                    onClick={() =>
                                      handleRemoveCompetence(
                                        index,
                                        !toRemove,
                                        arrayHelpers,
                                        !competence.competenceId,
                                        setFieldValue,
                                        `${arrayHelpers.name}.${index}.toRemove`
                                      )
                                    }
                                  />
                                </TextInput>
                              )}
                            </Field>
                          </div>
                        );
                      })}
                    </div>
                    <Button
                      className={cx(
                        "edit-project-competences-modal-add-button",
                        {
                          "edit-project-competences-modal-add-button-empty": isListEmpty,
                        }
                      )}
                      secondary
                      size="small"
                      onClick={() => handleAddCompetence(arrayHelpers)}
                    >
                      <AddIcon /> Add a new competence
                    </Button>
                  </>
                )}
              />
              <Button
                className="edit-project-competences-modal-submit-button"
                disabled={isSubmitting}
                type="submit"
              >
                Done
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

EditProjectCompetencesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default EditProjectCompetencesModal;
