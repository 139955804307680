import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setUserProjectsInit = () => {
  return {
    type: "GET_USER_PROJECTS_INIT",
  };
};

const setFetchedUserProjectsLoading = () => {
  return {
    type: "GET_USER_PROJECTS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedUserProjects = (response) => {
  return {
    type: "GET_USER_PROJECTS",
    payload: response.data,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedUserProjectsError = () => {
  return {
    type: "GET_USER_PROJECTS_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getUserProjects = (channelId) => {
  return (dispatch) => {
    dispatch(setFetchedUserProjectsLoading());
    _fetch(`api/UserProjects/${channelId}`)
      .then((response) => {
        if (response.success) {
          dispatch(setFetchedUserProjects(response));
        } else {
          dispatch(setFetchedUserProjectsError());
        }
      })
      .catch(() => dispatch(setFetchedUserProjectsError()));
  };
};

export const resetUserProjects = () => {
  return (dispatch) => {
    dispatch(setUserProjectsInit());
  };
};
