import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as msTeams from "@microsoft/teams-js";
import ProjectsContainer from "../../components/ProjectsContainer";
import {
  getUserProjects,
  resetUserProjects,
} from "../../actions/getUserProjects";
import { setPageTitle } from "../../actions/setPageTitle";
import { STATUSES } from "../../constants/statuses";

export default function Projects() {
  const dispatch = useDispatch();

  const userProjects = useSelector(({ userProjects }) => userProjects);

  useEffect(() => {
    dispatch(setPageTitle({ text: "Choose a project", backPath: "/" }));

    msTeams.initialize();
    msTeams.getContext(({ channelId }) => {
      dispatch(getUserProjects(channelId));
    });

    return () => dispatch(resetUserProjects());
  }, [dispatch]);

  if (userProjects.status === STATUSES.ERROR) {
    return <h2>Something went wrong :(</h2>;
  }

  return <ProjectsContainer />;
}
