import { combineReducers } from "redux";
import userPersonalMood from "./userPersonalMood";
import userEvaluations from "./userEvaluations";
import userProjects from "./userProjects";
import userProject from "./userProject";
import userComments from "./userComments";
import project from "./project";
import retroComments from "./retroComments";
import teamCompetencesAverages from "./teamCompetencesAverages";
import mandatoryUser from "./mandatoryUser";
import userEvaluation from "./userEvaluation";
import userCompetencesAverages from "./userCompetencesAverages";
import projectSprints from "./projectSprints";
import sprint from "./sprint";
import teamsConfigInfo from "./teamsConfigInfo";
import activeUsers from "./activeUsers";
import receivedFeedbacks from "./receivedFeedbacks";
import givenFeedbacks from "./givenFeedbacks";
import notifications from "./notifications";
import projectUsers from "./projectUsers";
import selfUser from "./selfUser";
import sprintUsers from "./sprintUsers";
import ccFeedbacks from "./ccFeedbacks";
import receivedRequestedFeedbacks from "./receivedRequestedFeedbacks";
import receivedFeedbackRequests from "./receivedFeedbackRequests";
import sprintParticipantCount from "./sprintParticipantCount";
import allProjectCompetences from "./allProjectCompetences";
import latestSprint from "./latestSprint";
import pageTitle from "./pageTitle";
import collapsedRetroFormComments from "./collapsedRetroFormComments";
import collapsedRetroReviewComments from "./collapsedRetroReviewComments";
import projectListTab from "./projectListTab";
import feedbackTab from "./feedbackTab";
import givenFeedbackRequests from "./givenFeedbackRequests";
import receivedFeedbackAnswers from "./receivedFeedbackAnswers";
import givenFeedbackAnswers from "./givenFeedbackAnswers";
import feedbacksToExcelExport from "./feedbacksToExcelExport";

export default combineReducers({
  userPersonalMood,
  userEvaluations,
  userProjects,
  userProject,
  userComments,
  project,
  retroComments,
  teamCompetencesAverages,
  mandatoryUser,
  userEvaluation,
  userCompetencesAverages,
  projectSprints,
  sprint,
  teamsConfigInfo,
  activeUsers,
  receivedFeedbacks,
  givenFeedbacks,
  notifications,
  projectUsers,
  selfUser,
  sprintUsers,
  ccFeedbacks,
  receivedRequestedFeedbacks,
  receivedFeedbackRequests,
  sprintParticipantCount,
  allProjectCompetences,
  latestSprint,
  pageTitle,
  collapsedRetroFormComments,
  collapsedRetroReviewComments,
  projectListTab,
  feedbackTab,
  givenFeedbackRequests,
  receivedFeedbackAnswers,
  givenFeedbackAnswers,
  feedbacksToExcelExport,
});
