import React from "react";
import * as msTeams from "@microsoft/teams-js";
import { ReactComponent as LoginImage } from "../../assets/sms.svg";
import "./LoginPage.scss";

export default function LoginPage() {
  msTeams.initialize();

  //opens up a popup window and redirects to login url
  const login = () => {
    msTeams.authentication.authenticate({
      url: window.location.origin + "/login-start",
      width: 600,
      height: 535,
      successCallback: (result) => {
        let tokenResult = JSON.parse(result);

        const redirectedFrom = localStorage.getItem("auth.redirectedFrom");
        localStorage.removeItem("auth.redirectedFrom");

        localStorage.setItem(
          "auth.retroWebApiAccessToken",
          tokenResult.accessToken
        );

        if (typeof redirectedFrom === "string") {
          window.location.href = redirectedFrom;
        } else {
          window.location.href = window.location.origin;
        }
      },
      failureCallback: () => {},
    });
  };

  return (
    <>
      <div className="login-page">
        <LoginImage className="login-image" />
        <div className="login-button">
          <button onClick={login} className="login-button-button">
            Login
          </button>
        </div>
      </div>
    </>
  );
}
