import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import GreyContainer from "../GreyContainer";
import PersonalMoodContainer from "../PersonalMoodContainer";
import TeamAverageCompetences from "./TeamAverageCompetences";
import AdminCompleteSprintForm from "./AdminCompleteSprintForm";
import "./AdminCompleteSprintContainer.scss";

const AdminCompleteSprintContainer = ({ projectId }) => {
  const sprint = useSelector(({ userProject }) => userProject.payload.sprint);
  const { competences, teamAverages } = useSelector(
    ({ teamCompetencesAverages }) => teamCompetencesAverages.payload
  );

  return (
    <div className="admin-complete-sprint-container">
      <div className="admin-complete-sprint-container-top">
        <GreyContainer title="Team mood" blueTitle>
          <PersonalMoodContainer moodAverage={sprint.moodAverage} />
        </GreyContainer>
        <GreyContainer title="Team evaluation" blueTitle>
          <TeamAverageCompetences
            competences={competences}
            chartData={[teamAverages]}
          />
        </GreyContainer>
      </div>
      <div className="admin-complete-sprint-container-form">
        <AdminCompleteSprintForm projectId={projectId} />
      </div>
    </div>
  );
};

AdminCompleteSprintContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default AdminCompleteSprintContainer;
