import { STATUSES } from "../constants/statuses";

const initialState = {
  payload: null,
  status: STATUSES.INIT,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER_PROJECT":
      return action;
    case "GET_USER_PROJECT_INIT":
      return initialState;
    case "GET_USER_PROJECT_ERROR":
      return Object.assign({}, state, {
        ...action,
      });
    case "GET_USER_PROJECT_LOADING":
      return Object.assign({}, state, {
        ...action,
      });
    default:
      return state;
  }
};
