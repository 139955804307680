export const usersToSearchOptionsEntity = (users, withNone = true) => {
  const options = users.map((user) => ({
    label: user.fullName,
    value: user.userId,
  }));

  if (withNone) {
    options.unshift({ label: "None", value: "" });
  }

  return options;
};
