import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setReceivedFeedbacksInit = () => {
  return {
    type: "GET_RECEIVED_FEEDBACKS_INIT",
  };
};

const setFetchedReceivedFeedbacksLoading = () => {
  return {
    type: "GET_RECEIVED_FEEDBACKS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedReceivedFeedbacks = (response) => {
  return {
    type: "GET_RECEIVED_FEEDBACKS",
    payload: response.data,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedReceivedFeedbacksError = () => {
  return {
    type: "GET_RECEIVED_FEEDBACKS_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getReceivedFeedbacks = () => {
  return (dispatch) => {
    dispatch(setFetchedReceivedFeedbacksLoading());
    _fetch("api/Feedbacks?received=true")
      .then((response) => {
        if (response.success) {
          dispatch(setFetchedReceivedFeedbacks(response));
        } else {
          dispatch(setFetchedReceivedFeedbacksError());
        }
      })
      .catch(() => dispatch(setFetchedReceivedFeedbacksError()));
  };
};

export const resetReceivedFeedbacks = () => {
  return (dispatch) => {
    dispatch(setReceivedFeedbacksInit());
  };
};
