import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import FeedbackPanel from "../FeedbackPanel";
import ReceivedFeedbackRequestPanel from "./ReceivedFeedbackRequestPanel";
import FeedbackRequestAnswerPanel from "../FeedbackRequestAnswerPanel";
import _fetch from "../../../utils/fetch";
import { getNotifications } from "../../../actions/getNotifications";
import EmptyListBadge from "../../EmptyListBadge";

const ReceivedFeedbacks = ({ hasNotification, filters }) => {
  const dispatch = useDispatch();

  const receivedFeedbacks = useSelector(
    ({ receivedFeedbacks }) => receivedFeedbacks.payload
  );
  const receivedFeedbackRequests = useSelector(
    ({ receivedFeedbackRequests }) => receivedFeedbackRequests.payload
  );
  const receivedFeedbackAnswers = useSelector(
    ({ receivedFeedbackAnswers }) => receivedFeedbackAnswers.payload
  );
  const feedbacksToExcelExport = useSelector(
    ({ feedbacksToExcelExport }) => feedbacksToExcelExport
  );

  useEffect(() => {
    if (hasNotification) {
      const formData = {
        unreadFeedback: true,
        unreadRequestedFeedback: true,
        unreadAnsweredFeedback: true,
      };
      _fetch("api/notifications", { method: "PUT", data: formData }).then(() =>
        dispatch(getNotifications())
      );
    }
  }, [dispatch, hasNotification]);

  const sortFeedbackComponents = (array) =>
    array.sort((a, b) => new Date(b.props.date) - new Date(a.props.date));

  const receivedFeedbacksPanels = filters.feedback
    ? []
    : receivedFeedbacks.map((feedback, index) => (
        <FeedbackPanel
          key={`received_feedback_panel_${index}`}
          fromName={feedback.fromFullName}
          date={feedback.feedbackDate}
          feedback={feedback.feedback}
          feedbackId={feedback.feedbackId}
          isViewed={feedback.viewed}
          isMarked={feedback.marked}
        />
      ));

  const receivedFeedbackRequestsPanels = filters.request
    ? []
    : receivedFeedbackRequests.map((request, index) => (
        <ReceivedFeedbackRequestPanel
          key={`received_feedback_request_panel_${index}`}
          fromName={request.fromFullName}
          date={request.feedbackDate}
          requestId={request.feedbackRequestId}
          questions={request.feedbackRequestData.map((question) => ({
            question: question.question,
            isEvaluationRequired: question.isEvaluationRequired,
            feedbackRequestDataId: question.feedbackRequestDataId,
          }))}
          isViewed={request.requestViewed}
        />
      ));

  const receivedFeedbackAnswersPanels = filters.answer
    ? []
    : receivedFeedbackAnswers.map((answer, index) => (
        <FeedbackRequestAnswerPanel
          key={`received_feedback_answer_${index}`}
          fromName={answer.toFullName}
          date={answer.feedbackDate}
          data={answer.feedbackRequestData.map((item) => ({
            question: item.question,
            answer: item.answer,
            isEvaluationRequired: item.isEvaluationRequired,
            feedbackRequestDataId: item.feedbackRequestDataId,
            evaluation: item.evaluation,
          }))}
          isViewed={answer.answerViewed}
          selectable={feedbacksToExcelExport.selectable}
          feedbackRequestId={answer.feedbackRequestId}
        />
      ));

  const allFeedbacks = [
    ...receivedFeedbacksPanels,
    ...receivedFeedbackRequestsPanels,
    ...receivedFeedbackAnswersPanels,
  ];

  if (allFeedbacks.length > 0) {
    return sortFeedbackComponents(allFeedbacks);
  }

  return <EmptyListBadge />;
};

ReceivedFeedbacks.propTypes = {
  hasNotification: PropTypes.bool,
  filters: PropTypes.object.isRequired,
};

export default ReceivedFeedbacks;
