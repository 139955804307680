import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setAllProjectCompetencesInit = () => {
  return {
    type: "GET_ALL_PROJECT_COMPETENCES_INIT",
  };
};

const setFetchedAllProjectCompetencesLoading = () => {
  return {
    type: "GET_ALL_PROJECT_COMPETENCES_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedAllProjectCompetences = (response) => {
  return {
    type: "GET_ALL_PROJECT_COMPETENCES",
    payload: response,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedAllProjectCompetencesError = () => {
  return {
    type: "GET_ALL_PROJECT_COMPETENCES_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getAllProjectCompetences = (projectId) => {
  return (dispatch) => {
    dispatch(setFetchedAllProjectCompetencesLoading());
    _fetch(`api/competences/${projectId}`)
      .then((response) => {
        if (response.success) {
          dispatch(setFetchedAllProjectCompetences(response));
        } else {
          dispatch(setFetchedAllProjectCompetencesError());
        }
      })
      .catch(() => dispatch(setFetchedAllProjectCompetencesError()));
  };
};

export const resetAllProjectCompetences = () => {
  return (dispatch) => {
    dispatch(setAllProjectCompetencesInit());
  };
};
