import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import RetroForm from "../../components/RetroForm";
import {
  getUserPersonalMood,
  resetUserPersonalMood,
} from "../../actions/getUserPersonalMood";
import { getUserProject, resetUserProject } from "../../actions/getUserProject";
import {
  getUserEvaluations,
  resetUserEvaluations,
} from "../../actions/getUserEvaluations";
import {
  getUserComments,
  resetUserComments,
} from "../../actions/getUserComments";
import { getProject, resetProject } from "../../actions/getProject";
import {
  getMandatoryUser,
  resetMandatoryUser,
} from "../../actions/getMandatoryUser";
import { setPageTitle } from "../../actions/setPageTitle";
import { getSprintUsers, resetSprintUsers } from "../../actions/getSprintUsers";
import { getSelfUser, resetSelfUser } from "../../actions/getSelfUser";
import { STATUSES } from "../../constants/statuses";
import { ROUTES } from "../../constants/routes";
import { hasErrors, areInitiallyLoading } from "../../utils/pageStates";
import Spinner from "../../components/Spinner";

export default function Retro(props) {
  const dispatch = useDispatch();

  const userPersonalMood = useSelector(
    ({ userPersonalMood }) => userPersonalMood
  );
  const userEvaluations = useSelector(({ userEvaluations }) => userEvaluations);
  const userComments = useSelector(({ userComments }) => userComments);
  const userProject = useSelector(({ userProject }) => userProject);
  const project = useSelector(({ project }) => project);
  const mandatoryUser = useSelector(({ mandatoryUser }) => mandatoryUser);
  const selfUser = useSelector(({ selfUser }) => selfUser);
  const sprintUsers = useSelector(({ sprintUsers }) => sprintUsers);

  const projectId = props.match.params.projectId;

  const [isSprintValid, setIsSprintValid] = useState(true);

  const fetches = [
    userPersonalMood,
    userEvaluations,
    userComments,
    userProject,
    project,
    mandatoryUser,
    selfUser,
    sprintUsers,
  ];

  useEffect(() => {
    dispatch(
      setPageTitle({
        text: "Sprint evaluation",
        backPath: ROUTES.RETRO_HOME.path,
      })
    );
    dispatch(getUserProject(projectId));

    return () => {
      dispatch(resetUserProject());
    };
  }, [dispatch, projectId]);

  useEffect(() => {
    if (userProject.status === STATUSES.SUCCESS) {
      const { sprint } = userProject.payload;

      if (sprint && sprint.sprintStatus === 0) {
        dispatch(getUserPersonalMood(sprint.sprintId));
        dispatch(getUserEvaluations(sprint.sprintId));
        dispatch(getUserComments(sprint.sprintId));
        dispatch(getSprintUsers(sprint.sprintId));
        dispatch(getProject(projectId));
        dispatch(getMandatoryUser(projectId));
        dispatch(getSelfUser());
      } else {
        setIsSprintValid(false);
      }
    }
    return () => {
      dispatch(resetUserPersonalMood());
      dispatch(resetUserEvaluations());
      dispatch(resetUserComments());
      dispatch(resetSprintUsers());
      dispatch(resetProject());
      dispatch(resetMandatoryUser());
      dispatch(resetSelfUser());
    };
  }, [dispatch, userProject, projectId]);

  const renderForm = () => {
    const project = userProject.payload;

    if (
      mandatoryUser.payload &&
      mandatoryUser.payload.isUserInSprint === false
    ) {
      return (
        <h2 className="project-info">
          You are not participating in this sprint
        </h2>
      );
    }

    if (!project || !project.projectId) {
      return <h2>You are not participating in this project</h2>;
    }

    if (
      project.sprint.retroStartDate &&
      new Date(project.sprint.retroStartDate) >= new Date()
    ) {
      return <h2>Retro has not started yet!</h2>;
    }

    return <RetroForm projectId={projectId} />;
  };

  if (!isSprintValid) {
    return <h2>This sprint can't be evaluated yet</h2>;
  }

  if (hasErrors(fetches)) {
    return <h2>Something went wrong :(</h2>;
  }

  if (areInitiallyLoading(fetches)) {
    return <Spinner />;
  }

  return renderForm();
}
