import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useSelector } from "react-redux";
import { object, string, date } from "yup";
import GreyContainer from "../../GreyContainer";
import PanelFormInput from "../../PanelFormInput";
import TextInput from "../../TextInput";
import DateInput from "../../DateInput";
import Button from "../../Button";
import _fetch from "../../../utils/fetch";
import formatDate from "../../../utils/formatDate";
import Toaster from "../../Toaster";
import { ROUTES } from "../../../constants/routes";
import { Intent } from "@blueprintjs/core";

const validationSchema = object().shape({
  sprintName: string().min(1).required(),
  sprintStartDate: date().required(),
  sprintEndDate: date().required(),
  retroStartDate: date().required(),
});

const AdminEditSprintForm = () => {
  const [redirectToRetroMain, setRedirectToRetroMain] = useState(false);

  const sprint = useSelector(({ userProject }) => userProject.payload.sprint);

  const setInitialValues = () => ({
    sprintName: sprint.sprintName,
    sprintStartDate: new Date(sprint.startDate),
    sprintEndDate: new Date(sprint.endDate),
    retroStartDate: new Date(sprint.retroStartDate),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = {
      sprintName: values.sprintName,
      startDate: formatDate(values.sprintStartDate),
      endDate: formatDate(values.sprintEndDate),
      retroStartDate: formatDate(values.retroStartDate),
    };

    const { status, success } = await _fetch(
      `api/sprint/${sprint.sprintId}/name-and-dates`,
      {
        method: "PATCH",
        data: formData,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const message = { message: "Sprint info updated", intent: Intent.SUCCESS };

    if (status >= 400 || !success) {
      message.message = "An error has occurred";
      message.intent = Intent.DANGER;
    }

    setSubmitting(false);
    Toaster.show(message);

    if (success) {
      setRedirectToRetroMain(true);
    }
  };

  if (redirectToRetroMain) {
    return <Redirect to={ROUTES.RETRO_HOME.path} />;
  }

  return (
    <GreyContainer className="admin-start-sprint-container">
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={setInitialValues()}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, setFieldValue, isSubmitting }) => {
          return (
            <Form className="admin-start-sprint-form">
              <div className="panel admin-start-sprint-form-container">
                <PanelFormInput title="Retro name">
                  <Field
                    component={TextInput}
                    name="sprintName"
                    value={values.sprintName}
                    placeholder="Insert retro name..."
                    error={!!errors.sprintName}
                  />
                </PanelFormInput>
                <PanelFormInput title="Sprint period" horizontal wrapWidth>
                  <Field
                    text="From"
                    maxDate={values.sprintEndDate}
                    component={DateInput}
                    name="sprintStartDate"
                    setFieldValue={setFieldValue}
                    error={!!errors.sprintStartDate}
                  />
                  <Field
                    text="To"
                    minDate={values.sprintStartDate}
                    component={DateInput}
                    name="sprintEndDate"
                    setFieldValue={setFieldValue}
                    error={!!errors.sprintEndDate}
                  />
                </PanelFormInput>
                <PanelFormInput title="Retro start date" wrapWidth>
                  <Field
                    text="From"
                    component={DateInput}
                    name="retroStartDate"
                    setFieldValue={setFieldValue}
                    error={!!errors.retroStartDate}
                  />
                </PanelFormInput>
              </div>
              <Button type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </Form>
          );
        }}
      </Formik>
    </GreyContainer>
  );
};

export default AdminEditSprintForm;
