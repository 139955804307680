import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getProjectSprints,
  resetProjectSprints,
} from "../../actions/getProjectSprints";
import { hasErrors, areInitiallyLoading } from "../../utils/pageStates";
import Spinner from "../../components/Spinner";
import SprintsContainer from "../../components/SprintsContainer";
import { setPageTitle } from "../../actions/setPageTitle";
import { ROUTES } from "../../constants/routes";
import { getProject, resetProject } from "../../actions/getProject";

const Sprints = ({ match }) => {
  const dispatch = useDispatch();

  const projectId = match.params.projectId;

  const projectSprints = useSelector(({ projectSprints }) => projectSprints);
  const project = useSelector(({ project }) => project);

  const fetches = [projectSprints, project];

  useEffect(() => {
    dispatch(getProjectSprints(projectId, 2));
    dispatch(getProject(projectId));

    return () => {
      dispatch(resetProjectSprints());
      dispatch(resetProject());
    };
  }, [dispatch, projectId]);

  useEffect(() => {
    if (project.payload) {
      dispatch(
        setPageTitle({
          text: project.payload.projectName,
          backPath: ROUTES.RETRO_HOME.path,
        })
      );
    }
  }, [dispatch, project]);

  if (areInitiallyLoading(fetches)) {
    return <Spinner />;
  }

  if (hasErrors(fetches)) {
    return <h2>An error has occured</h2>;
  }

  return <SprintsContainer />;
};

export default Sprints;
