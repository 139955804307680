import { STATUSES } from "../constants/statuses";

const initialState = {
  payload: {
    data: {
      unreadFeedback: 0,
      unreadMentionedInCcFeedback: 0,
      unreadRequestedFeedback: 0,
      unreadAnsweredFeedback: 0,
      unreadFeedbackRequestTotal: 0,
      unreadFeedbackTotal: 0,
      unreadTotal: 0,
    },
  },
  status: STATUSES.INIT,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_NOTIFICATIONS":
      return action;
    case "GET_NOTIFICATIONS_INIT":
      return initialState;
    case "GET_NOTIFICATIONS_ERROR":
      return Object.assign({}, state, {
        ...action,
      });
    case "GET_NOTIFICATIONS_LOADING":
      return Object.assign({}, state, {
        ...action,
      });
    default:
      return state;
  }
};
