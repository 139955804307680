import React from "react";
import * as msTeams from "@microsoft/teams-js";

export default function LoginEnd() {
  msTeams.initialize();
  localStorage.removeItem("auth.error");

  const getHashParameters = () => {
    let hashParams = {};
    window.location.hash
      .substr(1)
      .split("&")
      .forEach(function (item) {
        let s = item.split("="),
          k = s[0],
          v = s[1] && decodeURIComponent(s[1]);
        hashParams[k] = v;
      });
    return hashParams;
  };

  let hashParams = getHashParameters();

  if (hashParams["error"]) {
    localStorage.setItem("auth.error", JSON.stringify(hashParams));
    msTeams.authentication.notifyFailure(hashParams["error"]);
  } else if (hashParams["access_token"]) {
    let expectedState = localStorage.getItem("auth.state");
    if (expectedState !== hashParams["state"]) {
      // state does not match. Someone tampered with the connection?
      localStorage.setItem("auth.error", JSON.stringify(hashParams));
      msTeams.authentication.notifyFailure("StateDoesNotMatch");
    } else {
      let result =
        JSON.stringify({
          idToken: hashParams["id_token"],
          accessToken: hashParams["access_token"],
          tokenType: hashParams["token_type"],
          expiresIn: hashParams["expires_in"],
        });

      msTeams.authentication.notifySuccess(result);
    }
  } else {
    // Unexpected condition: hash does not containt error or access_token parameter
    localStorage.setItem("auth.error", JSON.stringify(hashParams));
    msTeams.authentication.notifyFailure("UnexpectedFailure");
  }

  return <></>;
}
