import React, { useEffect } from "react";
import PropTypes from "prop-types";
import IconButton from "../IconButton";
import { ReactComponent as MinusIcon } from "../../assets/icons/minus.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/add.svg";
import "./NumberInput.scss";

const NumberInput = ({
  field,
  setFieldValue,
  minValue = Number.MIN_SAFE_INTEGER,
  maxValue = Number.MAX_SAFE_INTEGER,
  ...props
}) => {
  const { value, name } = field;

  useEffect(() => {
    if (value > maxValue) {
      setFieldValue(name, maxValue);
    }
  }, [maxValue, value, setFieldValue, name]);

  useEffect(() => {
    if (value < minValue) {
      setFieldValue(name, minValue);
    }
  }, [minValue, value, setFieldValue, name]);

  const onSubstraction = () => {
    setFieldValue(name, value - 1);
  };

  const onAddition = () => {
    setFieldValue(name, value + 1);
  };

  return (
    <div className="number-input">
      <IconButton
        icon={<MinusIcon />}
        onClick={onSubstraction}
        disabled={value <= minValue}
      />
      <input {...field} {...props} type="number" />
      <span className="number-input-value">{value}</span>
      <IconButton
        icon={<PlusIcon />}
        onClick={onAddition}
        disabled={value >= maxValue}
      />
    </div>
  );
};

NumberInput.propTypes = {
  field: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  text: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
};

export default NumberInput;
