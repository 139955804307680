import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import "./Avatar.scss";

const Avatar = ({ children, color = "#d3d3d3", onClick = null }) => (
  <div
    style={{ backgroundColor: color }}
    className={cx("avatar", { clickable: !!onClick })}
    onClick={onClick}
  >
    <span className="avatar-text">{children}</span>
  </div>
);

Avatar.propTypes = {
  children: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export default Avatar;
