import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserProject, resetUserProject } from "../../actions/getUserProject";
import {
  getRetroComments,
  resetRetroComments,
} from "../../actions/getRetroComments";
import {
  getTeamCompetencesAverages,
  resetTeamCompetencesAverages,
} from "../../actions/getTeamCompetencesAverages";
import { setPageTitle } from "../../actions/setPageTitle";
import { STATUSES } from "../../constants/statuses";
import { hasErrors, areInitiallyLoading } from "../../utils/pageStates";
import AdminCompleteSprintContainer from "../../components/AdminCompleteSprintContainer";
import Spinner from "../../components/Spinner";
import { ROUTES } from "../../constants/routes";
import {
  getProjectUsers,
  resetProjectUsers,
} from "../../actions/getProjectUsers";

const AdminCompleteSprint = ({ match }) => {
  const dispatch = useDispatch();

  const userProject = useSelector(({ userProject }) => userProject);
  const retroComments = useSelector(({ retroComments }) => retroComments);
  const teamCompetencesAverages = useSelector(
    ({ teamCompetencesAverages }) => teamCompetencesAverages
  );
  const projectUsers = useSelector(({ projectUsers }) => projectUsers);

  const [isSprintMissing, setIsSprintMissing] = useState(false);

  const projectId = match.params.projectId;

  const fetches = [
    userProject,
    retroComments,
    teamCompetencesAverages,
    projectUsers,
  ];

  useEffect(() => {
    dispatch(
      setPageTitle({
        text: "Retro",
        backPath: ROUTES.RETRO_HOME.path,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserProject(projectId));

    return () => dispatch(resetUserProject(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (userProject.status === STATUSES.SUCCESS) {
      const { sprint } = userProject.payload;

      if (sprint && sprint.retroEndDate) {
        const { sprintId } = sprint;

        dispatch(getRetroComments(sprintId));
        dispatch(getTeamCompetencesAverages(sprintId));
        dispatch(getProjectUsers(userProject.payload.projectId));
      } else {
        setIsSprintMissing(true);
      }
    }

    return () => {
      dispatch(resetRetroComments());
      dispatch(resetTeamCompetencesAverages());
      dispatch(resetProjectUsers());
    };
  }, [dispatch, userProject]);

  if (!isSprintMissing && areInitiallyLoading(fetches)) {
    return <Spinner />;
  }

  if (isSprintMissing) {
    return <h2>Sprint doesn't exist</h2>;
  }

  if (hasErrors(fetches)) {
    return <h2>Something went wrong :(</h2>;
  }

  if (userProject.payload.sprint.sprintStatus === 1) {
    return <AdminCompleteSprintContainer projectId={projectId} />;
  }

  return <h2>This sprint can't be finished yet</h2>;
};

export default AdminCompleteSprint;
