import React, { memo, useRef } from "react";
import PropTypes from "prop-types";
import { HTMLSelect } from "@blueprintjs/core";
import RadarChart from "../../RadarChart";
import { dataToChartDataset } from "../../../utils/dataToChartDataset";
import { CHART_FILTER_OPTIONS } from "../../../constants/chart-filter-options";
import "./AveragesChart.scss";
import getColor from "../../../utils/getColor";

const AveragesChart = memo(
  (props) => {
    const { competences, allEvaluations, optionsData } = props;

    const ref = useRef();

    const initialChartData = allEvaluations.map((item) => {
      let itemData = item.data;
      if (item.filterable) {
        itemData = item.data.competenceAverages.map(
          (average) => average.averageScore
        );
      }

      return { data: itemData, legendLabel: item.legendLabel };
    });

    const evaluationsToOptionsEntity = (data) => {
      const options = [];

      const all = data.competenceAverages.length > 0 && {
        value: CHART_FILTER_OPTIONS.ALL.value,
        title: CHART_FILTER_OPTIONS.ALL.title,
      };
      if (all) {
        options.push(all);
      }

      const nonAnonymous =
        data.usersEvaluations.length > 0 &&
        data.usersEvaluations.map((item) => ({
          value: item.fullName,
          title: item.fullName,
        }));
      if (nonAnonymous) {
        options.push(...nonAnonymous);
      }

      const anonymous = data.anonymousCompetenceAverage.length > 0 && {
        value: CHART_FILTER_OPTIONS.ANONYMOUS.value,
        title: CHART_FILTER_OPTIONS.ANONYMOUS.title,
      };
      if (anonymous) {
        options.push(anonymous);
      }

      return options;
    };

    const handleSelectChange = (e) => {
      const othersEvaluations = allEvaluations[1].data;
      let itemData;

      if (e.target.value === CHART_FILTER_OPTIONS.ALL.value) {
        itemData = othersEvaluations.competenceAverages.map(
          (average) => average.averageScore
        );
      } else if (e.target.value === CHART_FILTER_OPTIONS.ANONYMOUS.value) {
        itemData = othersEvaluations.anonymousCompetenceAverage.map(
          (average) => average.averageScore
        );
      } else if (othersEvaluations.usersEvaluations.length > 0) {
        itemData = othersEvaluations.usersEvaluations
          .find((evaluation) => evaluation.fullName === e.target.value)
          .evaluationCompetences.map(
            (competence) => competence.competenceValue
          );
      }

      ref.current.data.datasets[1] = {
        label: "Others evaluations",
        data: itemData,
        backgroundColor: getColor(1, 0.1),
        borderWidth: 2,
        borderColor: getColor(1, 1),
        pointBackgroundColor: getColor(1, 1),
      };

      ref.current.update();
    };

    return (
      <div className="panel averages-chart">
        <RadarChart
          id="archive-chart"
          labels={competences}
          chartData={dataToChartDataset(initialChartData)}
          width={350}
          height={200}
          ref={ref}
        />
        {evaluationsToOptionsEntity(optionsData).length > 0 && (
          <div className="averages-chart-select-filter-container">
            <span className="averages-chart-select-filter-title">
              Others evaluations
            </span>
            <div className="bp3-select select-filter">
              <HTMLSelect onChange={handleSelectChange} defaultValue="all">
                {evaluationsToOptionsEntity(optionsData).map((item) => {
                  return (
                    <option
                      key={`evaluationsallEvaluations_filter_option_${item.value}`}
                      value={item.value}
                    >
                      {item.title}
                    </option>
                  );
                })}
              </HTMLSelect>
            </div>
          </div>
        )}
      </div>
    );
  },
  ({ prevProps, nextProps }) => prevProps === nextProps
);

AveragesChart.propTypes = {
  competences: PropTypes.array.isRequired,
  allEvaluations: PropTypes.array.isRequired,
  optionsData: PropTypes.object.isRequired,
};

export default AveragesChart;
