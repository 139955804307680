import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { useSelector } from "react-redux";
import Modal from "../../../../../Modal";
import SearchSelect from "../../../../../SearchSelect";
import DateInput from "../../../../../DateInput";
import Button from "../../../../../Button";
import { usersToSearchOptionsEntity } from "../../../../../../utils/usersToSearchOptionsEntity";
import "./ActionPointDetails.scss";

const ActionPointDetails = (props) => {
  const {
    isOpen,
    onClose,
    assignedUser,
    assignedDate,
    arrayHelpers,
    index,
  } = props;

  const projectUsers = useSelector(({ projectUsers }) => projectUsers.payload);

  const setInitialValues = () => ({
    assignedUser: assignedUser,
    assignedDate: assignedDate ? new Date(assignedDate) : undefined,
  });

  const handleSubmit = (values) => {
    const { form } = arrayHelpers;

    form.setFieldValue(
      `${arrayHelpers.name}.${index}.assignedUser`,
      values.assignedUser
    );

    form.setFieldValue(
      `${arrayHelpers.name}.${index}.assignedDate`,
      values.assignedDate
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={setInitialValues()}
        validate={() => {}}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="action-points-input-panel-popup">
            <h2 className="action-points-input-panel-popup-title">
              Assign info for this action
            </h2>
            <div className="action-points-input-panel-popup-actions">
              <h4>Responsible person</h4>
              <Field
                component={SearchSelect}
                autoFocus
                name="assignedUser"
                value={values.assignedUser}
                setFieldValue={setFieldValue}
                options={usersToSearchOptionsEntity(projectUsers)}
                placeholder="Choose a colleague..."
              />
              <h4>Expire date</h4>
              <Field
                component={DateInput}
                name="assignedDate"
                setFieldValue={setFieldValue}
              />
            </div>
            <Button type="submit" onClick={onClose}>
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

ActionPointDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  assignedUser: PropTypes.object,
  assignedDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  arrayHelpers: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default ActionPointDetails;
