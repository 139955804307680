import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import BackButton from "../../BackButton";
import PropTypes from "prop-types";
import "./DefaultLayout.scss";

const DefaultLayout = ({ children, title, backPath }) => {
  return (
    <>
      <Header />
      <div className="default-layout-content">
        <div className="default-layout-title-container">
          {!!backPath && (
            <div className="default-layout-back-button">
              <BackButton path={backPath} />
            </div>
          )}
          <h1 className="default-layout-title">{title}</h1>
        </div>
        {children}
      </div>
      <Footer />
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.element])
    .isRequired,
  title: PropTypes.string,
  backPath: PropTypes.string,
};

export default DefaultLayout;
