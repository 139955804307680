import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import FeedbackPanel from "../FeedbackPanel";
import GivenFedbackRequestPanel from "./GivenFeedbackRequestPanel";
import FeedbackRequestAnswerPanel from "../FeedbackRequestAnswerPanel";
import EmptyListBadge from "../../EmptyListBadge";

const GivenFeedbacks = ({ filters }) => {
  const givenFeedbacks = useSelector(
    ({ givenFeedbacks }) => givenFeedbacks.payload
  );
  const givenFeedbackRequests = useSelector(
    ({ givenFeedbackRequests }) => givenFeedbackRequests.payload
  );
  const givenFeedbackAnswers = useSelector(
    ({ givenFeedbackAnswers }) => givenFeedbackAnswers.payload
  );

  const sortFeedbackComponents = (array) =>
    array.sort((a, b) => new Date(b.props.date) - new Date(a.props.date));

  const givenFeedbacksPanels = filters.feedback
    ? []
    : givenFeedbacks.map((feedback, index) => (
        <FeedbackPanel
          key={`received_feedback_panel_${index}`}
          toName={feedback.toFullName}
          date={feedback.feedbackDate}
          feedback={feedback.feedback}
          feedbackId={feedback.feedbackId}
          isMarked={feedback.marked}
        />
      ));

  const givenFeedbackRequestsPanels = filters.request
    ? []
    : givenFeedbackRequests.map((feedback, index) => (
        <GivenFedbackRequestPanel
          key={`given_feedback_request_panel_${index}`}
          toName={feedback.toFullName}
          date={feedback.feedbackDate}
          questions={feedback.feedbackRequestData}
        />
      ));

  const givenFeedbackAnswersPanels = filters.answer
    ? []
    : givenFeedbackAnswers.map((answer, index) => (
        <FeedbackRequestAnswerPanel
          key={`given_feedback_answer_panel_${index}`}
          toName={answer.fromFullName}
          date={answer.feedbackDate}
          data={answer.feedbackRequestData}
        />
      ));

  const allFeedbacks = [
    ...givenFeedbacksPanels,
    ...givenFeedbackRequestsPanels,
    ...givenFeedbackAnswersPanels,
  ];

  if (allFeedbacks.length > 0) {
    return sortFeedbackComponents(allFeedbacks);
  }

  return <EmptyListBadge />;
};

GivenFeedbacks.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default GivenFeedbacks;
