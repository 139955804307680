import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setUserCompetencesAveragesInit = () => {
  return {
    type: "GET_USER_COMPETENCES_AVERAGES_INIT",
  };
};
const setFetchedUserCompetencesAveragesLoading = () => {
  return {
    type: "GET_USER_COMPETENCES_AVERAGES_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedUserCompetencesAverages = (response) => {
  return {
    type: "GET_USER_COMPETENCES_AVERAGES",
    payload: response,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedUserCompetencesAveragesError = () => {
  return {
    type: "GET_USER_COMPETENCES_AVERAGES_ERROR",
    status: STATUSES.ERROR,
  };
};

const responseToUserCompetenceAverages = (res) => {
  return {
    legendLabel: "Others evaluations",
    data: res.data
      ? res.data
      : {
          competenceAverages: [],
          anonymousCompetenceAverage: [],
          usersEvaluations: [],
        },
    filterable: true,
  };
};

export const getUserCompetencesAverages = (sprintId) => {
  return (dispatch) => {
    dispatch(setFetchedUserCompetencesAveragesLoading());
    _fetch(`api/Sprints/${sprintId}/user-competences-averages`)
      .then((response) => {
        if (response.success) {
          const competenceAverages = responseToUserCompetenceAverages(response);
          dispatch(setFetchedUserCompetencesAverages(competenceAverages));
        } else {
          dispatch(setFetchedUserCompetencesAveragesError());
        }
      })
      .catch(() => dispatch(setFetchedUserCompetencesAveragesError()));
  };
};

export const resetUserCompetencesAverages = () => {
  return (dispatch) => {
    dispatch(setUserCompetencesAveragesInit());
  };
};
