export const splitComments = (res) => {
  return res.data.reduce(
    (results, item) => {
      if (item.commentType === 0) {
        results.doWellComments.push({
          id: item.commentId,
          text: item.comment,
          actionPoints: item.actionPoints,
        });
      } else if (item.commentType === 1) {
        results.improveComments.push({
          id: item.commentId,
          text: item.comment,
          isStarred: item.starred,
          actionPoints: item.actionPoints,
        });
      }
      return results;
    },
    { doWellComments: [], improveComments: [] }
  );
};
