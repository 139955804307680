import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import cx from "classnames";
import "./Button.scss";

const Button = ({
  size = "medium",
  secondary = false,
  danger = false,
  to = "",
  externalTo = "",
  title = "",
  children,
  icon = null,
  disabled,
  type = "button",
  className = null,
  ...props
}) => {
  const classNames = cx("button", className, {
    "button-xsmall": size === "xsmall",
    "button-small": size === "small",
    "button-large": size === "large",
    "button-secondary": secondary,
    "button-danger": danger,
    "button-with-icon": !!icon,
  });

  if (to && !disabled) {
    return (
      <Link to={to} className={classNames}>
        {icon}
        {children}
      </Link>
    );
  }

  if (externalTo && !disabled) {
    return (
      <a title={title} href={externalTo} className={classNames}>
        {icon}
        {children}
      </a>
    );
  }

  return (
    <button disabled={disabled} className={classNames} type={type} {...props}>
      {icon}
      {children}
    </button>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large"]),
  secondary: PropTypes.bool,
  danger: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
  icon: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  externalTo: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
};

export default Button;
