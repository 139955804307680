export default function (
  state = { text: "", backPath: "" },
  { type, payload }
) {
  switch (type) {
    case "SET_PAGE_TITLE":
      return payload;
    default:
      return state;
  }
}
