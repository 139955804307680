import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { object, string } from "yup";
import { Intent } from "@blueprintjs/core";
import Toaster from "../../Toaster";
import _fetch from "../../../utils/fetch";
import Button from "../../Button";
// import RadarChart from "../../RadarChart";
import Modal from "../../Modal";
import TextInput from "../../TextInput";
import InfoBadge from "../../InfoBadge";
// import { dataToChartDataset } from "../../../utils/dataToChartDataset";
import "./ConfluenceExport.scss";

const validationSchema = object().shape({
  confluenceUrl: string().url().required(),
  username: string().min(1).required(),
  password: string().min(1).required(),
  spaceKey: string().min(1).required(),
});

const urlInfoContent = (
  <div>
    <p>Root URL of your Confluence page.</p>
    <p>
      <i>E.g. https://conf.baltic-amadeus.lt</i>
    </p>
  </div>
);

const spacekeyInfoContent = (
  <div className="tooltip">
    <p>Space Key can be found in Confluence page following these steps:</p>
    <p>
      <i>
        Space tool (bottom left corner) {">"} Overview {">"} Space details
      </i>
    </p>
    <p>Value near "Key" is what you need.</p>
  </div>
);

const pageTitleInfoContent = (
  <div className="tooltip">
    <p>Primary page is a page created inside a Confluence space (Page Tree).</p>
    <p>
      If you would like to use default primary page, you can leave this field
      blank.
    </p>
    <p>
      Make sure that text is <i>case sensitive</i>.
    </p>
  </div>
);

const ConfluenceExport = (props) => {
  const {
    isOpen,
    onClose,
    sprintId,
    // competences,
    // chartData,
    projectId,
  } = props;

  const [confluenceUrl, setConfluenceUrl] = useState("");
  const [spaceKey, setSpaceKey] = useState("");
  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    _fetch(`api/ConfluenceSpace?projectId=${projectId}`)
      .then(({ data }) => {
        setConfluenceUrl(data.url);
        setSpaceKey(data.spaceKey);
        setPageTitle(data.parentTitle);
      })
      .catch(() => {
        setConfluenceUrl("");
        setSpaceKey("");
        setPageTitle("");
      });
  }, [projectId]);

  const setInitialValues = () => ({
    confluenceUrl: confluenceUrl ? confluenceUrl : "",
    username: "",
    password: "",
    spaceKey: spaceKey ? spaceKey : "",
    pageTitle: pageTitle ? pageTitle : "",
  });

  const getErrorNumber = (text) => text.split(" ")[0];

  const handleSubmit = (values, { setSubmitting }) => {
    const { confluenceUrl, username, password, spaceKey, pageTitle } = values;

    const chart = document.getElementById("export-chart");
    const chartBase64 = chart.toDataURL("image/png", 1).split(",")[1];

    const encodedLogin = window.btoa(`${username}:${password}`);

    const formData = {
      url: confluenceUrl,
      login: encodedLogin,
      spaceKey: spaceKey,
      imageBase64String: chartBase64,
      parentPageTitle: pageTitle,
    };

    _fetch(`api/Sprints/${sprintId}/ConfluenceExport`, {
      method: "POST",
      data: formData,
    })
      .then((res) => {
        const notification = { message: "", intent: Intent.DANGER };
        const errorNumber = getErrorNumber(res.data);

        if (errorNumber === "01") {
          notification.message = "Request to confluence failed";
        } else if (errorNumber === "02") {
          notification.message =
            "Bad user information or User has no permissions to create page in this space";
        } else if (errorNumber === "03") {
          notification.message = "Page already exists / problem parsing xhtml";
        } else if (errorNumber === "04") {
          notification.message = "Parent page with such a name is not found";
        } else if (errorNumber === "05") {
          notification.message = "Confluence URL was not found";
        } else if (errorNumber === "06") {
          notification.message = "Invalid space key";
        } else if (res.status < 300) {
          onClose();
          notification.message = "Confluence info sent";
          notification.intent = Intent.SUCCESS;
        }

        setSubmitting(false);
        Toaster.show(notification);
      })
      .catch(() => {
        Toaster.show({
          message: "An error has occured",
          intent: Intent.DANGER,
        });
      });
  };

  return (
    <>
      {/* <RadarChart
        id="confluence-chart"
        labels={competences}
        chartData={dataToChartDataset(chartData)}
        width={250}
        height={200}
        className="to-base64"
      /> */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={setInitialValues()}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, isSubmitting }) => (
            <Form className="confluence-export-form">
              <h2 className="confluence-export-form-title">
                Enter Confluence data
              </h2>
              <Field
                component={TextInput}
                name="confluenceUrl"
                value={values.confluenceUrl}
                placeholder="Enter URL..."
                label={
                  <div className="confluence-export-form-input-label">
                    Confluence URL <InfoBadge content={urlInfoContent} />
                  </div>
                }
                error={!!errors.confluenceUrl}
              />
              <Field
                component={TextInput}
                name="username"
                value={values.username}
                placeholder="Enter username..."
                label="Confluence username"
                error={!!errors.username}
              />
              <Field
                component={TextInput}
                type="password"
                name="password"
                value={values.password}
                placeholder="Enter password..."
                label="Confluence password"
                error={!!errors.password}
              />
              <Field
                component={TextInput}
                name="spaceKey"
                value={values.spaceKey}
                placeholder="Enter Space Key..."
                label={
                  <div className="confluence-export-form-input-label">
                    Page Space Key <InfoBadge content={spacekeyInfoContent} />
                  </div>
                }
                error={!!errors.spaceKey}
              />
              <Field
                component={TextInput}
                type="input"
                name="pageTitle"
                value={values.pageTitle}
                placeholder="Enter primary page name..."
                label={
                  <div className="confluence-export-form-input-label">
                    Primary page name{" "}
                    <InfoBadge content={pageTitleInfoContent} />
                  </div>
                }
              />
              <Button type="submit" disabled={isSubmitting}>
                Export
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

ConfluenceExport.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sprintId: PropTypes.number.isRequired,
  competences: PropTypes.array.isRequired,
  chartData: PropTypes.array.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default ConfluenceExport;
