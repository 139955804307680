import axios from "axios";

const _fetch = async (url, params) => {
  axios.defaults.withCredentials = true;
  return await axios(`${process.env.REACT_APP_API}/${url}`, {
    ...params,
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + localStorage.getItem("auth.retroWebApiAccessToken"),
    },
    validateStatus: (status) => {
      return status < 500; // Reject only if the status code is greater than or equal to 500
    },
  })
    .then(async (response) => {
      // if response status code === 401 then move to login page
      if (response.status === 401) {
        localStorage.setItem("auth.redirectedFrom", window.location.href);
        window.location.href = window.location.origin + "/login";
        return;
      }
      return {
        success: response.status < 400,
        status: response.status,
        data: response.data,
      };
    })
    .catch((error) => {
      // if (window.location.pathname !== "/") {
      //   window.location.href = "/";
      // }
      throw new Error(error);
    });
};

export default _fetch;
