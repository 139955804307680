import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setUserProjectInit = () => {
  return {
    type: "GET_USER_PROJECT_INIT",
  };
};

const setFetchedUserProjectLoading = () => {
  return {
    type: "GET_USER_PROJECT_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedUserProject = (response) => {
  return {
    type: "GET_USER_PROJECT",
    payload: response.data,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedUserProjectError = () => {
  return {
    type: "GET_USER_PROJECT_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getUserProject = (projectId) => {
  return (dispatch) => {
    dispatch(setFetchedUserProjectLoading(projectId));
    _fetch(`api/Projects/${projectId}/Latest-Sprint`)
      .then((response) => {
        if (response.success) {
          dispatch(setFetchedUserProject(response));
        } else {
          dispatch(setFetchedUserProjectError());
        }
      })
      .catch(() => dispatch(setFetchedUserProjectError()));
  };
};

export const resetUserProject = () => {
  return (dispatch) => {
    dispatch(setUserProjectInit());
  };
};
