import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Field } from "formik";
import Collapse from "../../../../Collapse";
import Badge from "../../../../Badge";
import IconButton from "../../../../IconButton";
import TextInput from "../../../../TextInput";
import Avatar from "../../../../Avatar";
import ActionPointDetails from "./ActionPointDetails";
import { ReactComponent as AddIcon } from "../../../../../assets/icons/add.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/delete.svg";
import { nameToInitials } from "../../../../../utils/nameToInitials";
import formatDate from "../../../../../utils/formatDate";
import { getAvatarColor } from "../../../../../utils/getAvatarColor";
import { setCollapsedRetroFormComments } from "../../../../../actions/setCollapsedRetroFormComments";
import { VALUES } from "../../../../../constants/values";
import "./ActionPointsInputPanel.scss";

const ActionPointsInputPanel = ({ arrayHelpers, index, commentsType }) => {
  const dispatch = useDispatch();

  const [openActionPointDetails, setOpenActionPointDetails] = useState({
    isOpen: false,
    index: 0,
  });

  const projectUserIds = useSelector(({ projectUsers }) =>
    projectUsers.payload.map(({ userId }) => userId)
  );
  const collapsedRetroFormComments = useSelector(
    ({ collapsedRetroFormComments }) => collapsedRetroFormComments
  );

  const { actionPoints } = arrayHelpers.form.values[commentsType][index];

  const { errors } = arrayHelpers.form;
  const actionPointsErrors = errors[commentsType]?.[index]?.actionPoints;

  const setIsOpen = (value) =>
    dispatch(
      setCollapsedRetroFormComments({
        ...collapsedRetroFormComments,
        [commentsType]: {
          [index]: value,
        },
      })
    );

  const addActionButton = (
    <IconButton
      icon={<AddIcon />}
      text="Add an action"
      size="small"
      onClick={() => {
        arrayHelpers.push({
          text: "",
        });
        setIsOpen(true);
      }}
    />
  );

  const isCollapseOpen = collapsedRetroFormComments[commentsType][`${index}`];

  return (
    <Collapse
      title={
        <div className="action-points-input-panel-header">
          <span className="action-points-input-panel-header-title">
            Actions we are going to take <Badge>{actionPoints.length}</Badge>
          </span>
          {!isCollapseOpen && addActionButton}
        </div>
      }
      isOpen={!!isCollapseOpen}
      setIsOpen={setIsOpen}
      hideCollapseButton={actionPoints.length <= 0}
      className="action-points-input-panel"
    >
      <ul className="action-points-input-panel-inputs">
        {actionPoints.map((actionPoint, actionPointIndex) => {
          const error = actionPointsErrors
            ? !!actionPointsErrors[actionPointIndex]
            : false;

          return (
            <Fragment
              key={`${arrayHelpers.form.values[commentsType][index]}_${actionPointIndex}`}
            >
              <li>
                <div className="action-points-input-panel-inputs-field">
                  <Field
                    name={`${arrayHelpers.name}.${actionPointIndex}.text`}
                    value={actionPoint.text}
                  >
                    {({ field }) => (
                      <TextInput
                        thin
                        {...field}
                        autoFocus
                        autoGrowHeight
                        error={error}
                        textarea
                        maxLength={VALUES.MAX_ACTION_POINTS_COMMENT_LENGTH}
                      >
                        <div
                          className="action-points-input-panel-inputs-field-actions"
                          onClick={() =>
                            setOpenActionPointDetails({
                              isOpen: true,
                              index: actionPointIndex,
                            })
                          }
                        >
                          {actionPoint.assignedDate && (
                            <span className="action-points-input-panel-inputs-field-actions-date">
                              {formatDate(actionPoint.assignedDate)}
                            </span>
                          )}
                          {actionPoint.assignedUser &&
                          actionPoint.assignedUser.value ? (
                            <Avatar
                              color={getAvatarColor(
                                projectUserIds,
                                actionPoint.assignedUser.value
                              )}
                            >
                              {nameToInitials(actionPoint.assignedUser.label)}
                            </Avatar>
                          ) : (
                            <IconButton size="xsmall" icon={<AddIcon />} />
                          )}
                        </div>
                      </TextInput>
                    )}
                  </Field>
                  <IconButton
                    size="xsmall"
                    icon={<DeleteIcon />}
                    secondary
                    onClick={() => {
                      if (actionPoints.length <= 1) {
                        setIsOpen(false);
                      }
                      arrayHelpers.remove(actionPointIndex);
                    }}
                  />
                </div>
              </li>
              <ActionPointDetails
                isOpen={openActionPointDetails.isOpen}
                onClose={() =>
                  setOpenActionPointDetails({
                    isOpen: false,
                    index: openActionPointDetails.index,
                  })
                }
                assignedUser={
                  actionPoints[openActionPointDetails.index].assignedUser
                }
                assignedDate={
                  actionPoints[openActionPointDetails.index].assignedDate
                }
                arrayHelpers={arrayHelpers}
                index={openActionPointDetails.index}
              />
            </Fragment>
          );
        })}
      </ul>
      {isCollapseOpen && addActionButton}
    </Collapse>
  );
};
ActionPointsInputPanel.propTypes = {
  arrayHelpers: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  commentsType: PropTypes.string.isRequired,
};
export default ActionPointsInputPanel;
