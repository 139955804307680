import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from "react-router-dom";
import { ReactComponent as PoweredByBa } from "../../assets/logos/powered-by-ba.svg";
import { ROUTES } from "../../constants/routes";
import "./Footer.scss";

const Footer = ({
  onDarkBackground = false,
  onDarkBackgroundMobile = false,
}) => (
  <footer
    className={cx("footer", {
      "footer-on-dark": onDarkBackground,
      "footer-on-dark-mobile": onDarkBackgroundMobile,
    })}
  >
    <PoweredByBa className="footer-powered-by-ba-logo" />
    <div className="footer-links">
      <Link to={ROUTES.USER_MANUAL_PAGE.path} className="footer-links-button">
        User manual
      </Link>
      <Link to={ROUTES.PRIVACY_POLICY_PAGE.path} className="footer-links-link">
        Privacy policy
      </Link>
      <Link
        to={ROUTES.TERMS_AND_CONDITIONS_PAGE.path}
        className="footer-links-link"
      >
        Terms and conditions
      </Link>
      <Link to={ROUTES.CONTACT_US_PAGE.path} className="footer-links-link">
        Contact us
      </Link>
    </div>
  </footer>
);

Footer.propTypes = {
  onDarkBackground: PropTypes.bool,
  onDarkBackgroundMobile: PropTypes.bool,
};

export default Footer;
