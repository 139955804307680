import React from "react";
import PropTypes from "prop-types";
import "./StatementSectionContent.scss";

const StatementSectionContent = ({ children }) => {
  return <div className="statement-section-content">{children}</div>;
};

StatementSectionContent.propTypes = {
  children: PropTypes.node,
};

export default StatementSectionContent;
