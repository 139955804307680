import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setProjectUsersInit = () => {
  return {
    type: "GET_PROJECT_USERS_INIT",
  };
};

const setFetchedProjectUsersLoading = () => {
  return {
    type: "GET_PROJECT_USERS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedProjectUsers = (response) => {
  return {
    type: "GET_PROJECT_USERS",
    payload: response.data,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedProjectUsersError = () => {
  return {
    type: "GET_PROJECT_USERS_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getProjectUsers = (teamId) => {
  return (dispatch) => {
    dispatch(setFetchedProjectUsersLoading());
    _fetch(`api/Projects/${teamId}/Users`)
      .then((response) => {
        if (response.data) {
          dispatch(setFetchedProjectUsers(response));
        } else {
          dispatch(setFetchedProjectUsersError());
        }
      })
      .catch(() => dispatch(setFetchedProjectUsersError()));
  };
};

export const resetProjectUsers = () => {
  return (dispatch) => {
    dispatch(setProjectUsersInit());
  };
};
