//list of colors distinguishable by humans
//https://en.wikipedia.org/wiki/Help:Distinguishable_colors
//Not one to one with wiki page, I mixed them a bit
const colors = [
  { r: 255, g: 80, b: 5 },
  { r: 0, g: 117, b: 220 },
  { r: 76, g: 0, b: 92 },
  { r: 25, g: 25, b: 25 },
  { r: 240, g: 163, b: 255 },
  { r: 43, g: 206, b: 72 },
  { r: 255, g: 204, b: 153 },
  { r: 128, g: 128, b: 128 },
  { r: 148, g: 255, b: 181 },
  { r: 143, g: 124, b: 0 },
  { r: 157, g: 204, b: 0 },
  { r: 194, g: 0, b: 136 },
  { r: 0, g: 51, b: 128 },
  { r: 255, g: 164, b: 5 },
  { r: 255, g: 168, b: 187 },
  { r: 66, g: 102, b: 0 },
  { r: 255, g: 0, b: 16 },
  { r: 94, g: 241, b: 242 },
  { r: 0, g: 153, b: 143 },
  { r: 224, g: 255, b: 102 },
  { r: 116, g: 10, b: 255 },
  { r: 153, g: 0, b: 0 },
  { r: 255, g: 255, b: 128 },
  { r: 255, g: 255, b: 0 },
  { r: 0, g: 92, b: 49 },
];

export default function getColor(index, opacity) {
  return `rgba(${colors[index].r}, ${colors[index].g}, ${colors[index].b}, ${opacity})`;
}
