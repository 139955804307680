import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as Back } from "../../assets/icons/back.svg";
import "./BackButton.scss";

const BackButton = ({ path, ...props }) => (
  <Link className="back-button" to={path} {...props}>
    <Back /> <span>Back</span>
  </Link>
);

BackButton.propTypes = {
  path: PropTypes.string,
};

export default BackButton;
