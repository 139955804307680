import _fetch from "../utils/fetch";
import { splitComments } from "../utils/splitComments";
import { STATUSES } from "../constants/statuses";

// this action dispatches both retro do well and improve comments

const setRetroCommentsInit = () => {
  return {
    type: "GET_RETRO_COMMENTS_INIT",
    status: STATUSES.INIT,
  };
};

const setFetchedRetroCommentsLoading = () => {
  return {
    type: "GET_RETRO_COMMENTS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedRetroComments = (response) => {
  return {
    type: "GET_RETRO_COMMENTS",
    payload: response,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedRetroCommentsError = () => {
  return {
    type: "GET_RETRO_COMMENTS_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getRetroComments = (sprintId, onlyStarred = false) => {
  const query = onlyStarred ? "?starred=true" : "";
  return (dispatch) => {
    dispatch(setFetchedRetroCommentsLoading());
    _fetch(`api/Sprints/${sprintId}/Retro-Comments${query}`)
      .then((res) => {
        if (res.success) {
          const comments = splitComments(res);
          dispatch(setFetchedRetroComments(comments));
        } else {
          dispatch(setFetchedRetroCommentsError());
        }
      })
      .catch(() => {
        dispatch(setFetchedRetroCommentsError());
      });
  };
};

export const resetRetroComments = () => {
  return (dispatch) => {
    dispatch(setRetroCommentsInit());
  };
};
