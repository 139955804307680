export const getAvatarColor = (list, item) => {
  const colors = [
    "#00bf8a",
    "#ffb000",
    "#98b0d5",
    "#d161eb",
    "#4967ff",
    "#95d500",
    "#ff6666",
    "#7f61eb",
    "#19aeff",
    "#20adc3",
  ];

  const index = list.indexOf(item);

  return index <= colors.length ? colors[index] : "#d3d3d3";
};
