import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setUserEvaluationInit = () => {
  return {
    type: "GET_USER_EVALUATION_INIT",
  };
};
const setFetchedUserEvaluationLoading = () => {
  return {
    type: "GET_USER_EVALUATION_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedUserEvaluation = (response) => {
  return {
    type: "GET_USER_EVALUATION",
    payload: response,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedUserEvaluationError = () => {
  return {
    type: "GET_USER_EVALUATION_ERROR",
    status: STATUSES.ERROR,
  };
};

const responseToUserEvaluation = (res) => ({
  legendLabel: "Self evaluation",
  data: res.data
    ? res.data.evaluationCompetences.map((item) => item.competenceValue)
    : [],
  filterable: false,
});

export const getUserEvaluation = (sprintId) => {
  return (dispatch) => {
    dispatch(setFetchedUserEvaluationLoading());
    _fetch(`api/Sprints/${sprintId}/User-Evaluation`)
      .then((response) => {
        if (response.success) {
          const userEvalaution = responseToUserEvaluation(response);
          dispatch(setFetchedUserEvaluation(userEvalaution));
        } else {
          dispatch(setFetchedUserEvaluationError());
        }
      })
      .catch(() => dispatch(setFetchedUserEvaluationError()));
  };
};

export const resetUserEvaluation = () => {
  return (dispatch) => {
    dispatch(setUserEvaluationInit());
  };
};
