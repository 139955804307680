import React from "react";
import PropTypes from "prop-types";
import Footer from "../../../components/Footer";
import "./FrontPageLayout.scss";

const FrontPageLayout = ({ children }) => {
  return (
    <>
      <div className="front-page-layout-content">{children}</div>
      <Footer onDarkBackground />
    </>
  );
};

FrontPageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

export default FrontPageLayout;
