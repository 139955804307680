import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSprint, resetSprint } from "../../actions/getSprint";
import {
  getTeamCompetencesAverages,
  resetTeamCompetencesAverages,
} from "../../actions/getTeamCompetencesAverages";
import {
  getUserEvaluation,
  resetUserEvaluation,
} from "../../actions/getUserEvaluation";
import {
  getUserCompetencesAverages,
  resetUserCompetencesAverages,
} from "../../actions/getUserCompetencesAverages";
import {
  getRetroComments,
  resetRetroComments,
} from "../../actions/getRetroComments";
import {
  getSprintParticipantCount,
  resetSprintParticipantCount,
} from "../../actions/getSprintParticipantCount";
import { getProject, resetProject } from "../../actions/getProject";
import {
  getProjectUsers,
  resetProjectUsers,
} from "../../actions/getProjectUsers";
import {
  getSprintUsers
} from "../../actions/getSprintUsers";
import { setPageTitle } from "../../actions/setPageTitle";
import RetroReviewContainer from "../../components/RetroReviewContainer";
import { STATUSES } from "../../constants/statuses";
import { ROUTES } from "../../constants/routes";
import { hasErrors, areInitiallyLoading } from "../../utils/pageStates";
import Spinner from "../../components/Spinner";

const RetroReview = ({ match }) => {
  const dispatch = useDispatch();

  const sprint = useSelector(({ sprint }) => sprint);
  const teamCompetencesAverages = useSelector(
    (state) => state.teamCompetencesAverages
  );
  const userEvaluation = useSelector(({ userEvaluation }) => userEvaluation);
  const userCompetencesAverages = useSelector(
    ({ userCompetencesAverages }) => userCompetencesAverages
  );
  const retroComments = useSelector(({ retroComments }) => retroComments);
  const sprintParticipantCount = useSelector(
    ({ sprintParticipantCount }) => sprintParticipantCount
  );
  const project = useSelector(({ project }) => project);
  const projectUsers = useSelector(({ projectUsers }) => projectUsers);

  const projectId = match.params.projectId;
  const sprintId = match.params.sprintId;

  const [isSprintValid, setIsSprintValid] = useState(true);

  const fetches = [
    sprint,
    teamCompetencesAverages,
    userEvaluation,
    userCompetencesAverages,
    retroComments,
    sprintParticipantCount,
    projectUsers,
    project,
  ];

  useEffect(() => {
    dispatch(getProject(projectId));
    dispatch(getProjectUsers(projectId));

    return () => {
      dispatch(resetProject());
      dispatch(resetProjectUsers());
    };
  }, [dispatch, projectId]);

  useEffect(() => {
    dispatch(getSprint(sprintId));

    return () => dispatch(resetSprint());
  }, [dispatch, sprintId]);

  useEffect(() => {
    dispatch(getSprintUsers(sprintId));
    return;
  }, [dispatch, sprintId]);

  useEffect(() => {
    if (sprint.status === STATUSES.SUCCESS) {
      if (sprint.payload && sprint.payload.sprintStatus === 2) {
        dispatch(getTeamCompetencesAverages(sprintId));
        dispatch(getUserEvaluation(sprintId));
        dispatch(getUserCompetencesAverages(sprintId));
        dispatch(getRetroComments(sprintId, true));
        dispatch(getSprintParticipantCount(sprintId));
      } else {
        setIsSprintValid(false);
      }
    }

    return () => {
      dispatch(resetTeamCompetencesAverages());
      dispatch(resetUserEvaluation());
      dispatch(resetUserCompetencesAverages());
      dispatch(resetRetroComments());
      dispatch(resetSprintParticipantCount());
    };
  }, [dispatch, sprint, sprintId]);

  useEffect(() => {
    if (project.payload) {
      dispatch(
        setPageTitle({
          text: "Retro review",
          backPath: `${ROUTES.RETRO_PROJECTS.path}/${project.payload.projectId}/sprints`,
        })
      );
    }
  }, [dispatch, project.payload]);

  if (hasErrors(fetches)) {
    return <h2>An error has occured :(</h2>;
  }

  if (!isSprintValid) {
    return <h2>Sprint is missing or isn't finished</h2>;
  }

  if (areInitiallyLoading(fetches)) {
    return <Spinner />;
  }

  return <RetroReviewContainer projectId={projectId} />;
};

export default RetroReview;
