import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../actions/setPageTitle";
import { ROUTES } from "../../constants/routes";
import StatementContainer from "../../components/StatementContainer";
import StatementSection from "../../components/StatementContainer/StatementSection";
import StatementSectionHeader from "../../components/StatementContainer/StatementSection/StatementSectionHeader";
import StatementSectionContent from "../../components/StatementContainer/StatementSection/StatementSectionContent";

const TermsAndConditions = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setPageTitle({
        text: "Terms of use",
        backPath: ROUTES.HOME.path,
      })
    );
  }, [dispatch]);

  return (
    <StatementContainer>
      <StatementSection>
        <StatementSectionContent>
          <p>
            Retro is owned by Baltic Amadeus UAB, a company incorporated and
            acting under the laws of Lithuania, company code 110320619,
            registered office at Mokslininkų st. 2A, LT-08412 Vilnius,
            Lithuania.
          </p>
          <p>
            These terms and conditions outline the rules and regulations for the
            use of Microsoft Teams app Retro.
          </p>
          <p>
            By using the Retro, we assume you acknowledge and agree to follow
            the rules and procedures set forth in these Terms of Use. Do not
            continue to use Retro if you do not agree to take all of the Terms
            of use stated on this page.
          </p>
        </StatementSectionContent>
      </StatementSection>

      <StatementSection>
        <StatementSectionHeader>General</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            Baltic Amadeus reserves the right to modify these Terms of User at
            any time, without any prior notice. Any modification to these Terms
            of Use will take effect as soon as these changes are published in
            the website. By using the app, you further acknowledge and agree
            that it is your responsibility to review Terms of Use every time you
            access the app, Baltic Amadeus shall not be held liable for your
            ignorance relating to this responsibility. You can review the most
            current version of the Terms of Use by simply clicking on the link
            “Terms and Use” at the end of the website.
          </p>
          <p>
            Any continued access after the modification will signify consent to
            the modifications of these Terms of Use. By using the app, you agree
            that such procedures in the event of modifications of Terms of Use
            are proper.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>Intellectual Property</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            The Retro is developed and managed by Baltic Amadeus. All
            intellectual property rights are reserved. You may access this from
            Microsoft website for your own personal use subjected to
            restrictions set in these Terms of Use.
          </p>
          <p>
            The intellectual property of this app is owned by Baltic Amadeus or
            third parties, if it is not stated otherwise. You are granted
            limited rights for purposes of viewing the material contained on
            this app. You do not have the right to alter the content of the app
            or to otherwise distort the substance of it, you do not have the
            right to sell, rent or sub-license, reproduce, duplicate or copy,
            redistribute material or content from the app. You may communicate
            our app or part of it to others or make otherwise available, only if
            you specify that the author of the app or the content you
            communicate to third parties belongs to Baltic Amadeus.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>Liability</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            Baltic Amadeus shall not be held liable, directly or indirectly, for
            any damage or loss caused or alleged to be caused in the events,
            which are not described in these Terms of Use, but related to your
            access to the app, unless otherwise is explicitly stated in the
            legal acts. Baltic Amadeus accepts no liability for slight or normal
            negligence.
          </p>
          <p>
            Baltic Amadeus also do not warrant app’s completeness or accuracy;
            nor do we promise to ensure that the app remains available or that
            the material on the app is kept up to date.
          </p>
          <p>
            Our website may contain links to third-party websites that are not
            owned or controlled by Baltic Amadeus. Baltic Amadeus has no control
            over, and assumes no responsibility for, the content, privacy
            policies, or practices of any third party websites. You further
            acknowledge and agree that Baltic Amadeus shall not be responsible
            or liable, directly or indirectly, for any damage or loss caused or
            alleged to be caused by or in connection with use of or reliance on
            any such content, goods or services available on or through any such
            websites.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>Privacy</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            For more information regarding your personal information and other
            privacy related questions please read our Privacy Policy.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>Settlement of Disputes</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            Any dispute relating to us and our activities shall be resolved by
            negotiations between you and us, upon applying by e-mail to
            info@ba.lt.
          </p>
          <p>
            If a dispute would reach the court, the laws of the Republic of
            Lithuania would apply, and the dispute would be heard by a competent
            court of the Republic of Lithuania.
          </p>
        </StatementSectionContent>
      </StatementSection>
      <StatementSection>
        <StatementSectionHeader>Contact Information</StatementSectionHeader>
        <StatementSectionContent>
          <p>
            If you would like to contact us to understand more about Terms and
            Conditions or wish to contact us concerning any other matter you may
            send an email to info@ba.lt.
          </p>
          <p>This document was last updated on June 22, 2020</p>
        </StatementSectionContent>
      </StatementSection>
    </StatementContainer>
  );
};

export default TermsAndConditions;
