import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setUserEvaluationsInit = () => {
  return {
    type: "GET_USER_EVALUATIONS_INIT",
  };
};
const setFetchedUserEvaluationsLoading = () => {
  return {
    type: "GET_USER_EVALUATIONS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedUserEvaluations = (response) => {
  return {
    type: "GET_USER_EVALUATIONS",
    payload: response,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedUserEvaluationsError = () => {
  return {
    type: "GET_USER_EVALUATIONS_ERROR",
    status: STATUSES.ERROR,
  };
};

const fetchedDataToForm = (res) => {
  return res.data.map((evaluation) => {
    return {
      evaluatedUserId: evaluation.evaluatedUserId.toString(),
      evaluationId: evaluation.evaluationId,
      isAnonymous: evaluation.isAnonymous,
      evaluationCompetences: evaluation.evaluationCompetences.map(
        (competence) => {
          return {
            competenceValue: competence.competenceValue.toString(),
            competenceName: competence.competenceName,
            competenceId: competence.competenceId,
          };
        }
      ),
    };
  });
};

export const getUserEvaluations = (sprintId) => {
  return (dispatch) => {
    dispatch(setFetchedUserEvaluationsLoading());
    _fetch(`api/Sprints/${sprintId}/Evaluations`)
      .then((response) => {
        if (response.success) {
          const formData = fetchedDataToForm(response);
          dispatch(setFetchedUserEvaluations(formData));
        } else {
          dispatch(setFetchedUserEvaluationsError());
        }
      })
      .catch(() => dispatch(setFetchedUserEvaluationsError()));
  };
};

export const resetUserEvaluations = () => {
  return (dispatch) => {
    dispatch(setUserEvaluationsInit());
  };
};
