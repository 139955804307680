import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import "./PanelFormInput.scss";

const PanelFormInput = (props) => {
  const {
    title,
    titleAction = null,
    children,
    tall = false,
    horizontal = false,
    competences = false,
    halfWidth = false,
    wrapWidth = false,
  } = props;

  return (
    <div
      className={cx("panel-form-field", {
        "panel-form-field-tall": tall,
      })}
    >
      <div className="panel-form-field-title-container">
        <span className="panel-form-field-title">{title}</span>
        {titleAction}
      </div>
      <div
        className={cx("panel-form-field-actions", {
          "panel-form-field-actions-horizontal": horizontal,
          "panel-form-field-actions-competences": competences,
          "panel-form-field-actions-half-width": halfWidth,
          "panel-form-field-actions-wrap-width": wrapWidth,
        })}
      >
        {children}
      </div>
    </div>
  );
};

PanelFormInput.propTypes = {
  title: PropTypes.string,
  titleAction: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
  tall: PropTypes.bool,
  horizontal: PropTypes.bool,
  competences: PropTypes.bool,
  halfWidth: PropTypes.bool,
  wrapWidth: PropTypes.bool,
};

export default PanelFormInput;
