import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { VALUES } from "../../../constants/values";
import "./FeedbackRequestQuestionsAnswers.scss";

const FeedbackRequestQuestionsAnswers = ({ data }) => {
  const getEvaluation = (evaluation) =>
    evaluation ? VALUES.SCALE[evaluation - 1] : { color: null, label: null };

  return (
    <div className="feedback-request-questions-answers">
      {data.map((item) => {
        const { color, label } = getEvaluation(item.evaluation);

        return (
          <div
            key={`feedback_request_question_answer_${item.feedbackRequestDataId}`}
            className="feedback-request-questions-answers-container"
          >
            <li className="feedback-request-questions-answers-question">
              {item.question}
            </li>
            <span className="feedback-request-questions-answers-answer">
              {item.answer}
            </span>
            {item.isEvaluationRequired && (
              <div className="feedback-request-questions-answers-evaluation">
                <div
                  className={cx(
                    "feedback-request-questions-answers-evaluation-badge",
                    color
                  )}
                />
                <span>{label}</span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

FeedbackRequestQuestionsAnswers.propTypes = {
  data: PropTypes.array.isRequired,
};

export default FeedbackRequestQuestionsAnswers;
