import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setLatestSprintInit = () => {
  return {
    type: "GET_LATEST_SPRINT_INIT",
  };
};

const setFetchedLatestSprintLoading = () => {
  return {
    type: "GET_LATEST_SPRINT_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedLatestSprint = (response) => {
  return {
    type: "GET_LATEST_SPRINT",
    payload: response.data,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedLatestSprintError = () => {
  return {
    type: "GET_LATEST_SPRINT_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getLatestSprint = (projectId) => {
  return (dispatch) => {
    dispatch(setFetchedLatestSprintLoading());
    _fetch(`api/projects/${projectId}/latest-sprint`)
      .then((response) => {
        if (response.success) {
          dispatch(setFetchedLatestSprint(response));
        } else {
          dispatch(setFetchedLatestSprintError());
        }
      })
      .catch(() => dispatch(setFetchedLatestSprintError()));
  };
};

export const resetLatestSprint = () => {
  return (dispatch) => {
    dispatch(setLatestSprintInit());
  };
};
