import { useEffect } from "react";
import { useFormikContext } from "formik";

const LeaveFormPageWarning = () => {
  const { dirty } = useFormikContext();

  useEffect(() => {
    if (dirty) {
      window.onbeforeunload = () => false;
    }

    return () => (window.onbeforeunload = () => {});
  }, [dirty]);

  return null;
};

export default LeaveFormPageWarning;
