import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Tag from "../../Tag";
import "./FeedbacksFilter.scss";

const FeedbacksFilter = ({ values, setValues }) => {
  const { feedback, request, answer } = values;

  const handleClick = (key, value) =>
    setValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  return (
    <div className="feedbacks-filter">
      Show:
      <button
        className={cx("feedbacks-filter-button", {
          "feedbacks-filter-button-checked": !request,
        })}
        onClick={() => handleClick("request", !request)}
      >
        <Tag color="yellow">Requests</Tag>
      </button>
      <button
        className={cx("feedbacks-filter-button", {
          "feedbacks-filter-button-checked": !feedback,
        })}
        onClick={() => handleClick("feedback", !feedback)}
      >
        <Tag color="grey">Feedbacks</Tag>
      </button>
      <button
        className={cx("feedbacks-filter-button", {
          "feedbacks-filter-button-checked": !answer,
        })}
        onClick={() => handleClick("answer", !answer)}
      >
        <Tag color="red">Answers</Tag>
      </button>
    </div>
  );
};

FeedbacksFilter.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
};

export default FeedbacksFilter;
