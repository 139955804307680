import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "../Tabs";
import ActiveProjectPanel from "./ActiveProjectPanel";
import InactiveProjectPanel from "./InactiveProjectPanel";
import EmptyListBadge from "../EmptyListBadge";
import { STATUSES } from "../../constants/statuses";
import formatDate from "../../utils/formatDate";
import { setProjectListTab } from "../../actions/setProjectListTab";
import "./ProjectsContainer.scss";

const ProjectsContainer = () => {
  const dispatch = useDispatch();

  const userProjects = useSelector(({ userProjects }) => userProjects);
  const pojectListTab = useSelector(({ projectListTab }) => projectListTab);

  const areProjectsLoading = () =>
    (userProjects.status === STATUSES.INIT ||
      userProjects.status === STATUSES.LOADING) &&
    !userProjects.payload;

  const isRetroActive = (project) =>
    project.sprint &&
    (project.sprint.sprintStatus === 0 || project.sprint.sprintStatus === 1);

  const getProjects = (isActive) => {
    return userProjects.payload
      .map((item) => {
        const { sprint, projectId } = item;
        return isActive
          ? isRetroActive(item) && (
              <ActiveProjectPanel
                key={`project_id_${projectId}`}
                projectName={item.projectName}
                projectId={projectId}
                sprintName={sprint.sprintName}
                sprintDate={formatDate(sprint.retroStartDate)}
                sprintStatus={sprint.sprintStatus}
                sprintId={sprint.sprintId}
                isAdmin={item.isAdmin}
              />
            )
          : !isRetroActive(item) && (
              <InactiveProjectPanel
                key={`project_id_${item.projectId}`}
                projectName={item.projectName}
                projectId={item.projectId}
                isAdmin={item.isAdmin}
              />
            );
      })
      .filter((item) => item);
  };

  const getTabs = () => {
    const getComponent = (isActive, text) => {
      if (userProjects.payload) {
        if (userProjects.payload.length <= 0){
          return <div>
                    It appears that this channel is not linked with a project.<br></br> 
                    Please go to settings and choose admins for your new project.<br></br>
                    <br></br>
                    If a project you used is lost, please contact us and we will link that project to your channel
                  </div>
        }
        const projects = getProjects(isActive);
        return projects.length > 0 ? (
          <div className="user-projects-container">{projects}</div>
        ) : (
          <EmptyListBadge text={text} />
        );
      }
    };

    return [
      {
        text: "Active",
        component: getComponent(true, "There are no active sprints"),
      },
      {
        text: "Inactive",
        component: getComponent(false, "There are no inactive sprints"),
      },
    ];
  };

  return (
    <div className="user-projects-tabs">
      <Tabs
        tabs={getTabs()}
        activeTab={pojectListTab}
        onTabClick={(index) => dispatch(setProjectListTab(index))}
        isLoading={areProjectsLoading()}
      />
    </div>
  );
};

export default ProjectsContainer;
