import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import Modal from "../../Modal";
import Button from "../../Button";
import "./Logout.scss";

const Logout = ({ fixed }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleConfirm = () => {
    localStorage.removeItem("auth.retroWebApiAccessToken");
    setShouldRedirect(true);
  };

  return (
    <>
      {shouldRedirect && <Redirect to={ROUTES.LOGIN_PAGE.path} />}
      {localStorage.getItem("auth.retroWebApiAccessToken") && (
        <button
          type="button"
          onClick={() => setIsAlertOpen(true)}
          className={cx("logout-button", { "logout-button-fixed": fixed })}
        >
          LOGOUT
        </button>
      )}
      <Modal isOpen={isAlertOpen} onClose={() => setIsAlertOpen(false)} cross>
        <p>Are you sure you want to logout?</p>
        <div className="modal-actions">
          <Button onClick={() => setIsAlertOpen(false)} secondary>
            Cancel
          </Button>
          <Button onClick={handleConfirm}>Logout</Button>
        </div>
      </Modal>
    </>
  );
};

Logout.propTypes = {
  fixed: PropTypes.bool,
};

export default Logout;
