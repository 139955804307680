import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setGivenFeedbackAnswersInit = () => {
  return {
    type: "GET_GIVEN_FEEDBACK_ANSWERS_INIT",
  };
};

const setFetchedGivenFeedbackAnswersLoading = () => {
  return {
    type: "GET_GIVEN_FEEDBACK_ANSWERS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedGivenFeedbackAnswers = (response) => {
  return {
    type: "GET_GIVEN_FEEDBACK_ANSWERS",
    payload: response.data,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedGivenFeedbackAnswersError = () => {
  return {
    type: "GET_GIVEN_FEEDBACK_ANSWERS_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getGivenFeedbackAnswers = () => {
  return (dispatch) => {
    dispatch(setFetchedGivenFeedbackAnswersLoading());
    _fetch("api/FeedbackRequests?answered=true&given=false")
      .then((response) => {
        const dispatchFn = response.data
          ? setFetchedGivenFeedbackAnswers(response)
          : setFetchedGivenFeedbackAnswersError();

        dispatch(dispatchFn);
      })
      .catch(() => dispatch(setFetchedGivenFeedbackAnswersError()));
  };
};

export const resetGivenFeedbackAnswers = () => {
  return (dispatch) => {
    dispatch(setGivenFeedbackAnswersInit());
  };
};
