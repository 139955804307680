import React from "react";
import PropTypes from "prop-types";
import {
  PopoverInteractionKind,
  Position,
  Tooltip as BPTooltip,
} from "@blueprintjs/core";
import "./Tooltip.scss";

const Tooltip = ({ children, content }) => (
  <BPTooltip
    content={content}
    interactionKind={PopoverInteractionKind.HOVER}
    position={Position.TOP}
    popoverClassName="tooltip-popover"
  >
    {children}
  </BPTooltip>
);

Tooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
};
export default Tooltip;
