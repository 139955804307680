import React, { useRef, useEffect } from "react";
import { object, array } from "yup";
import { useSelector } from "react-redux";
import { Intent } from "@blueprintjs/core";
import * as msTeams from "@microsoft/teams-js";
import { Formik, Form, Field } from "formik";
import _fetch from "../../utils/fetch";
import Tagger from "../Tagger";
import Toaster from "../Toaster";
import "./TeamsConfigForm.scss";

const validationSchema = object().shape({
  adminUsers: array().min(1).required(),
});

const TeamsConfigForm = () => {
  const formRef = useRef();

  const teamsConfigInfo = useSelector(
    ({ teamsConfigInfo }) => teamsConfigInfo.payload.data
  );

  const usersToTagEntity = (users, tags) =>
    users.reduce((results, user) => {
      if (!tags.find((tag) => tag.id === user.username)) {
        results.push({
          id: user.username,
          name: user.fullName,
        });
      }
      return results;
    }, []);

  const getInitialValues = () => ({
    adminUsers: teamsConfigInfo.adminUsers
      ? usersToTagEntity(teamsConfigInfo.adminUsers, [])
      : [],
  });

  msTeams.initialize();

  const validityState = teamsConfigInfo.isCurrentUserAdmin;
  msTeams.settings.setValidityState(validityState);

  useEffect(() => {
    getInitialValues();

    msTeams.getContext(({ channelId }) => {
      msTeams.settings.registerOnSaveHandler((saveEvent) => {
        const { values, validateForm } = formRef.current;

        validateForm().then((errors) => {
          if (errors.adminUsers) {
            Toaster.show({
              message: "Choose at least one admin",
              intent: Intent.DANGER,
            });
            saveEvent.notifyFailure();
          } else {
            const formData = {
              projectName: "Project",
              users: teamsConfigInfo.allUsers,
              adminUsers: values.adminUsers.map((value) => ({
                fullName: value.name,
                username: value.id,
              })),
              teamsChannelId: channelId,
            };

            const doPUT =
              teamsConfigInfo.adminUsers &&
              teamsConfigInfo.adminUsers.length > 0;

            _fetch(`api/project${doPUT ? `/${channelId}` : ""}`, {
              method: doPUT ? "PUT" : "POST",
              data: formData,
            }).then(({ status }) => {
              if (status >= 400) {
                saveEvent.notifyFailure();
                Toaster.show({
                  message: "Something went wrong",
                  intent: Intent.DANGER,
                });
              } else {
                msTeams.settings.setSettings({
                  contentUrl: window.location.origin,
                  entityId: process.env.REACT_APP_TEAMS_ENTITY_ID, //used in deep links to identify a tab
                });
                saveEvent.notifySuccess();
              }
            });
          }
        });
      });
    });
  });

  if (!teamsConfigInfo.isCurrentUserAdmin) {
    return <h2>You do not have permission to edit this info</h2>;
  }

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      innerRef={formRef}
    >
      {({ values, setFieldValue, errors }) => (
        <Form className="teams-config-form">
          <h2>Select project admins</h2>
          <Field
            component={Tagger}
            placeholder="Insert admin user names..."
            tags={values.adminUsers}
            suggestions={usersToTagEntity(
              teamsConfigInfo.allUsers,
              values.adminUsers
            )}
            setFieldValue={setFieldValue}
            fieldValueName="adminUsers"
            error={!!errors.adminUsers}
            className="teams-config-admin-users-tagger"
          />
        </Form>
      )}
    </Formik>
  );
};

export default TeamsConfigForm;
