import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Badge from "../../Badge";
import "./TabButton.scss";

export default function TabButton(allProps) {
  const { text, messages, active, hasNotification, ...props } = allProps;

  return (
    <button
      type="button"
      className={cx("tab-button", { "tab-button-active": active })}
      {...props}
    >
      {text}{" "}
      {messages >= 0 && (
        <Badge
          hasNotification={hasNotification > 0}
          unactive={!active}
          notificationBorderColor={active ? "#f6f7fb" : "#fff"}
        >
          {messages}
        </Badge>
      )}
    </button>
  );
}

TabButton.propTypes = {
  text: PropTypes.string.isRequired,
  messages: PropTypes.number,
  active: PropTypes.bool.isRequired,
  hasNotification: PropTypes.bool,
};
