import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./TextInput.scss";

const TextInput = (allProps) => {
  const {
    field,
    autoFocus = false,
    type = "text",
    maxLength,
    textarea = false,
    autoGrowHeight = false,
    className,
    thin,
    label = null,
    error = false,
    removed = false,
    children,
    ...props
  } = allProps;

  const ref = useRef();

  // auto vertical growth
  useEffect(() => {
    if (autoGrowHeight && textarea) {
      const textArea = ref.current;

      if (textArea) {
        const onInput = () => {
          textArea.style.height = "40px";
          textArea.style.height = textArea.scrollHeight + "px";
        };

        textArea.addEventListener("input", onInput, false);
      }
    }
  }, [autoGrowHeight, textarea]);

  const classNames = cx("text-input", className, {
    "text-input-textarea": textarea,
    "text-input-thin": thin,
    "text-input-with-children-input": children,
    "text-input-error": error,
    "text-input-removed": removed,
  });

  let input = textarea ? (
    <textarea
      ref={ref}
      {...field}
      {...props}
      className={classNames}
      autoFocus={autoFocus}
      maxLength={maxLength}
    />
  ) : (
    <input
      {...field}
      {...props}
      type={type}
      className={classNames}
      autoFocus={autoFocus}
      maxLength={maxLength}
    />
  );

  if (error || label || children) {
    input = (
      <div
        className={cx(className, "text-input-container", {
          "text-input-error-container": error,
        })}
      >
        {!!label && <label className="text-input-label">{label}</label>}
        {children ? (
          <div className="text-input-with-children">
            {input}
            <div
              className={cx("text-input-with-children-actions", {
                "text-input-with-children-actions-error": error,
              })}
            >
              {children}
            </div>
          </div>
        ) : (
          input
        )}
        {error && <span className="alert-text">This field is required</span>}
      </div>
    );
  }

  return input;
};

TextInput.propTypes = {
  field: PropTypes.object,
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  textarea: PropTypes.bool,
  autoresize: PropTypes.bool,
  className: PropTypes.string,
  thin: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.bool,
  removed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default TextInput;
