export default function (
  state = { doWellComments: {}, improveComments: {} },
  { type, payload }
) {
  switch (type) {
    case "SET_COLLAPSED_RETRO_FORM_COMMENTS":
      return payload;
    default:
      return state;
  }
}
