import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Intent } from "@blueprintjs/core";
import { string, number, object } from "yup";
import GreyContainer from "../GreyContainer";
import PanelFormInput from "../PanelFormInput";
import SearchSelect from "../SearchSelect";
import Tagger from "../Tagger";
import TextInput from "../TextInput";
import Switch from "../Switch";
import Button from "../Button";
import Toaster from "../Toaster";
import { usersToTagEntity } from "../../utils/usersToTagEntity";
import { usersToSearchOptionsEntity } from "../../utils/usersToSearchOptionsEntity";
import _fetch from "../../utils/fetch";
import { ROUTES } from "../../constants/routes";
import "./CreateFeedbackForm.scss";

const validationSchema = object().shape({
  toUser: object().shape({
    label: string().required(),
    value: number().required(),
  }),
  feedback: string().min(1).required(),
});

const CreateFeedbackForm = () => {
  const [redirectToFeedbacks, setRedirectToFeedbacks] = useState(false);

  const activeUsers = useSelector(
    ({ activeUsers }) => activeUsers.payload.data
  );

  const setInitialValues = () => ({
    toUser: "",
    ccUsers: [],
    feedback: "",
    isIncognito: false,
  });

  const handleSubmit = (values) => {
    const { toUser, ccUsers, feedback, isIncognito } = values;

    const feedbackObj = {
      toUserId: toUser.value,
      ccUserIds: ccUsers.map((item) => item.id),
      feedback,
      incognito: isIncognito,
    };

    _fetch("api/Feedbacks", {
      method: "POST",
      data: feedbackObj,
    })
      .then((response) => {
        const message = {
          message: "Feedback sent!",
          intent: Intent.SUCCESS,
        };

        if (response.success) {
          setRedirectToFeedbacks(true);
        } else {
          message.message = "Something went wrong";
          message.intent = Intent.DANGER;
        }

        Toaster.show(message);
      })
      .catch(() => {
        Toaster.show({
          message: "Something went wrong",
          intent: Intent.DANGER,
        });
      });
  };

  if (redirectToFeedbacks) {
    return <Redirect to={ROUTES.FEEDBACKS.path} />;
  }

  return (
    <GreyContainer className="create-feedback-container">
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={setInitialValues()}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, setFieldValue, isSubmitting }) => (
          <Form className="create-feedback-form">
            <div className="panel create-feedback-form-panel">
              <PanelFormInput title="Leave feedback for" halfWidth>
                <Field
                  component={SearchSelect}
                  name="toUser"
                  value={values.toUser}
                  placeholder="Choose a colleague..."
                  error={!!errors.toUser}
                  setFieldValue={setFieldValue}
                  options={usersToSearchOptionsEntity(activeUsers, false)}
                />
              </PanelFormInput>
              <PanelFormInput title="Colleagues CC">
                <Field
                  component={Tagger}
                  fieldValueName="ccUsers"
                  placeholder="Add colleagues CC"
                  setFieldValue={setFieldValue}
                  tags={values.ccUsers}
                  suggestions={usersToTagEntity(activeUsers, values.ccUsers)}
                />
              </PanelFormInput>
              <PanelFormInput title="Feedback">
                <Field
                  component={TextInput}
                  name="feedback"
                  value={values.feedback}
                  placeholder="Write your feedback..."
                  error={!!errors.feedback}
                  textarea
                  className="create-feedback-form-feedback"
                />
              </PanelFormInput>
              <PanelFormInput wrapWidth>
                <Switch
                  name="isIncognito"
                  label="Incognito"
                  icon={
                    <i className="fas fa-user-secret fa-lg incognito-icon" />
                  }
                  large
                />
              </PanelFormInput>
            </div>
            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </GreyContainer>
  );
};

export default CreateFeedbackForm;
