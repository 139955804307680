import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import ReactTags from "react-tag-autocomplete";
import "./Tagger.scss";

const Tagger = (allProps) => {
  const {
    field,
    setFieldValue,
    fieldValueName,
    tags,
    suggestions,
    placeholder,
    error = false,
    className = null,
    ...props
  } = allProps;

  const taggerClassNames = {
    root: cx("react-tags", className, {
      "react-tags-error": error,
    }),
    rootFocused: "is-focused",
    selected: "react-tags__selected",
    selectedTag: "react-tags__selected-tag",
    selectedTagName: "react-tags__selected-tag-name",
    search: "react-tags__search",
    searchWrapper: "react-tags__search-wrapper",
    searchInput:
      "react-tags__search-input react-tags-search-input-admin-start-sprint-form",
    suggestions: "react-tags__suggestions",
    suggestionActive: "is-active",
    suggestionDisabled: "is-disabled",
  };

  const handleDelete = (i) => {
    const tag = tags.slice(0);
    tag.splice(i, 1);
    setFieldValue(fieldValueName, tag);
  };

  const handleAddition = (tag) => {
    const tagged = [].concat(tags, tag);
    setFieldValue(fieldValueName, tagged);
  };

  const reactTags = (
    <ReactTags
      {...field}
      {...props}
      classNames={taggerClassNames}
      autofocus={false}
      placeholderText={tags.length === 0 ? placeholder : ""}
      tags={tags}
      suggestions={suggestions}
      onDelete={handleDelete}
      onAddition={handleAddition}
      onBlur={() => {}}
    />
  );

  if (error) {
    return (
      <div className="react-tags-error-container">
        {reactTags}
        <span className="alert-text">This field is required</span>
      </div>
    );
  }

  return reactTags;
};

Tagger.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  fieldValueName: PropTypes.string.isRequired,
  field: PropTypes.object,
  tags: PropTypes.array.isRequired,
  suggestions: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  className: PropTypes.string,
};

export default Tagger;
