import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Spinner } from "@blueprintjs/core";
import TabButton from "./TabButton";
import "./Tabs.scss";

const Tabs = (props) => {
  const { tabs, activeTab, onTabClick, isLoading, className = null } = props;

  return (
    <div className={cx("tabs", className)}>
      <div className="tab-buttons">
        {tabs.map((tab, index) => (
          <TabButton
            key={`tab_button_${index}`}
            text={tab.text}
            messages={tab.messages}
            active={index === activeTab}
            hasNotification={tab.hasNotification}
            onClick={() => onTabClick(index)}
          />
        ))}
      </div>
      <div
        className={cx("tab-content", {
          "tab-content-sharp-top-left": activeTab !== 0,
        })}
      >
        {!isLoading ? tabs[activeTab].component : <Spinner />}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.number.isRequired,
  onTabClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  hasNotification: PropTypes.bool,
};

export default Tabs;
