import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

// this action dispatches both user do well and improve comments

const setUserCommentsInit = () => {
  return {
    type: "GET_USER_COMMENTS_INIT",
  };
};

const setFetchedUserCommentsLoading = () => {
  return {
    type: "GET_USER_COMMENTS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedUserComments = (response) => {
  return {
    type: "GET_USER_COMMENTS",
    payload: response,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedUserCommentsError = () => {
  return {
    type: "GET_USER_COMMENTS_ERROR",
    status: STATUSES.ERROR,
  };
};

const splitData = (res) => {
  return res.data.reduce(
    (results, item) => {
      if (item.commentType === 0) {
        results.doWellComments.push({
          comment: item.comment,
          commentId: item.commentId,
        });
      } else if (item.commentType === 1) {
        results.improveComments.push({
          comment: item.comment,
          commentId: item.commentId,
        });
      }
      return results;
    },
    { doWellComments: [], improveComments: [] }
  );
};

export const getUserComments = (sprintId) => {
  return (dispatch) => {
    dispatch(setFetchedUserCommentsLoading());
    _fetch(`api/Sprints/${sprintId}/User-Comments`)
      .then((res) => {
        if (res.success) {
          const comments = splitData(res);
          dispatch(setFetchedUserComments(comments));
        } else {
          dispatch(setFetchedUserCommentsError());
        }
      })
      .catch(() => {
        dispatch(setFetchedUserCommentsError());
      });
  };
};

export const resetUserComments = () => {
  return (dispatch) => {
    dispatch(setUserCommentsInit());
  };
};
