import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import GreyContainer from "../../GreyContainer";
import PersonalMoodInput from "./PersonalMoodInput";
import "./PersonalMood.scss";
import { ReactComponent as VSadIcon } from "../../../assets/icons/emojis/vsad.svg";
import { ReactComponent as SadIcon } from "../../../assets/icons/emojis/sad.svg";
import { ReactComponent as MehIcon } from "../../../assets/icons/emojis/meh.svg";
import { ReactComponent as SmileIcon } from "../../../assets/icons/emojis/smile.svg";
import { ReactComponent as HappyIcon } from "../../../assets/icons/emojis/happy.svg";

const PersonalMood = ({ error = false }) => {
  return (
    <GreyContainer title="How did you feel during this sprint?">
      <div
        className={cx("panel", "personal-mood-inputs-panel", {
          "panel personal-mood-inputs-panel-error": error,
        })}
      >
        <PersonalMoodInput type="vsad" value="1">
          <VSadIcon />
        </PersonalMoodInput>
        <PersonalMoodInput type="sad" value="2">
          <SadIcon />
        </PersonalMoodInput>
        <PersonalMoodInput type="meh" value="3">
          <MehIcon />
        </PersonalMoodInput>
        <PersonalMoodInput type="smile" value="4">
          <SmileIcon />
        </PersonalMoodInput>
        <PersonalMoodInput type="happy" value="5">
          <HappyIcon />
        </PersonalMoodInput>
      </div>
      {error && <span className="alert-text">This field is required</span>}
    </GreyContainer>
  );
};

PersonalMood.propTypes = {
  error: PropTypes.bool,
};

export default PersonalMood;
