import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setSprintInit = () => {
  return {
    type: "GET_SPRINT_INIT",
  };
};

const setFetchedSprintLoading = () => {
  return {
    type: "GET_SPRINT_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedSprint = (response) => {
  return {
    type: "GET_SPRINT",
    payload: response,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedSprintError = () => {
  return {
    type: "GET_SPRINT_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getSprint = (sprintId) => {
  return (dispatch) => {
    dispatch(setFetchedSprintLoading());
    _fetch(`api/sprints/${sprintId}`)
      .then((res) => {
        if (res.success) {
          dispatch(setFetchedSprint(res.data));
        } else {
          dispatch(setFetchedSprintError());
        }
      })
      .catch(() => {
        dispatch(setFetchedSprintError());
      });
  };
};

export const resetSprint = () => {
  return (dispatch) => {
    dispatch(setSprintInit());
  };
};
