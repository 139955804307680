import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logos/logo.svg";
import { ROUTES } from "../../constants/routes";
import Logout from "./Logout";
import "./Header.scss";

const Header = () => (
  <header className="nav-header">
    <Link to={ROUTES.HOME.path}>
      <Logo />
    </Link>
    <Logout />
  </header>
);

export default Header;
