import React, { useState } from "react";
import { object, array } from "yup";
import { useSelector, useDispatch } from "react-redux";
import { Intent } from "@blueprintjs/core";
import * as msTeams from "@microsoft/teams-js";
import { Formik, Form, Field, yupToFormErrors } from "formik";
import { Redirect } from "react-router-dom";
import _fetch from "../../utils/fetch";
import Tagger from "../Tagger";
import Toaster from "../Toaster";
import Button from "../Button";
import GreyContainer from "../GreyContainer";
import "./EditProjectAdminsForm.scss";
import { getTeamsConfigInfo } from "../../actions/getTeamsConfigInfo";
import { ROUTES } from "../../constants/routes";

const validationSchema = object().shape({
  adminUsers: array().min(1).required(),
});

const TeamsConfigForm = () => {
  const dispatch = useDispatch();

  const teamsConfigInfo = useSelector(
    ({ teamsConfigInfo }) => teamsConfigInfo.payload.data
  );

  const [redirectToRetroHome, setRedirectToRetroHome] = useState(false);

  msTeams.initialize();

  const usersToTagEntity = (users, tags) =>
    users.reduce((results, user) => {
      if (!tags.find((tag) => tag.id === user.username)) {
        results.push({
          id: user.username,
          name: user.fullName,
        });
      }
      return results;
    }, []);

  const getInitialValues = () => ({
    adminUsers: teamsConfigInfo.adminUsers
      ? usersToTagEntity(teamsConfigInfo.adminUsers, [])
      : [],
  });

  const validate = async (values) => {
    const errors = await validationSchema
      .validate(values, { abortEarly: false })
      .catch((err) => {
        Toaster.show({
          message: "Please choose at least one admin",
          intent: Intent.DANGER,
        });
        return err;
      });

    return yupToFormErrors(errors);
  };

  const handleSubmit = (values) => {
    msTeams.getContext(async ({ channelId }) => {
      const formData = {
        projectName: "Project",
        users: teamsConfigInfo.allUsers,
        adminUsers: values.adminUsers.map((value) => ({
          fullName: value.name,
          username: value.id,
        })),
        teamsChannelId: channelId,
      };

      const doPUT =
        teamsConfigInfo.adminUsers && teamsConfigInfo.adminUsers.length > 0;

      const { status } = await _fetch(
        `api/project${doPUT ? `/${channelId}` : ""}`,
        {
          method: doPUT ? "PUT" : "POST",
          data: formData,
        }
      );

      const message = {
        message: "Project admins saved",
        intent: Intent.SUCCESS,
      };

      if (status >= 400) {
        message.message = "Something went wrong";
        message.intent = Intent.DANGER;
      } else {
        dispatch(getTeamsConfigInfo(channelId));
        setRedirectToRetroHome(true);
      }

      Toaster.show(message);
    });
  };

  if (!teamsConfigInfo.isCurrentUserAdmin) {
    return <h2>You do not have permission to edit this info</h2>;
  }

  if (redirectToRetroHome) {
    return <Redirect to={ROUTES.RETRO_HOME.path} />;
  }

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={getInitialValues()}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, errors }) => (
        <GreyContainer className="edit-project-admins-form-container">
          <Form className="edit-project-admins-form">
            <Field
              component={Tagger}
              placeholder="Insert admin user names..."
              tags={values.adminUsers}
              suggestions={usersToTagEntity(
                teamsConfigInfo.allUsers,
                values.adminUsers
              )}
              setFieldValue={setFieldValue}
              fieldValueName="adminUsers"
              error={!!errors.adminUsers}
            />
            <Button type="submit">Save</Button>
          </Form>
        </GreyContainer>
      )}
    </Formik>
  );
};

export default TeamsConfigForm;
