import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import TextInput from "../../../../../TextInput";
import RadioButtonScale from "../../../../../RadioButtonScale";
import { VALUES } from "../../../../../../constants/values";
import "./FeedbackRequestAnswerFormInput.scss";

const FeedbackRequestAnswerFormInput = ({
  question,
  name,
  errors,
  isEvaluationRequired,
}) => (
  <div className="feedback-request-answer-form-input">
    <li className="feedback-request-answer-form-input-question">{question}</li>
    <div className="feedback-request-answer-form-input-text-field">
      <Field
        component={TextInput}
        textarea
        name={`${name}.text`}
        placeholder="Provide your answer"
        error={!!errors?.text}
      />
    </div>
    {isEvaluationRequired && (
      <div className="feedback-request-answer-form-input-evaluation-container">
        <span>Evaluate:</span>
        <RadioButtonScale
          name={`${name}.evaluation`}
          inputs={VALUES.SCALE}
          size="medium"
          error={!!errors?.evaluation}
        />
      </div>
    )}
  </div>
);

FeedbackRequestAnswerFormInput.propTypes = {
  question: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  isEvaluationRequired: PropTypes.bool.isRequired,
};

export default FeedbackRequestAnswerFormInput;
