import _fetch from "../utils/fetch";
import { STATUSES } from "../constants/statuses";

const setGivenFeedbacksInit = () => {
  return {
    type: "GET_GIVEN_FEEDBACKS_INIT",
  };
};

const setFetchedGivenFeedbacksLoading = () => {
  return {
    type: "GET_GIVEN_FEEDBACKS_LOADING",
    status: STATUSES.LOADING,
  };
};

const setFetchedGivenFeedbacks = (response) => {
  return {
    type: "GET_GIVEN_FEEDBACKS",
    payload: response.data,
    status: STATUSES.SUCCESS,
  };
};

const setFetchedGivenFeedbacksError = () => {
  return {
    type: "GET_GIVEN_FEEDBACKS_ERROR",
    status: STATUSES.ERROR,
  };
};

export const getGivenFeedbacks = () => {
  return (dispatch) => {
    dispatch(setFetchedGivenFeedbacksLoading());
    _fetch("api/Feedbacks?given=true")
      .then((response) => {
        if (response.success) {
          dispatch(setFetchedGivenFeedbacks(response));
        } else {
          dispatch(setFetchedGivenFeedbacksError());
        }
      })
      .catch(() => dispatch(setFetchedGivenFeedbacksError()));
  };
};

export const resetGivenFeedbacks = () => {
  return (dispatch) => {
    dispatch(setGivenFeedbacksInit());
  };
};
